import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, DirectionsRenderer, Marker, InfoWindow } from '@react-google-maps/api';
import { ICONS } from '../components/mapSvgFiles'
import { formatNumberWithCommas } from '../createAndEditJob';
import { ReactComponent as MaterialImg } from '../assets/materisl.svg';
import { ReactComponent as UpArrowImg } from '../assets/upArrow.svg';

const containerStyle = {
    width: '100%',
    height: '100%',
};


function MapsComponentForMaterial({ jobs, setLocationsPoints, handleMapClick, response, selectedLocationForDirections, setSelectedLocationForDirections }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDCbaUvm19bper4TcgOHxph29ATcEZ0ZLw'
    })
    const [map, setMap] = React.useState(null);
    const [hoveredMarker, setHoveredMarker] = useState(null);
    const [finalResponse, setResponse] = useState(null);

    const onLoad = React.useCallback(function callback(map) {
        if (jobs.length > 0) {
            map.setCenter({
                lat: jobs[0].job_info.job_location.latitude,
                lng: jobs[0].job_info.job_location.longitude,
            });
        } else {
            map.setCenter({
                lat: 37.529944,
                lng: -121.845778
            });
        }
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    useEffect(() => {
        if (response) {
            setResponse(response)
        } else {
            setResponse(null)
        }
    }, [response])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            defaultCenter={{
                lat: 18.571041,
                lng: 79.162735
            }}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                streetViewControl: false,  // Hide Pegman (Street View) control
                fullscreenControl: false,  // Optionally hide fullscreen control
                mapTypeControl: false,
            }}
        // onClick={handleMapClick}
        >
            {jobs.map((pointer, index) => (
                <Marker
                    key={index}
                    position={{
                        lat: pointer.job_info.job_location.latitude,
                        lng: pointer.job_info.job_location.longitude
                    }}
                    // job_info
                    icon={{
                        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(ICONS.liveJobs)}`,
                        scaledSize: new window.google.maps.Size(40, 40),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(20, 40),
                    }}
                    onClick={() => {
                        setLocationsPoints(prev => {
                            const pointerValue = {
                                lat: pointer.job_info.job_location.latitude,
                                lng: pointer.job_info.job_location.longitude,
                                name: pointer.job_info.job_address
                            }
                            let copyValues = JSON.parse(JSON.stringify(prev))
                            if (copyValues.length === 2) {
                                if (!copyValues[0].name) {
                                    copyValues[0] = pointerValue
                                } else if (!copyValues[1].name) {
                                    copyValues[1] = pointerValue
                                }
                                return copyValues
                            } else {
                                if (!copyValues[copyValues.length - 1].name) {
                                    copyValues[copyValues.length - 1] = pointerValue
                                }
                                return copyValues
                            }
                        })
                    }}
                    onMouseOver={() => setHoveredMarker(pointer)} // Show InfoWindow on hover
                    onMouseOut={() => setHoveredMarker(null)} // Hide InfoWindow when not hovered
                />
            ))}
            {finalResponse && (
                <DirectionsRenderer
                    options={{
                        preserveViewport: true,
                        directions: response,
                        suppressMarkers: true,
                        polylineOptions: {
                            strokeColor: "#016c6a", // Set custom route color (Tomato Red)
                            strokeOpacity: 0.9, // Adjust opacity if needed
                            strokeWeight: 5, // Adjust line thickness
                        },
                    }} />
            )}
            {hoveredMarker && (
                <InfoWindow
                    position={{
                        lat: hoveredMarker.job_info.job_location.latitude,
                        lng: hoveredMarker.job_info.job_location.longitude
                    }}
                    options={{ pixelOffset: new window.google.maps.Size(0, -35) }} // Adjust position of the InfoWindow
                    style={{
                        boxShadow: '0px 8px 20px -4px #1718181F',
                        boxShadow: '0px 3px 6px -3px #17181814',
                        border: '1px solid #ECF0F3'
                    }}
                >
                    <div>
                        <div className='material-tracker-material-status' style={{ justifyContent: 'flex-start', marginBottom: '8px' }}>
                            <MaterialImg /> {hoveredMarker.job_info.job_number} <span style={{ color: '#4D82F3', fontSize: '10px', fontWeight: 400 }}>( {hoveredMarker.job_info.project_status} )</span>
                        </div>
                        <div className='map-filter-sub-options' style={{
                            marginBottom: '0.8rem',
                            marginTop: "0px"
                        }}>
                            <span style={{ fontSize: '14px', margin: "0px" }}>{hoveredMarker.job_info.job_name}</span>
                        </div>
                        <div className='divider-for-map-filter' style={{ width: '350px' }} />
                        {hoveredMarker.job_materials.map((material => {
                            return (
                                <div className='map-filter-sub-options' style={{ marginBottom: '0.8rem', whiteSpace: "nowrap", width: "100%" }}>
                                    <span style={{ fontSize: '10px', width: "80px" }}> {material.material_name} {material.subtype_name ? "(" + material.subtype_name + ")" : ''}</span>
                                    <div style={{ display: 'flex', gap: "1rem", flex: 1 }} className='map-hover-text-styling' >
                                        <span style={{ display: 'flex', justifyContent: 'start', flex: 1 , alignItems:'center'}}>
                                            {material.material_availability === 'DEFICIT' ?
                                                <UpArrowImg style={{ height: "0.6rem" }} className='up-arrow' /> :
                                                <UpArrowImg style={{ height: "0.6rem" }} />
                                            }
                                            {formatNumberWithCommas(material.material_quantity)} {`${material?.material_name?.toLowerCase() === 'asphalt' ? 'tons' : 'C.Y'}`}
                                        </span>
                                        <span style={{ flex: 1, textAlign: "start" }}>{formatNumberWithCommas(material.material_quantity_available)} {`${material?.material_name?.toLowerCase() === 'asphalt'  ? 'tons' : 'C.Y'}`}</span>
                                        <span style={{ flex: 1, textAlign: "start", maxWidth: "55px" }}>{material.material_availability}</span>
                                    </div>
                                </div>
                            )
                        }))}
                    </div>
                </InfoWindow>
            )}

            {/* {pointerValue &&
                <InfoWindow position={pointerValue}>
                    <div
                        className='direction-details' >
                        <div className='direction-in-miles'>
                            <CarPointImg />
                            <span style={{ fontSize: '12px', fontWeight: '300' }}>{pointerValue?.duration}</span>
                        </div>
                        <span style={{ color: "#6D6D6D", fontSize: '10px', fontWeight: '300' }}>{pointerValue?.distance} miles</span>
                    </div>
                </InfoWindow>
            } */}
        </GoogleMap>
    ) : <></>
}

export default React.memo(MapsComponentForMaterial);