import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { OutlinedInput } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as MapInputCrossCrossImg } from '../assets/mapInputCross.svg';
import { ReactComponent as LocationIconPointer } from '../assets/locationFieldIcon.svg';
import { ReactComponent as LocationEndPointer } from '../assets/locationEnd.svg';

const fontAndSize = {
    fontFamily: "Plus Jakarta Sans",
    color: 'rgb(27, 46, 49)',
    fontSize: '16px',
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "black",
        backgroundColor: '#f3f3f3',
    },
    margin: '0.1rem',
}

function SearchableUnderlinedDropdown({ locationPoints, index, label, options, value, setLocationsPoints }) {
    const [inputValue, setInputValue] = useState(""); // For search input
    const [dropdownOptions, setDropdownOptions] = useState(options); // Dropdown list
    const [loading, setLoading] = useState(false); // API call loading state
    const [selectedOption, setSelectedOption] = useState('')

    useEffect(() => {
        if (value) {
            setSelectedOption(value)
        } else {
            setSelectedOption('')
        }
    }, [value])

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
        }, 100);
    };

    return (
        <div className="searchable-outlined-input" style={{ position: "relative" }}>
            {index !== locationPoints.length - 1 &&
                <div style={{ position: 'absolute', borderRight: "3px dotted green", height: "28px", left: '6px', top: "36px" }} />}
                {index === locationPoints.length - 1 ?
            <LocationEndPointer style={{ height: '20px', width: '20px' }} /> :
            <LocationIconPointer style={{ height: '20px', width: '20px' }} />}
            <OutlinedInput
                onFocus={handleFocus}
                onBlur={handleBlur}
                size='small'
                sx={{ ...fontAndSize }}
                placeholder={label}
                fullWidth
                // error={error.isError && error.rfi_number}
                required
                value={inputValue || selectedOption.substring(0, 30) + ((selectedOption && selectedOption.length > 30) ? '...' : '')}
                className='outline-input-customized'
                onChange={(e) => {
                    if (!selectedOption) {
                        setInputValue(e.target.value)
                    }
                }}
                endAdornment={
                    <>
                        {loading ? <CircularProgress size={20} /> :
                            <SearchIcon style={{ color: "#6c6c6c", cursor: "pointer", paddingLeft: "10px" }} />}
                    </>
                }
            />
            <div className={`${locationPoints.length > 0 && 'show-on-hover'}`}>
                <MapInputCrossCrossImg
                    style={{ height: '18px', width: "18px" }}
                    onClick={() => {
                        let copyData = JSON.parse(JSON.stringify(locationPoints));
                        if (copyData.length > 2) {
                            copyData.splice(index, 1);
                        } else {
                            copyData[index] = {}
                        }
                        setSelectedOption('');
                        setInputValue('');
                        setLocationsPoints(prev => copyData);
                    }}
                />
            </div>
            {!loading && dropdownOptions.length > 0 && isFocused &&
                <div className="dropdown-outlined-list">
                    {dropdownOptions.map(i => {
                        return (
                            <div className="dropdown-outlined-list-option" onClick={() => setSelectedOption(i.label)}>
                                {i.label}
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    );
}

export default SearchableUnderlinedDropdown;
