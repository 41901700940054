import React, { useState } from 'react'
import { ReactComponent as CalenderImg } from '../assets/calender.svg';
import { ReactComponent as MaterialImg } from '../assets/materisl.svg';
import { ReactComponent as MaterialIconImg } from '../assets/material.svg';
import { ReactComponent as UpArrowImg } from '../assets/upArrow.svg';
import { ReactComponent as DropdownArrowImg } from '../assets/dropdownArrow.svg';
import { ReactComponent as EditImg } from '../assets/editpencil.svg';
import { ReactComponent as ShowMoreImg } from '../assets/showMoreTimemline.svg';
import { formatDateWithMoment } from '../../../utils/encryptionDecryption';
import { useNavigate } from "react-router-dom";
import { convertToCamelCase } from '../../../utils/encryptionDecryption';
import TimelineModal from './timelineModal';
import STRINGS from '../../../helpers/staticContentHelper';
import { formatNumberWithCommas } from '../createAndEditJob';

function MaterialDetailCard({ setIsAddJobEnabled, data, index, setEditJobData }) {
    const [timelineModal, setIsTimelineModal] = useState(false);
    const navigate = useNavigate();
    const [shoMore, setShowMore] = useState(false);
    const { job_info, job_materials } = data;


    return (
        <div className='material-tracker-create-new' style={{ marginBottom: "20px", marginTop: "0px", border: shoMore && "1px solid #398381" }}>
            <TimelineModal isModalOpen={timelineModal} setIsModalOpen={setIsTimelineModal} jobId={job_info.job_id} />
            <div className='material-tracker-header' style={{ marginBottom: "10px" }}>
                <div className='material-tracker-material-status'>
                    <MaterialImg /> {job_info.job_number} ( {job_info.project_status})
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <div className='material-tracker-material-status'>
                        <CalenderImg /> {STRINGS.DURATION} - {formatDateWithMoment(job_info.start_date)} - {formatDateWithMoment(job_info.end_date)}
                    </div>
                    <DropdownArrowImg
                        style={{ cursor: 'pointer', transform: !shoMore && 'rotate(180deg)' }}
                        onClick={() => {
                            setShowMore(prev => !prev)
                        }} />
                </div>
            </div>

            <div className='material-tracker-header' style={{ marginBottom: "10px" }}>
                <div className='material-tracker-material-status'>
                    <h6 className='material-tracker-header-card-header'>{job_info.job_name}</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <div className='material-tracker-material-status'
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setIsTimelineModal(true)
                        }}>
                        <span style={{ fontWeight: 400, fontSize: '14px', color: "#1F5D5E", borderBottom: "1px solid #1F5D5E", }}>
                            {STRINGS.VIEW_UPDATES}
                        </span>
                        <ShowMoreImg style={{ transform: 'rotate(90deg)', marginLeft: '0.2rem' }} />
                    </div>
                </div>
            </div>

            <div className='material-tracker-material-details-in-card-view' style={{ flexWrap: "wrap" }}>
                {job_materials?.map((material, index) => {
                    return (
                        <div className='material-tracker-material-details-card'>
                            <div className='material-tracker-header'>
                                <div className='material-tracker-material-status'>
                                    <MaterialIconImg />
                                    <span className='material-tracker-header-card-header'>{material.material_name}</span>
                                </div>
                                {/* <p className='material-tracker-card-updated'>{moment(material.updated_at).fromNow()}</p> */}
                            </div>

                            <div className='material-tracker-card-details'>
                                {[1].map(i => {
                                    return (
                                        <div className='material-tracker-card-details-price-and-location'>
                                            <p className='material-tracker-card-details-price-heading'>
                                                {material.subtype_name || 'N/A'}
                                            </p>
                                            <p className='material-tracker-card-details-price'>
                                                {formatNumberWithCommas(material.material_quantity_available)}  {`${material?.material_name?.toLowerCase() === 'asphalt' ? 'tons' : 'C.Y'}`}
                                            </p>
                                            <p className='material-tracker-card-details-status'>
                                                {convertToCamelCase(material.material_certificate_status)} {STRINGS.CERTIFICATION}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
            {shoMore &&
                <>
                    <div className='material-tracker-form-divider' />
                    <div className='material-tracker-create-new-general-details'>

                        <div className='material-tracker-header'>
                            <div className='material-tracker-material-status'>
                                <span className='material-tracker-header-card-header'>{STRINGS.GENERAL_DETAILS}</span>
                            </div>
                            {job_info.enable_edit &&
                                <div className='material-tracker-material-status'
                                    style={{ borderBottom: "1px solid #1F5D5E", cursor: "pointer" }}
                                    onClick={() => {
                                        setEditJobData(data)
                                        setIsAddJobEnabled(true)
                                        navigate(`/track-material?jobId=${job_info.job_id}`)
                                    }}>
                                    <span style={{ fontWeight: 400, fontSize: '14px', color: "#1F5D5E" }}>{STRINGS.EDIT_VALUE}</span>
                                    <EditImg />
                                </div>
                            }
                        </div>

                        <div className='material-tracker-card-details'>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", width: "100%" }}>
                                <div className='material-tracker-general-details' style={{ minWidth: "30%" }}>
                                    <h6>{STRINGS.JOB_NUMBER}</h6>
                                    <p>{job_info.job_number} </p>
                                </div>
                                <div className='material-tracker-general-details' style={{ minWidth: "30%" }}>
                                    <h6>{STRINGS.JOB_NAME}</h6>
                                    <p>{job_info.job_name}</p>
                                </div>
                                <div className='material-tracker-general-details' style={{ minWidth: "30%" }}>
                                    <h6>{STRINGS.LOCATION}</h6>
                                    <p>{job_info.job_address}</p>
                                </div>
                            </div>
                        </div>


                        <div className='material-tracker-card-details'>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", width: "100%" }}>
                                <div className='material-tracker-general-details' style={{ minWidth: "30%" }}>
                                    <h6>{STRINGS.PROJECT_MANAGER}</h6>
                                    <p>{job_info.project_manager}</p>
                                </div>
                                <div className='material-tracker-general-details' style={{ minWidth: "30%" }}>
                                    <h6>{STRINGS.PROJECT_STATUS}</h6>
                                    <p>{convertToCamelCase(job_info.project_status)}</p>
                                </div>
                                <div className='material-tracker-general-details' style={{ flex: 1 }}>
                                    <h6>Duration</h6>
                                    <p>{formatDateWithMoment(job_info.start_date)} - {formatDateWithMoment(job_info.end_date)}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='material-tracker-form-divider' />

                    <div className='material-tracker-create-new-general-details'>

                        <div className='material-tracker-header' style={{ marginBottom: "2rem" }}>
                            <div className='material-tracker-material-status'>
                                <span className='material-tracker-header-card-header'>{STRINGS.MATERIAL_DETAILS}</span>
                            </div>
                        </div>

                        {job_materials?.map((material, index) => {
                            return (
                                <>
                                    <div className='material-tracker-header'>
                                        <div className='material-tracker-material-status'>
                                            <MaterialIconImg />
                                            <span className='material-tracker-header-card-header'>{material.material_name}</span>
                                        </div>
                                    </div>

                                    <div className='material-tracker-card-details'>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", width: "100%" }}>
                                            <div className='material-tracker-general-details' style={{ minWidth: "30%" }}>
                                                <h6>Material subtype</h6>
                                                <p>{material.subtype_name || 'N/A'}</p>
                                            </div>
                                            <div className='material-tracker-general-details' style={{ minWidth: "30%" }}>
                                                <h6>Material available </h6>
                                                <p>{formatNumberWithCommas(material.material_quantity_available)} {`${material?.material_name?.toLowerCase() === 'asphalt' ? 'tons' : 'C.Y'}`}</p>
                                            </div>
                                            <div className='material-tracker-general-details' style={{ minWidth: "30%" }}>
                                                <h6>Certification</h6>
                                                <p>{convertToCamelCase(material.material_certificate_status)}</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='material-tracker-material-details-card' style={{ minWidth: "calc(100% - 2rem)", backgroundColor: "#FAFBFC" }}>
                                        <div className='material-tracker-header'>
                                            <div className='material-tracker-material-status'>
                                                <span className='material-tracker-header-card-header' style={{ color: '#1B2E31CC', fontSize: '16px' }}>Material movement</span>
                                            </div>
                                            {/* <div className='material-tracker-material-status'>
                                                {material.material_availability === 'DEFICIT' ?
                                                    <UpArrowImg style={{ transform: 'rotate(180deg)' }} className='up-arrow' />
                                                    : <UpArrowImg />
                                                }

                                                <p className='material-tracker-card-updated'>{formatNumberWithCommas(material.material_quantity)} {`${material?.material_name?.toLowerCase() === 'asphalt'  ? 'tons' : 'C.Y'}`} </p>
                                            </div> */}
                                        </div>

                                        <div className='material-tracker-form-divider' style={{ margin: " 1rem 0px" }} />

                                        <div className='material-tracker-card-details'>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", width: "100%" }}>
                                                <div className='material-tracker-general-details' style={{ minWidth: "30%" }}>
                                                    <h6>{STRINGS.MATERIAL_QUANTITY}</h6>

                                                    <p className='material-tracker-card-updated'>
                                                        {material.material_quantity ? (
                                                            <>
                                                                {material.material_availability === 'DEFICIT' ? (
                                                                    <UpArrowImg style={{ transform: 'rotate(180deg)', marginRight: "0.2rem" }} className='up-arrow' />
                                                                ) : (
                                                                    <UpArrowImg style={{ marginRight: "0.2rem" }} />
                                                                )}
                                                                {formatNumberWithCommas(material.material_quantity)} {material?.material_name?.toLowerCase() === 'asphalt' ? 'tons' : 'C.Y'}
                                                            </>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </p>
                                                </div>
                                                <div className='material-tracker-general-details' style={{ minWidth: "30%" }}>
                                                    <h6>{STRINGS.MATERIAL_AVAILABILITY}  </h6>
                                                    <p>{material.material_availability ? convertToCamelCase(material.material_availability) : "-"}</p>
                                                </div>
                                                <div className='material-tracker-general-details' style={{ minWidth: "30%" }}>
                                                    <h6>{STRINGS.TRANSFER_DATE_WINDOW}</h6>
                                                    <p>{formatDateWithMoment(material.materials_acceptance_period.start_date)} - {formatDateWithMoment(material.materials_acceptance_period.end_date)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {index !== (job_materials.length - 1) &&
                                        <div className='material-tracker-form-divider' />
                                    }
                                </>
                            )
                        })}
                    </div>
                </>
            } </div>
    )
}

export default MaterialDetailCard;