import React, { useState, useRef, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { ReactComponent as DropdownImg } from '../assets/dropdown.svg';
import OutlinedInput from '@mui/material/OutlinedInput';

const iconStyling = { marginRight: "0rem", position: 'absolute', right: "10px", bottom: '15px', cursor: 'pointer' }

const fontAndSize = {
    fontFamily: "Plus Jakarta Sans",
    color: 'rgb(27, 46, 49)',
    fontSize: '12px',
    fontWeight: 400,
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "black",
        backgroundColor: '#f3f3f3',
    },
    margin: '0.1rem',
    borderRadius: "6px", // Add border radius to each option
    padding: '10px 12px'
}

function OutlinedFilterDropdown({ error, dropdownOptions = [], label, onValueChange, optionLabel, placeholder, value }) {
    const [isFocused, setIsFocused] = useState(false);
    const [loading, setIsLoading] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleCloseFocus = () => {
        setIsFocused(false);
    }

    // trigger if user clicks outside
    const divRef = useRef(null);

    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            handleCloseFocus()
            // Add your logic here
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <Typography className='input-label' sx={{ ...fontAndSize, padding: "0", fontSize: '14px' }}>
                {label}
            </Typography>
            <div className="searchable-outlined-input-new" style={{ height: "42px" }}>
                <OutlinedInput
                    onFocus={handleFocus}
                    fullWidth
                    required
                    size='small'
                    className='outline-input-customized'
                    placeholder={placeholder}
                    error={error}
                    value={value}
                />
                <DropdownImg style={iconStyling} />
                {error &&
                    <span key='err' style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem", position: 'absolute', left: "0px", bottom: "-20px" }}>
                        *required
                    </span>
                }
                {!loading && dropdownOptions?.length > 0 && isFocused &&
                    <div className="dropdown-outlined-list-new-bottom" ref={divRef} style={{ border: "1px solid #ECF0F3", top: "45px" }}>
                        {dropdownOptions.map((optionData, index) => {
                            return (<SingleDropdownList
                                optionData={optionData}
                                onChange={(...rest) => {
                                    onValueChange(...rest)
                                    handleCloseFocus()
                                }}
                                optionLabel={optionLabel}
                            />)
                        })}
                    </div>
                }
            </div>
        </>
    )
}

export default OutlinedFilterDropdown;


export function SingleDropdownList({ optionData, onChange, optionLabel }) {
    return (
        <>
            <div className="dropdown-outlined-list-option-new"
                key={optionData?.label}
                onClick={() => {
                    onChange(optionData)
                }}>
                {optionData[optionLabel] ? optionData[optionLabel] : optionData.label}
            </div>
        </>
    )
}