import React, { useEffect, useState } from 'react'
import CrossImg from '../../../assests/modalCross.svg';
import { materialAvailabilityValues, projectStatusValues } from '../addNewJobPayload';
import { GET_FILTER_DROPDOWN } from '../../../services/trackMaterialServices';
import { convertToCamelCase, decryptResponse } from '../../../utils/encryptionDecryption';
import StyledDatePicker from '../components/datePicker';
import STRINGS from '../../../helpers/staticContentHelper';
import moment from 'moment';
import FilterDropdown from './filterDropdown';

function FilterModal({
    setIsModalOpen,
    isModalOpen,
    selectedMaterials,
    setSelectedMaterials,
    getJObsList,
    materialDropdownCount,
    setMaterialDropdownCount,
    isFilterApplied,
    setIsFilterApplied
}) {
    const [materialDropdownList, setMaterialDropdownList] = useState([]);
    const [error, setError] = useState(false);
    const handleModal = () => {
        setIsModalOpen(false)
    }

    const getDropDownList = async () => {
        const res = await GET_FILTER_DROPDOWN();
        const listOfIds = {
            materialIds: [],
            materialSubIds: []
        }
        try {
            const decryptedData = JSON.parse(decryptResponse(res));
            let count = 0;
            const dropDownListFormMaterial = decryptedData.map(material => {
                listOfIds.materialIds.push(material.material.material_id)
                count++
                let obj = {}
                obj.material_name = material.material.material_name;
                obj.material_id = material.material.material_id;
                obj.subtypes = material.subtypes
                count = (count + material.subtypes.length)

                material.subtypes.forEach(element => {
                    listOfIds.materialSubIds.push(element.subtype_id)
                });
                return obj
            })
            setMaterialDropdownList(dropDownListFormMaterial)
            setMaterialDropdownCount(prev => {
                return { ...prev, materialCount: count, allMaterials: listOfIds }
            })
            console.log(listOfIds)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (isModalOpen && materialDropdownList.length === 0) {
            getDropDownList()
        }
    }, [isModalOpen])

    const getMovementsDataDivideByCommas = () => {
        let stringValue = selectedMaterials.material_availability.map(str => convertToCamelCase(str)).join(", ") || 'Select material availability '
        return stringValue
    }

    const getStatusDataDivideByCommas = () => {
        let stringValue = selectedMaterials.project_status.map(str => convertToCamelCase(str)).join(", ") || 'Select status'
        return stringValue
    }

    const getMaterialNamesAdnSubtypes = (listOfMaterialIds) => {
        let materialNames = []
        materialDropdownList.forEach(material => {
            // search inside the material 
            listOfMaterialIds.forEach(id => {
                if (id === material.material_id) {
                    materialNames.push(material.material_name)
                }
                material.subtypes.map(subtype => {
                    if (id === subtype.subtype_id) {
                        materialNames.push(subtype.subtype_name)
                    }
                })
            })
        })
        return materialNames
    }

    const getMaterialSelectedByCommas = () => {
        const materialNames = getMaterialNamesAdnSubtypes([...selectedMaterials.materials.material_ids, ...selectedMaterials.materials.subtype_ids])
        return materialNames.join(", ") || 'Select materials'
    }

    const handleDateValidation = () => {
        if ((selectedMaterials?.material_acceptance_range.start_date && selectedMaterials?.material_acceptance_range.end_date)
            || (!selectedMaterials?.material_acceptance_range.start_date && !selectedMaterials?.material_acceptance_range.end_date)) {
            setError(false)
            return false
        } else if (selectedMaterials?.material_acceptance_range.start_date && !selectedMaterials?.material_acceptance_range.end_date) {
            setError(true)
            return true
        } else if (!selectedMaterials?.material_acceptance_range.start_date && selectedMaterials?.material_acceptance_range.end_date) {
            setError(true)
            return true
        }
    }

    const handleFilterClick = () => {
        const isValidated = handleDateValidation()
        if (isValidated) return
        getJObsList()
        handleModal()
        setIsFilterApplied(true)
    }

    return (
        <div className={`pdf-side-view-modal-absolute`}
            style={{
                width: isModalOpen ? '100%' : '0px',
                // backgroundColor: "rgba(0, 0, 0, 0.5)",
                backgroundColor: "transparent",
                borderRadius: '0px',
                height: 'calc(100vh)',
                position: 'fixed',
            }} onClick={handleModal} >
            <div className={`pdf-side-view-modal pdf-side-view-modal-absolute`} style={{ width: isModalOpen ? '489px' : '0px', height: 'calc(100vh - 0.2rem)', boxShadow: '0px 8px 20px -4px #1718181F' }}
                onClick={(e) => e.stopPropagation()}>
                {isModalOpen &&
                    <>
                        <div className='pdf-side-view-modal-header'>
                            <div className='double-arrow-with-title'>
                                <p>Filter </p>
                            </div>
                            <img src={CrossImg} style={{ width: '0.8rem', cursor: "pointer" }} alt='not-found' onClick={handleModal} />
                        </div>

                        <div style={{ padding: '0px 1.8rem' }}>
                            <div style={{ flex: 1 }}>
                                <div className='flex-input-box'
                                    style={{ marginTop: '1rem' }}>
                                    <FilterDropdown
                                        subTypeValues={selectedMaterials.materials.subtype_ids}
                                        multipleValues={selectedMaterials.materials.material_ids}
                                        dropdownOptions={materialDropdownList}
                                        label='Material'
                                        onValueChange={(value, subOption) => {
                                            console.log(value, subOption)
                                            let deepCopy = JSON.parse(JSON.stringify(selectedMaterials));
                                            if (subOption) {
                                                if (deepCopy.materials.subtype_ids.includes(value.subtype_id)) {
                                                    const filteredOptions = deepCopy.materials.subtype_ids.filter(subtypeValue => subtypeValue !== value.subtype_id);
                                                    deepCopy.materials.subtype_ids = filteredOptions
                                                } else {
                                                    deepCopy.materials.subtype_ids.push(value.subtype_id);
                                                }
                                                if (!deepCopy.materials.material_ids.includes(value.material_id) && deepCopy.materials.subtype_ids.includes(value.subtype_id)) {
                                                    deepCopy.materials.material_ids.push(value.material.material_id);
                                                } else {
                                                    let isIncluded = false;
                                                    value.material.subtypes.forEach(element => {
                                                        if (deepCopy.materials.subtype_ids.includes(element.subtype_id)) {
                                                            isIncluded = true
                                                            return
                                                        }
                                                    });
                                                    if (!isIncluded && deepCopy.materials.material_ids.includes(value.material_id)) {
                                                        const filteredOptions = deepCopy.materials.material_ids.filter(material_id => material_id !== value.material_id);
                                                        deepCopy.materials.material_ids = filteredOptions
                                                    }
                                                }
                                            } else {
                                                if (deepCopy.materials.material_ids.includes(value.material_id)) {
                                                    const filteredOptions = deepCopy.materials.material_ids.filter(material_id => material_id !== value.material_id);
                                                    deepCopy.materials.material_ids = filteredOptions
                                                    let materialSubIds = deepCopy.materials.subtype_ids
                                                    const subIds = value.subtypes.map(element => element.subtype_id);
                                                    materialSubIds = materialSubIds.filter(item => !subIds.includes(item));
                                                    deepCopy.materials.subtype_ids = materialSubIds
                                                } else {
                                                    deepCopy.materials.material_ids.push(value.material_id);
                                                    let newSubTypeList = []
                                                    value.subtypes.forEach(element => {
                                                        if (!deepCopy.materials.subtype_ids.includes(element.subtype_id)) {
                                                            newSubTypeList.push(element.subtype_id)
                                                        }
                                                    });
                                                    let listOfIds = []
                                                    deepCopy.materials.subtype_ids.forEach(e => {
                                                        if (!newSubTypeList.includes(e)) {
                                                            listOfIds.push(e)
                                                        }
                                                    })
                                                    deepCopy.materials.subtype_ids = [...listOfIds, ...newSubTypeList]
                                                }
                                            }
                                            setSelectedMaterials(deepCopy);
                                        }}
                                        isAllChecked={materialDropdownCount.materialCount === (selectedMaterials.materials.material_ids.length + selectedMaterials.materials.subtype_ids.length)}
                                        placeholder={getMaterialSelectedByCommas()}
                                        optionLabel='material_name'
                                        optionValue='material_id'
                                        handleAllChecked={() => {
                                            setSelectedMaterials(prev => ({
                                                ...prev, 'materials': {
                                                    'material_ids': materialDropdownCount.allMaterials.materialIds,
                                                    'subtype_ids': materialDropdownCount.allMaterials.materialSubIds
                                                }
                                            }))
                                        }}
                                        handleClearAll={() => {
                                            setSelectedMaterials(prev => ({
                                                ...prev, 'materials': {
                                                    'material_ids': [],
                                                    'subtype_ids': []
                                                }
                                            }))
                                        }}
                                    />
                                </div>

                                <div className='flex-input-box' style={{ marginTop: '1rem' }}>
                                    <FilterDropdown
                                        multipleValues={selectedMaterials.project_status}
                                        dropdownOptions={projectStatusValues}
                                        label='Project status'
                                        onValueChange={(optionData) => {
                                            let deepCopy = JSON.parse(JSON.stringify(selectedMaterials));
                                            if (deepCopy.project_status.includes(optionData.value)) {
                                                const filteredOptions = deepCopy.project_status.filter(subtypeValue => subtypeValue !== optionData.value);
                                                deepCopy.project_status = filteredOptions
                                            } else {
                                                deepCopy.project_status.push(optionData.value);
                                            }
                                            setSelectedMaterials(deepCopy)
                                        }}
                                        isAllChecked={selectedMaterials.project_status.length === projectStatusValues.length}
                                        placeholder={getStatusDataDivideByCommas()}
                                        optionValue='value'
                                        handleAllChecked={() => {
                                            const filteredIds = projectStatusValues.map(i => i.value)
                                            setSelectedMaterials(prev => ({ ...prev, project_status: filteredIds }))
                                        }}
                                        handleClearAll={() => {
                                            setSelectedMaterials(prev => ({ ...prev, project_status: [] }))
                                        }}
                                    />
                                </div>
                                <div className='flex-input-box' style={{ marginTop: '1rem' }}>
                                    <FilterDropdown
                                        multipleValues={selectedMaterials.material_availability}
                                        dropdownOptions={materialAvailabilityValues}
                                        label='Material availability '
                                        onValueChange={(optionData) => {
                                            let deepCopy = JSON.parse(JSON.stringify(selectedMaterials));
                                            if (deepCopy.material_availability.includes(optionData.value)) {
                                                const filteredOptions = deepCopy.material_availability.filter(subtypeValue => subtypeValue !== optionData.value);
                                                deepCopy.material_availability = filteredOptions
                                            } else {
                                                deepCopy.material_availability.push(optionData.value);
                                            }
                                            setSelectedMaterials(deepCopy)
                                        }}
                                        isAllChecked={selectedMaterials.material_availability.length === materialAvailabilityValues.length}
                                        placeholder={getMovementsDataDivideByCommas()}
                                        optionValue='value'
                                        handleAllChecked={() => {
                                            const filteredIds = materialAvailabilityValues.map(i => i.value)
                                            setSelectedMaterials(prev => ({ ...prev, material_availability: filteredIds }))
                                        }}
                                        handleClearAll={() => {
                                            setSelectedMaterials(prev => ({ ...prev, material_availability: [] }))
                                        }}
                                    />
                                </div>

                                <div style={{ marginTop: '1rem', }}>
                                    <span style={{ fontSize: "14px", fontWeight: 400, color: "rgb(27, 46, 49)" }}>Material availability window</span>
                                    <div className='flex-input-box' style={{ display: "flex", justifyContent: 'center', gap: "1rem", marginTop: '0.5rem' }}>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                            <StyledDatePicker
                                                isFilterStyle={true}
                                                placeHolder='Enter start date'
                                                label=''
                                                value={selectedMaterials?.material_acceptance_range.start_date ?
                                                    moment(selectedMaterials?.material_acceptance_range.start_date, "DD-MM-YYYY").toDate()
                                                    : ''
                                                }
                                                onChange={(date) => {
                                                    if (date) {
                                                        let deepCopy = JSON.parse(JSON.stringify(selectedMaterials));
                                                        deepCopy.material_acceptance_range.start_date = moment(date).format("DD-MM-YYYY");
                                                        setSelectedMaterials(deepCopy)
                                                    }
                                                }}
                                                minDate={'01/01/1990'}
                                            />
                                            {(error && !selectedMaterials?.material_acceptance_range.start_date) &&
                                                <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                                    {STRINGS.REQUIRED_FIELD_ERROR_MSG}
                                                </span>
                                            }
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                            <StyledDatePicker
                                                isFilterStyle={true}
                                                placeHolder='Enter end date'
                                                label=''
                                                value={selectedMaterials?.material_acceptance_range.end_date ?
                                                    moment(selectedMaterials?.material_acceptance_range.end_date, "DD-MM-YYYY").toDate()
                                                    : ''
                                                }
                                                onChange={(date) => {
                                                    if (date) {
                                                        let deepCopy = JSON.parse(JSON.stringify(selectedMaterials));
                                                        deepCopy.material_acceptance_range.end_date = moment(date).format("DD-MM-YYYY");
                                                        setSelectedMaterials(deepCopy)
                                                    }
                                                }}
                                                minDate={'01/01/1990'}
                                            />
                                            {(error && !selectedMaterials?.material_acceptance_range.end_date) &&
                                                <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                                    {STRINGS.REQUIRED_FIELD_ERROR_MSG}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div style={{ padding: '0.5rem', position: 'absolute', bottom: '0px', width: 'calc(100% - 1rem)', justifyContent: 'flex-end', display: 'flex' }}>
                    {isFilterApplied && <button className='button-secondary-web'
                        onClick={() => {
                            handleModal()
                            getJObsList(
                                {
                                    "materials": {
                                        'material_ids': [],
                                        'subtype_ids': []
                                    },
                                    "project_status": [],
                                    "material_availability": [],
                                    "material_acceptance_range": {}
                                }
                            )
                            setIsFilterApplied(false)
                        }}>
                        Remove filter
                    </button>}
                    <button className='button-primary-web'
                        style={{ marginLeft: "1rem" }}
                        onClick={handleFilterClick}>
                        Apply
                    </button>

                </div>
            </div>
        </div>
    )
}

export default FilterModal;