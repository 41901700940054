
import React, { useState } from 'react';
import '../../project/fileUpload.css'
import uploadFile from '../../../assests/uploadFile.svg';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import pdfImg from '../../../assests/pdfIcon.svg'
import modalCross from '../../../assests/modalCrossWhite.svg'
import modalCrossBlack from '../../../assests/modalCross.svg'
import TextField from '@material-ui/core/TextField'
import IndexingFileModal from '../../projectDetails/fileIndexingModal';
import STRINGS from '../../../helpers/staticContentHelper';
import Modal from '@mui/material/Modal';
import { baseTypography, buttonStyle, popUpStyle } from '../../newProjects/components';
import { decryptResponse } from '../../../utils/encryptionDecryption';
import { LinearProgress } from '@mui/material';
import { UPLOAD_CERTIFICATE } from '../../../services/trackMaterialServices';

export default function UploadConfigurationFile({ open, setOpen, selectedUploadFile, setJobData, jobData }) {
    const [dropFile, setDropFile] = useState({})
    const [isDuplicateFilesDetected, setIsDuplicateFilesDetected] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [sizeLimit, setSizeLimit] = useState(false)
    const [isEditable, setIsEditable] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [singleFileLimit, setSingleFileLimit] = useState(false);
    const [editedFileName, setEditedFileName] = useState([]);

    const handleClose = () => {
        setIsDuplicateFilesDetected(false)
        setUploadedFiles([])
        setEditedFileName([])
        setDropFile({})
        setIsFileUploading(false)
        setOpen(false)
    };

    const onUpload = (files) => {
        setSingleFileLimit(prev => false)
        let numberOfFiles = []
        setUploadedFiles(prev => {
            numberOfFiles = [...prev, ...files]
            if (numberOfFiles.length > 1) {
                setSizeLimit(false)
                setIsModalOpen(true)
            }
            return numberOfFiles.slice(0, 1)
        })

        numberOfFiles.forEach(file => {
            if (file && (file?.size > 512 * 1024 * 1024)) {
                setSizeLimit(true)
                setIsModalOpen(true)
                setUploadedFiles([])
            }
        })
    };

    const addFileToProject = async () => {
        const formData = new FormData();
        uploadedFiles.forEach((file, index) => {
            formData.append(`files`, file);
        });
        setIsFileUploading(true)
        const res = await UPLOAD_CERTIFICATE(formData);
        const parsedResponse = JSON.parse(decryptResponse(res))
        let jobMaterials = JSON.parse(JSON.stringify(jobData));
        let certificate_id = parsedResponse?.material_certificate_id || null
        jobMaterials['job_materials'][selectedUploadFile].material_certificate_id = certificate_id;
        setJobData(prev => jobMaterials)
        setIsFileUploading(false)
        setOpen(false)
    }

    const fileInputRef = React.useRef(null);
    const [selectedFileIndex, setSelectedFileIndex] = React.useState(0);

    const handleChange = (event, index) => {
        const newEditList = [...editedFileName]
        newEditList[index] = event.target.value
        setEditedFileName(newEditList);
    };

    React.useEffect(() => {
        if (dropFile.current) {
            dropFile.current.addEventListener('dragover', handleDragOver);
            dropFile.current.addEventListener('drop', handleDrop);
        }
    }, [dropFile]);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { files } = e.dataTransfer;

        if (files && files.length) {
            onUpload(files);
        }
    };

    const truncateFileName = (fileName) => {
        if (fileName.length > 25) {
            return fileName.slice(0, 25) + '...'
        }
        return fileName
    }

    const handleFileSelect = (event) => {
        const files = event.target.files;
        onUpload(files);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={popUpStyle}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={modalCrossBlack} alt='crossIcon'
                        style={{
                            backgroundColor: "#1B2E3114",
                            padding: '0.5rem',
                            borderRadius: '1rem',
                            cursor: "pointer"
                        }} onClick={handleClose} />
                </Box>
                <div className='FilesDragAndDrop' style={{ paddingBottom: "0px" }} ref={(e) => {
                    if (e) {
                        setDropFile(dropFile.current = e)
                    }
                }}>
                    <IndexingFileModal open={isModalOpen}
                        setOpen={setIsModalOpen}
                        title={STRINGS.UPLOAD_LIMIT_REACHED}
                        description={sizeLimit ? STRINGS.FILE_LIMIT_512MB : 'you can upload a maximum of 1 file'}
                        singleFileLimitText={singleFileLimit ? STRINGS.MAX_FILE_FILE_FOR_GPT : ''}
                    />
                    <div className='FilesDragAndDrop__area' style={{ padding: "1rem 0px 0px 0px", ...(isDuplicateFilesDetected && { border: 'none', padding: 0, margin: 0 }) }}>
                        {!isDuplicateFilesDetected &&
                            <input type="image" src={uploadFile} alt='fileUpload' height='50px' htmlFor="fileInput"
                                accept="application/pdf,application/vnd.ms-excel"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (!isDuplicateFilesDetected) {
                                        document.getElementById("fileInput").click();
                                    }
                                }} />}
                        {!isDuplicateFilesDetected ?
                            <p style={{ ...baseTypography, textAlign: 'center' }}>{STRINGS.DRAG_AND_DROP} <span />
                                <label htmlFor="fileInput" style={{
                                    color: "#006D6B", cursor: "pointer",
                                    ...baseTypography, textDecoration: "underline"
                                }}>
                                    {STRINGS.BROWSE_FILES}
                                </label>
                                <input
                                    accept="application/pdf,application/vnd.ms-excel"
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={handleFileSelect}
                                    ref={fileInputRef}
                                    multiple
                                />
                                <p style={{ ...baseTypography, textAlign: 'center', lineHeight: "0px" }}>{STRINGS.PDF_WARN}</p>
                            </p> :
                            <p style={{ ...baseTypography, textAlign: 'center', fontWeight: 600, fontSize: '24px', margin: '0px' }}>{isDuplicateFilesDetected && isFileUploading ? 'Adding files' : 'Duplicate files detected'} <span />
                                {!isFileUploading && <p style={{ ...baseTypography, textAlign: 'center', lineHeight: "0px", fontWeight: 300, color: '#6D6D6D', fontSize: '14px' }}> Please rename the files before uploading them again</p>}
                            </p>
                        }
                    </div>
                    {/* files list  */}
                    {uploadedFiles?.length > 0 &&
                        <Box sx={{
                            display: 'flex',
                            alignItems: "center",
                            flexDirection: 'column',
                            gap: '10px',
                            maxHeight: '350px',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            marginTop: '1rem',
                            padding: '0.1rem',
                            borderRadius: '0.2rem'
                        }}>
                            {uploadedFiles?.map((uploadedFile, index) => {
                                if (isEditable && selectedFileIndex !== index) return <></>
                                return (
                                    <Box sx={{
                                        p: 1, display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        backgroundColor: "white",
                                        width: 'calc(100% - 1rem)',
                                        border: '1px solid #ECF0F3',
                                        borderRadius: '8px'
                                    }} fullWidth >
                                        <Box sx={{ display: 'flex', gap: '0.8rem', overflow: 'hidden' }}>
                                            <img src={pdfImg} alt='imagenofound' />
                                            {isEditable ?
                                                <>
                                                    <TextField
                                                        value={editedFileName[index]}
                                                        onChange={(event) => handleChange(event, index)}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: { width: '190px', fontSize: '14px', padding: '0rem', flex: 1, ...baseTypography }
                                                        }}
                                                    />
                                                </>
                                                :
                                                <Typography id="modal-modal-description" sx={{
                                                    textAlign: 'center',
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    color: '#6D6D6D',
                                                    ...baseTypography
                                                }}>
                                                    {truncateFileName(editedFileName[index] ? editedFileName[index] : uploadedFile?.name)}
                                                </Typography>
                                            }
                                        </Box>
                                        {!isFileUploading &&
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '0.9rem' }}>
                                                {!isEditable ? (
                                                    <>
                                                        <img src={modalCross} alt='crossIcon'
                                                            style={{
                                                                backgroundColor: "#006D6B",
                                                                padding: '0.5rem',
                                                                borderRadius: '1rem',
                                                                cursor: "pointer",
                                                            }} onClick={() => {
                                                                setIsEditable(false)

                                                                const uploadedFilesList = uploadedFiles?.filter((file, idx) => {
                                                                    return idx !== index
                                                                })
                                                                const editedFileNameList = editedFileName?.filter((file, idx) => {
                                                                    return idx !== index
                                                                })

                                                                if (uploadedFilesList.length === 0) {
                                                                    setIsDuplicateFilesDetected(false)
                                                                }

                                                                setUploadedFiles(uploadedFilesList)

                                                                setEditedFileName(editedFileNameList)
                                                            }
                                                            } />
                                                    </>
                                                )
                                                    :
                                                    (<>
                                                        <Typography id="modal-modal-description" sx={{
                                                            textAlign: 'center',
                                                            fontSize: '15px',
                                                            paddingTop: '0.1rem',
                                                            fontWeight: 600,
                                                            color: '#006D6B',
                                                            cursor: 'pointer',
                                                            ...baseTypography

                                                        }} onClick={() => setIsEditable(false)}>
                                                            {STRINGS.SAVE}
                                                        </Typography>
                                                        <img src={modalCross} alt='crossIcon'
                                                            style={{
                                                                backgroundColor: "#006D6B",
                                                                padding: '0.5rem',
                                                                borderRadius: '1rem',
                                                                cursor: "pointer",
                                                            }} onClick={() => {
                                                                const newEditList = [...editedFileName]
                                                                newEditList[index] = uploadedFile?.name
                                                                setEditedFileName(newEditList);
                                                                setIsEditable(false)
                                                            }
                                                            } />
                                                    </>
                                                    )
                                                }
                                            </Box>}
                                    </Box>
                                )
                            })
                            }
                        </Box>
                    }
                    {isFileUploading &&
                        <LinearProgress color='success' />
                    }
                    {!isFileUploading && !isEditable &&
                        <Button variant="outlined" size="large" fullWidth
                            sx={{
                                ...buttonStyle, ...baseTypography,
                                backgroundColor: uploadedFiles.length === 0 ? 'white' : '#1B2E31',
                                color: "white", mt: 2
                            }}
                            onClick={addFileToProject}
                            disabled={uploadedFiles.length === 0}
                        >
                            {STRINGS.UPLOAD_FILES}
                        </Button>
                    }
                </div>
            </Box>
        </Modal>
    );
}