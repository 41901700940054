import React, { useState } from 'react';
import { ReactComponent as DirectionsImg } from '../assets/directions.svg';
import { ReactComponent as CrossImg } from '../assets/cross.svg';
import { ReactComponent as TrackerImg } from '../assets/tracker.svg';
import { ReactComponent as SwapLocationsImg } from '../assets/swapLocations.svg';
import { ReactComponent as AddDestinationImg } from '../assets/addDestination.svg';
import SearchableUnderlinedDropdown from './test';
function checkType(value) {
    if (typeof value === 'function') {
        return 'function'
    } else {
        return 'number'
    }
}

function MapsDirectionsComponent({ locationPoints, setLocationsPoints, response, setResponse, setPointerValue }) {
    const [isDirectionsEnabled, setIsDirectionsEnabled] = useState(false);

    const triggerSetPointerValue = (routeData) => {
        let latitude = checkType(routeData.legs[0].steps[0].start_point.lat) === 'function' ? routeData.legs[0].steps[0].start_point.lat() : routeData.legs[0].steps[0].start_point.lat
        let longitude = checkType(routeData.legs[0].steps[0].start_point.lng) === 'function' ? routeData.legs[0].steps[0].start_point.lng() : routeData.legs[0].steps[0].start_point.lng
        let middleValue = Math.round(routeData.legs[0].steps.length / 2);
        const actualPointerValue = {
            ...routeData.legs[0].steps[middleValue].start_point,
            lat: latitude,
            lng: longitude,
            distance: (routeData.legs[0].distance.value / 1609.34).toFixed(2),
            duration: routeData.legs[0].duration.text,
        }
        setPointerValue(actualPointerValue)
    }
    return (
        <div className='track-material-directions-component'>
            <div className='display-flex-between'>
                <span>Directions</span>
                {isDirectionsEnabled ? <CrossImg style={{ cursor: "pointer" }} onClick={() => setIsDirectionsEnabled(prev => !prev)} /> :
                    <DirectionsImg style={{ cursor: "pointer" }} onClick={() => setIsDirectionsEnabled(prev => !prev)} />
                }
            </div>

            {isDirectionsEnabled &&
                <>
                    <div className='display-flex-between' style={{ marginTop: "1rem" }}>
                        {/* <div style={{ padding: '0rem 0.5rem' }}>
                            <TrackerImg />
                        </div> */}
                        <div className='display-flex-between' style={{ flexDirection: 'column', gap: '0.8rem', flex: 1}}>
                            {
                                locationPoints.map((locationPoint, index) => {
                                    return (
                                        <>
                                            <SearchableUnderlinedDropdown
                                                index={index}
                                                label="Choose destination or click on the map"
                                                optionKey='locationOne'
                                                setLocationsPoints={setLocationsPoints}
                                                value={locationPoint.name}
                                                locationPoints={locationPoints}
                                                options={[]}
                                            />
                                        </>
                                    )
                                })
                            }
                        </div>
                        {locationPoints.length === 2 &&
                            <div style={{ paddingLeft: '0.5rem', cursor: 'pointer' }} onClick={() => {
                                let copyData = JSON.parse(JSON.stringify(locationPoints));
                                const locationOne = copyData[0]
                                copyData[0] = copyData[1]
                                copyData[1] = locationOne
                                setLocationsPoints(prev => copyData);
                            }}>
                                <SwapLocationsImg />
                            </div>
                        }
                    </div>
                    {locationPoints[0]?.name && locationPoints[1]?.name &&
                        <>
                            <div className='add-destination-map' onClick={() => setLocationsPoints(prev => {
                                return [...prev, {}]
                            })}>
                                <AddDestinationImg />
                                Add destination
                            </div>
                            <div style={{ borderTop: "1px solid #ECF0F3", margin: "1rem 0px" }} />
                        </>
                    }
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        {response?.availableRoutes?.length > 0 &&
                            response?.availableRoutes.map((routeData, index) => {
                                return (
                                    <div className='add-destination-map' onClick={() => {
                                        let deepCopy = JSON.parse(JSON.stringify(response))
                                        deepCopy.routes = [routeData]
                                        deepCopy.activeRoute = index
                                        setResponse(deepCopy)
                                        triggerSetPointerValue(routeData)
                                    }} style={{ flexDirection: "column", marginTop: "0px", paddingTop: "0px", cursor: "pointer" }}>
                                        <div className='add-destination-map route-details' style={{ backgroundColor: response.activeRoute === index && "#F2FAFA" }} onClick={() => { }} >
                                            <div className='flex-below-other'>
                                                <p>{routeData.legs[0].duration.text}</p>
                                                <span>via A457</span>
                                            </div>
                                            <div className='miles-details'>
                                                {routeData.legs[0].distance.text}les
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default MapsDirectionsComponent;