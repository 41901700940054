import React, { useEffect, useState } from 'react'
import CrossImg from '../../../assests/modalCross.svg';
import Box from '@mui/material/Box';
import StepContent from '@mui/material/StepContent';
import { ReactComponent as MaterialImg } from '../assets/material.svg'
import { ReactComponent as ShowMoreTimelineImg } from '../assets/showMoreTimemline.svg';
import { GET_VERSION_HISTORY } from '../../../services/trackMaterialServices';
import { decryptResponse } from '../../../utils/encryptionDecryption';
import { Stepper, Step, StepLabel } from '@mui/material';
import STRINGS from '../../../helpers/staticContentHelper';

// Custom SVG Icon Component
const CustomStepIcon = () => {
    return (
        <div
            style={{
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
            }}
        >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="#ABF5D1" fill-opacity="0.4" />
                <path d="M16 15.9987C17.8409 15.9987 19.3333 14.5063 19.3333 12.6654C19.3333 10.8244 17.8409 9.33203 16 9.33203C14.159 9.33203 12.6666 10.8244 12.6666 12.6654C12.6666 14.5063 14.159 15.9987 16 15.9987Z" stroke="#1B2E31" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21.7266 22.6667C21.7266 20.0867 19.16 18 16 18C12.84 18 10.2733 20.0867 10.2733 22.6667" stroke="#1B2E31" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </div>
    );
};


function TimelineModal({ setIsModalOpen, isModalOpen, jobId }) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [steps, setSteps] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const handleModal = () => {
        setIsModalOpen(false)
    }

    const getVersionHistoryData = async (jobId) => {
        setIsLoading(true)
        const res = await GET_VERSION_HISTORY(jobId)
        const parsedResponse = JSON.parse(decryptResponse(res))
        setSteps(prev => parsedResponse)
        setIsLoading(false)
    }

    useEffect(() => {
        if (isModalOpen && jobId) {
            setActiveStep(0)
            getVersionHistoryData(jobId)
        }
    }, [isModalOpen])


    return (
        <div className={`pdf-side-view-modal-absolute`}
            style={{
                width: isModalOpen ? '100%' : '0px',
                // backgroundColor: "rgba(0, 0, 0, 0.5)",
                backgroundColor: "transparent",
                borderRadius: '0px',
                height: 'calc(100vh)',
                position: 'fixed',
            }} onClick={handleModal} >
            <div className={`pdf-side-view-modal pdf-side-view-modal-absolute`} style={{ width: isModalOpen ? '500px' : '0px', height: 'calc(100vh - 0.2rem)', boxShadow: '0px 8px 20px -4px #1718181F' }}
                onClick={(e) => e.stopPropagation()}>
                {isModalOpen && !isLoading ?
                    <>
                        <div className='pdf-side-view-modal-header'>
                            <div className='double-arrow-with-title'>
                                <p>Activities </p>
                            </div>
                            <img src={CrossImg} style={{ width: '0.8rem', cursor: "pointer" }} alt='not-found' onClick={handleModal} />
                        </div>

                        <Box sx={{ padding: "0px 1rem", overflow: "scroll", paddingBottom: '0.5rem' }}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.label}>
                                        <StepLabel
                                            StepIconComponent={CustomStepIcon}
                                            sx={{ alignItems: 'flex-start' }}
                                        >
                                            <div className='step-header-text' style={{ position: 'relative' }}>
                                                <p>{step.version_added_by.name} <span>modified </span>{step.comparisons_list.length} {step.comparisons_list.length === 1 ? 'entry' : 'entries'} </p>
                                                {/* <p style={{ fontSize: '14px', color: '#6D6D6D' }}>from {step.comparisons_list[0].old_value}  to {step.comparisons_list[0].new_value} C.Y</p>
                                                <p style={{ fontSize: '11px', color: '#6D6D6D' }}>f3 Jan 2025 . 10:45 AM</p> */}
                                                {activeStep !== index &&
                                                    <div className='show-more-info-timeline'
                                                        onClick={() => {
                                                            setActiveStep(index)
                                                        }}>
                                                        {STRINGS.SHOW_DETAILS}
                                                        <ShowMoreTimelineImg style={{ transform: 'rotate(180deg)' }} />
                                                    </div>
                                                }
                                            </div>
                                        </StepLabel>
                                        <StepContent>
                                            {step.comparisons_list.map((i, index) => {
                                                console.log(i , '__materialData__')
                                                return (
                                                    <div className='timeline-details-card' style={{ marginTop: index > 0 && "1rem" }}>
                                                        <div className='timeline-card-title-info'>
                                                            <p>{i.description}</p>
                                                            {i.status === 'Updated' &&
                                                                <span><MaterialImg /> {i.entity_name}</span>
                                                            }
                                                        </div>
                                                        <div className='timeline-card-info-in-detail'>
                                                            {i.status === 'Updated' &&
                                                                <>
                                                                    <p>
                                                                        {STRINGS.OLD} <span style={{ color: "#1B2E31" }}>{i.old_value}</span>
                                                                    </p>
                                                                    <p style={{ marginTop: '8px' }}>
                                                                        {STRINGS.CHANGED_TO} <span style={{ color: "#1B2E31" }}>{i.new_value}</span>
                                                                    </p>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className='show-more-info-timeline'
                                                style={{ marginTop: '1rem' }}
                                                onClick={() => {
                                                    setActiveStep(null)
                                                }}>
                                                {STRINGS.HIDE_DETAILS} <ShowMoreTimelineImg />
                                            </div>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </> :
                    <div className='no-projects-found'>
                        <div class="loading-dots">
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default TimelineModal;



