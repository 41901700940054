import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as RIghtArrow } from '../../assests/right.svg';
import { ReactComponent as LocationdetailImg } from '../materialSearch/assets/locationDetail.svg';
import moment from 'moment/moment';
import MaterialHistoryModal from '../../components/materialHistoryModal.js/index.js';
import { GET_LANDFILL_HISTORY } from '../../services/projects.services.js';
import { decryptResponse } from '../../utils/encryptionDecryption.js';
import STRINGS from '../../helpers/staticContentHelper.js';
import TableSkeletonLoader from './components/tableSkeletonLoader.js';
import NoDataFound from './components/noDataFound.js';

function LandFillsSearchHistory() {
    const [isLoading, setIsLoading] = useState(true);
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({})
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        const getListOfHistroty = async () => {
            try {
                let res = await GET_LANDFILL_HISTORY();
                const parsedResponse = JSON.parse(decryptResponse(res))
                setIsLoading(false)
                setTableData(prev => {
                    return parsedResponse
                })
            } catch (error) {
                console.log(error)
            }

        }
        getListOfHistroty()
    }, [])

    const columns = [
        {
            id: 'created_at', label: 'DATE', align: 'start', minWidth: "110px", format: (value, row) => {
                return <span style={{ fontSize: '14px', fontWeight: "500", fontFamily: 'Plus Jakarta Sans' }}>{moment(value).format('DD/MM/YYYY')}</span>
            }
        },
        {
            id: 'landfill_name', label: 'LANDFILL NAME', align: 'start', minWidth: "110px", format: (value, row) => {
                return (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div className='material-search-address-table-view'>
                            <p>{value.substring(0, 20)} {value.length > 20 && '...'}</p>
                        </div>
                    </div>
                )
            }
        },
        {
            id: 'address', label: 'ADDRESS', align: 'start', format: (value, row) => {
                let destination = row.destination_details.formatted_address;
                let supplier = row.formatted_address;
                return (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div className='material-search-address-table-view'>
                            <span>Destination:</span>
                            <p>{destination.substring(0, 25)} {destination.length > 25 && '...'}</p>
                        </div>
                        <div className='material-search-address-table-view'>
                            <span>Supplier:</span>
                            <p>{supplier.substring(0, 25)} {supplier.length > 25 && '...'}</p>
                        </div>
                    </div>
                )
            }
        },
        {
            id: 'distance', label: 'DISTANCE', align: 'start', maxWidth: '100px',
            format: (value) => {
                return (
                    <div className='material-search-address-table-view'>
                        <span>{value.time}</span>
                        <p>{value.miles} miles</p>
                    </div>
                )
            }
        },
        {
            id: 'materials', label: 'MATERIALS', align: 'start',
            format: (value, row) => {
                const stringToRender = row.materials_accepted.slice(0, 2).join(", ");
                let remainingFilters = row.materials_accepted.length;

                if (stringToRender.length > 22) {
                    remainingFilters++
                }

                return (
                    <div style={{ display: 'flex', flexDirection: "row" }}>
                        <div className='material-search-address-table-view'>
                            <p>{stringToRender.substring(0, 22)} {(stringToRender.length > 22 || remainingFilters > 2) && '...'}</p>
                        </div>
                        {remainingFilters > 2 &&
                            <div className='materials-more-chip'>+{remainingFilters - 2}</div>
                        }
                    </div>
                )
            }
        },
        {
            id: 'action', label: '', align: 'start',
            format: (value, row) => {
                return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1.2rem' }}><LocationdetailImg /> <RIghtArrow /> </div>
            }
        },
    ];

    return (
        <div className='material-search-history-main' style={{ height: "100%", paddingTop: '0px' , padding:'0px'}}>
            {isHistoryModalOpen &&
                <MaterialHistoryModal
                    selectedLocation={selectedLocation}
                    setIsModalOpen={setIsHistoryModalOpen}
                    isModalOpen={isHistoryModalOpen} />
            }

            {isLoading ? <TableSkeletonLoader /> :
                tableData.length === 0 ?
                    <NoDataFound title={STRINGS.NO_HISTORY_FOUND}
                        subTitle={STRINGS.NO_HISTORY_FOUND_SUB_TITLE} /> :
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                width: column.width,
                                                minWidth: column.minWidth,
                                                padding: "0.4rem 0.1rem",
                                                color: '#6D6D6D',
                                                fontFamily: 'Plus Jakarta Sans'
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.length > 0 && tableData.map((row) => {
                                    return (
                                        <TableRow hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                            style={{ cursor: "pointer", height: "62px" }}
                                            onClick={() => {
                                                setIsHistoryModalOpen(prev => !prev)
                                                setSelectedLocation(row)
                                            }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}
                                                        sx={{
                                                            position: 'relative', padding: '0.5rem 0.1px',
                                                            paddingRight: '1rem',
                                                            fontFamily: 'Plus Jakarta Sans',
                                                            maxWidth: column.maxWidth && column.maxWidth
                                                        }}>
                                                        {column.format
                                                            ? column.format(value, row)
                                                            : value}

                                                    </TableCell>

                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </div>
    )
}

export default LandFillsSearchHistory

