import React, { useEffect, useState } from 'react';
import { ViewOptions } from './components/options';
import CreateAndEditJob from './createAndEditJob';
import MaterialDetailCard from './components/materialDetailCard';
import { ReactComponent as FilterImg } from './assets/filter.svg';
import FilterModal from './components/filterModal';
import MapsComponentForMaterial from './components/mapsComponent';
import { ReactComponent as NoMaterialFound } from './assets/noMaterialFound.svg';
import NoDataFound from '../materialSearch/components/noDataFound';
import MapsDirectionsComponent from './components/mapsDirectionsComponent';
import MapFilters from './components/mapFilters';
import { FETCH_LOCATION_ROUTES } from '../materialSearch/googleSerive';
import { GET_JOBS } from '../../services/trackMaterialServices';
import { LStorage } from '../../utils/localStorage.Helper';
import { decryptResponse } from '../../utils/encryptionDecryption';

let initialFilterData = {
  "materials": {
    'material_ids': [],
    'subtype_ids': []
  },
  "project_status": [],
  "material_availability": [],
  "material_acceptance_range": {}
}

function MaterialTrackerDashboard() {
  const [selectedMaterials, setSelectedMaterials] = useState(initialFilterData);
  const userData = LStorage.getUserData()
  const [selectedOption, setSelectedOption] = useState('List view');
  const [isAddJobEnabled, setIsAddJobEnabled] = useState(false);
  const [editJobData, setEditJobData] = useState(null);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [pointerValue, triggerSetPointerValue] = useState(null);
  const [locationPoints, setLocationsPoints] = useState([{}, {}]);
  const [response, setResponse] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [selectedLocationForDirections, setSelectedLocationForDirections] = useState({
    locationOne: null,
    locationTwo: null,
    locationThree: null
  })
  const [materialDropdownCount, setMaterialDropdownCount] = useState({
    materialCount: 0,
    allMaterials: {
      materialIds: [],
      materialSubIds: []
    }
  });

  const getLocationData = async () => {
    if (locationPoints.length >= 2 && locationPoints[0]?.name && locationPoints[1]?.name) {

      const reqPayloadForDirection = {
        origin: locationPoints[0],
        destination: locationPoints[1],
      }

      if (locationPoints.length > 2 && locationPoints[locationPoints.length - 1].name) {
        const waypoints = locationPoints.slice(2).map(location => {
          return { location: location }
        });
        reqPayloadForDirection.waypoints = waypoints
      }

      const responseForRoute = await FETCH_LOCATION_ROUTES(reqPayloadForDirection);
      responseForRoute.availableRoutes = responseForRoute.routes
      responseForRoute.routes = [responseForRoute.availableRoutes[0]]
      responseForRoute.activeRoute = 0
      setResponse(prev => responseForRoute);

    } else {
      setResponse(prev => null)
    }
  }

  useEffect(() => {
    if (selectedOption !== 'List view') {
      getLocationData()
    }
  }, [selectedOption, locationPoints]);

  const handleMapClick = (event) => {
    // if (!locationPoints[locationPoints.length - 1].name && locationPoints.length > 2) {
    //   const location = {
    //     lat: event.latLng.lat(),
    //     lng: event.latLng.lng(),
    //     name: 'test location'
    //   };
    //   setLocationsPoints(prev => {
    //     let copyValues = JSON.parse(JSON.stringify(prev))
    //     copyValues[copyValues.length - 1] = location
    //     return copyValues
    //   })
    // }
  };

  const getJObsList = async (payload) => {
    try {
      if (payload) {
        setSelectedMaterials(initialFilterData)
      }
      setJobsLoading(true)
      const res = await GET_JOBS(payload || selectedMaterials);
      const decryptedResponse = JSON.parse(decryptResponse(res))
      setJobs(decryptedResponse)
      setJobsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getJObsList()
  }, [])

  if (jobsLoading) {
    return (
      <div className='no-projects-found'>
        <div class="loading-dots">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    )
  }

  const getFilterCount = () => {
    return selectedMaterials.materials.material_ids.length + selectedMaterials.materials.subtype_ids.length + selectedMaterials.material_availability.length + selectedMaterials.project_status.length
  }

  return (
    <div className='material-tracker-dashboard' style={{ position: 'relative' }}>
      <FilterModal
        isFilterApplied={isFilterApplied}
        setIsFilterApplied={setIsFilterApplied}
        materialDropdownCount={materialDropdownCount}
        setMaterialDropdownCount={setMaterialDropdownCount}
        getJObsList={getJObsList}
        selectedMaterials={selectedMaterials}
        setSelectedMaterials={setSelectedMaterials}
        isModalOpen={isFilterActive}
        setIsModalOpen={setIsFilterActive} />
      {isAddJobEnabled ?
        <CreateAndEditJob
          editJobData={editJobData}
          isAddJobEnabled={isAddJobEnabled}
          setIsAddJobEnabled={setIsAddJobEnabled}
          jobs={jobs}
          getJObsList={getJObsList}
        />
        :
        <>
          {/* heder component  */}
          <div className='material-tracker-header' style={{ padding: "1rem 0px", position: 'sticky', top: '0px', backgroundColor: "white", zIndex: 1 }}>
            <ViewOptions selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
            <div style={{ display: 'flex', gap: '1rem' }}>
              <button className='button-secondary-web' style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
              }}
                onClick={() => {
                  setIsFilterActive(true)
                }}>
                {getFilterCount() > 0 && isFilterApplied &&
                  <div className='map-filters-for-material-badge'>
                    {getFilterCount()}
                  </div>
                }
                Filter
                <FilterImg style={{ marginLeft: '0.5rem' }} />
              </button>
              {(userData.role === 'admin' || userData.role === 'manager') &&
                <button className='button-primary-web' onClick={() => {
                  setIsAddJobEnabled(true);
                  setEditJobData(null);
                }}>
                  Add new job
                </button>}
            </div>
          </div>
          {/* header component end */}
          {selectedOption === 'List view' ?
            <>
              {
                jobs.length === 0 ?
                  <div className='full-width-and-height-position-relative'>
                    <NoDataFound
                      Icon={NoMaterialFound}
                      title='No Jobs Yet!'
                      subTitle='Looks like you haven’t added any jobs. Start tracking materials by creating your first job now.'
                      buttonText='Add new job'
                      handleButtonClick={() => {
                        setIsAddJobEnabled(true);
                        setEditJobData(null);
                      }}
                    />
                  </div>
                  :
                  <div className='material-tracker-create-new' style={{
                    padding: "0",
                    border: 'none',
                    width: "calc(100% - 2px)",
                    overflow: "auto",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    marginTop: '0px'
                  }}>
                    {jobs.map((jobData, index) => {
                      return <MaterialDetailCard
                        index={index}
                        data={jobData}
                        setIsAddJobEnabled={setIsAddJobEnabled}
                        setEditJobData={setEditJobData}
                      />
                    })}
                  </div>
              }
            </>
            :
            <div className='full-width-and-height-position-relative'>
              <MapsComponentForMaterial
                jobs={jobs}
                handleMapClick={handleMapClick}
                response={response}
                setLocationsPoints={setLocationsPoints}
                selectedLocationForDirections={selectedLocationForDirections}
                setSelectedLocationForDirections={setSelectedLocationForDirections}
                pointerValue={pointerValue}
              />
              <MapsDirectionsComponent
                locationPoints={locationPoints}
                setLocationsPoints={setLocationsPoints}
                selectedLocationForDirections={selectedLocationForDirections}
                setSelectedLocationForDirections={setSelectedLocationForDirections}
                response={response}
                setResponse={setResponse}
                setPointerValue={triggerSetPointerValue}
              />
              <MapFilters />
            </div>
          }
        </>
      }
    </div>
  )
}

export default MaterialTrackerDashboard;