import React, { useState } from 'react';
import { ReactComponent as FilterImg } from '../assets/filterBySite.svg';
import { styled } from '@mui/material/styles';
import { ReactComponent as CrossImg } from '../assets/cross.svg';
import { ReactComponent as LiveJobsImg } from '../assets/liveJobs.svg';
import { ReactComponent as UpComingJobsImg } from '../assets/upCOmingJobs.svg';
import { ReactComponent as DumpingYardImg } from '../assets/dumpingYards.svg';
import { ReactComponent as LandFillsImg } from '../assets/landFills.svg';
import { ReactComponent as VendorsImg } from '../assets/vendors.svg';
import Switch from '@mui/material/Switch';

const FILTER_OPTIONS = [
  {
    title: 'Live jobs',
    Icon: LiveJobsImg
  },
  {
    title: 'Up coming jobs',
    Icon: UpComingJobsImg
  },
  {
    title: 'Dump yard',
    Icon: DumpingYardImg
  },
  {
    title: 'Landfills',
    Icon: LandFillsImg
  },
  {
    title: 'Vendors',
    Icon: VendorsImg
  },
]

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1F5D5E',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    backgroundColor: 'white',
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#C2C2C2',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));

function MapFilters() {
  const [isFIlterActive, setIsFIlterActive] = useState(false)
  return (
    <div className='map-filters-for-material'>
      {!isFIlterActive ?
        <>
          <div className='map-filter-main-option' onClick={() => setIsFIlterActive(true)}>
            <FilterImg />
            <span>Filter by site</span>
          </div>
          <div className='map-filters-for-material-badge'>
            5
          </div>
        </>
        :
        <>
          <div className='map-filter-sub-options' style={{ marginBottom: '0.8rem' }}>
            <span>Filter by site</span>
            <CrossImg onClick={() => setIsFIlterActive(false)} style={{ padding: "6px", backgroundColor: "#1B2E3114", borderRadius: '50%', cursor: "pointer" }} />
          </div>

          <div className='divider-for-map-filter' />

          {FILTER_OPTIONS.map((singleOption, index) => {
            return (
              <div className='map-filter-sub-options' style={{ margin: '1rem 0px' }}>
                <div className='map-filter-main-option'>
                  <singleOption.Icon />
                  <span style={{ color: '#6D6D6D' }}>{singleOption.title}</span>
                </div>
                <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
              </div>
            )
          })}
        </>}
    </div>
  )
}

export default MapFilters