import React, { useEffect, useState } from 'react';
import './materialTracker.css';
import ManageJobUsers from './components/manageJobUsers';
import JobHeader from '../../components/jobsHeader ';
import { LStorage } from '../../utils/localStorage.Helper';
import { useStoreInviteUserContext } from '../../services/inviteUserContext';

function TrackMaterialLayout({ children }) {
    const { inviteUserModalOpen, setInviteUserModalOpen } = useStoreInviteUserContext();
    const userData = LStorage.getUserData()
    const [addUserModalOpen, setAddUserModalOpen] = useState(false);

    useEffect(() => {
        if (inviteUserModalOpen) {
            setAddUserModalOpen(true)
            setInviteUserModalOpen(false)
        }
    }, [inviteUserModalOpen])
    return (
        <div className='projects-main'>
            <ManageJobUsers open={addUserModalOpen} setOpen={() => setAddUserModalOpen(false)} userData={userData} />
            <JobHeader setOpen={false} tableData={[]} hideButtons={true} setAddUserModalOpen={setAddUserModalOpen} />

            <div className='welcome-screen-main-container' style={{ maxHeight: "calc(100% - 85px)", maxWidth: 'calc(100% - 2rem)' }}>
                {children}
            </div>
        </div>
    )
}

export default TrackMaterialLayout;