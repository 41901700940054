import { createContext, useContext, useState } from 'react';

const StoreConstructionGptContext = createContext();

export const StoreLocationHistoryValuesProvider = ({ children }) => {
    const [constructionGptValues, setConstructionGptValues] = useState({});

    return (
        <StoreConstructionGptContext.Provider value={{ constructionGptValues, setConstructionGptValues }}>
            {children}
        </StoreConstructionGptContext.Provider>
    );
};

export const useStoreConstructionGptContext = () => useContext(StoreConstructionGptContext);
