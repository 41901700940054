import * as React from 'react';
import Box from '@mui/material/Box';
import modalCross from '../../../../assests/modalCross.svg'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import { REMOVE_USER_FROM_JOB } from '../../../../services/trackMaterialServices';
import CircularProgress from '@mui/material/CircularProgress';
import { decryptResponse } from '../../../../utils/encryptionDecryption';
import STRINGS from '../../../../helpers/staticContentHelper';
import { useAlert } from '../../../../utils/alertsProvider';
import { DELTE_USER_FROM_ORG } from '../../../../services';

function UserDeleteModal({ open, setOpen, userData = {}, fetchUserList, jobId }) {
    const [isLoading, setIsLoading] = React.useState(false);
    const { showAlert } = useAlert();

    const handleClose = (e) => {
        setOpen(false);
    }

    const deleteOption = (e) => {
        setIsLoading(true)
        const reqData = {
            user_email: userData.email,
            org_id: userData.org_id,
        }
        if (jobId) {
            reqData.job_id = jobId
            REMOVE_USER_FROM_JOB(reqData).then(res => {
                const decryptedData = JSON.parse(decryptResponse(res));
                if (decryptedData?.removal_status) {
                    fetchUserList()
                    setOpen(false);
                    showAlert(STRINGS.JOB_ACCESS_REVOKED, userData.email, STRINGS.USER_HAS_BEEN_REMOVED_FROM_JOB)
                }
                setIsLoading(false)
            })
        } else {
            DELTE_USER_FROM_ORG(reqData).then(res => {
                const decryptedData = JSON.parse(decryptResponse(res));
                if (decryptedData?.delete_status) {
                    fetchUserList()
                    setOpen(false);
                    showAlert(STRINGS.DELETE_SUCCESSFUL, userData.email, STRINGS.USER_HAS_BEEN_REMOVED_FROM_JOB)
                }
                setIsLoading(false)
            })
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 420,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        px: 4,
        borderRadius: '0.5rem'
    };

    const buttonStyle = {
        border: "1px solid #E6E8EA",
        borderRadius: "10px",
        color: "black",
        textTransform: 'none',
        "&:hover": {
            border: "1px solid #E6E8EA",
            background: '#FF0000',
            opacity: '0.9',
            color: "white",
        },
        fontFamily: 'Plus Jakarta Sans'
    };
    const cancelButtonStyle = {
        border: "1px solid #E6E8EA",
        borderRadius: "10px",
        color: "black",
        textTransform: 'none',
        "&:hover": {
            border: "1px solid #E6E8EA",
        },
        fontFamily: 'Plus Jakarta Sans'
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            onClick={(e) => e.stopPropagation()}
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={modalCross} alt='crossIcon'
                        style={{
                            backgroundColor: "#1B2E3114",
                            padding: '0.5rem',
                            borderRadius: '1rem',
                            cursor: "pointer"
                        }} onClick={() => setOpen(false)} />
                </Box>
                <Typography id="modal-modal-description" sx={{
                    textAlign: 'center',
                    fontSize: '16px',
                    fontWeight: 400,
                    p: 1,
                    fontFamily: 'Plus Jakarta Sans'
                }}>
                    {jobId ? STRINGS.DELETE_FROM_JOB : STRINGS.DELETE_FROM_ORG}
                </Typography>
                <Typography id="modal-modal-description" sx={{
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#6D6D6D',
                    pb: 1,
                    fontFamily: 'Plus Jakarta Sans'
                }}>
                    <span style={{ fontWeight: 600, fontFamily: 'Plus Jakarta Sans' }}>{userData?.email}</span> <br /> <br />
                    <span style={{ fontSize: "0.7rem", fontFamily: 'Plus Jakarta Sans', marginTop: '1rem' }}>{STRINGS.THIS_ACTION_CANNOT_BE_UNDONE}</span>
                </Typography>

                {isLoading ?
                    <CircularProgress color="success" sx={{ color: '#124429', margin: 'auto', display: 'flex' }} />
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 1 }}>
                        <Button
                            type='submit'
                            variant="outlined"
                            size="large"
                            sx={{ ...cancelButtonStyle, backgroundColor: 'white', color: "black", width: "180px" }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            variant="outlined"
                            size="large"
                            sx={{ ...buttonStyle, backgroundColor: '#FF2400', color: "white", width: "180px" }}
                            onClick={deleteOption}
                        >
                            Delete
                        </Button>
                    </Box>

                }
            </Box>
        </Modal>
    )
}

export default UserDeleteModal
