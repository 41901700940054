import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, CircularProgress, Popper } from "@mui/material";
import { ReactComponent as DropdownImg } from '../assets/dropdown.svg';
import { styled } from "@mui/system";

export const UNDERLINE_INPUT_COLOR = {
    "& .MuiInputBase-root": {
        fontFamily: "Plus Jakarta Sans", // Set font family
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#1B2E31', // Change the border color on focus
        },
    },
    '& .MuiInputLabel-root': {
        fontFamily: "Plus Jakarta Sans",
        '&.Mui-focused': {
            color: '#1B2E31', // Change the label color on focus
        },
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: '#A3A7AA', // Default underline color
        borderBottomWidth: "1px"
    },
    '& .MuiInput-underline:hover:before': {
        borderBottomColor: '#A3A7AA', // Hover color
        borderBottomWidth: "1px"
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#A3A7AA', // Focused underline color
        borderBottomWidth: "1px"
    },
}

function SearchableDropdown({ additionalOptionStyling = {}, label, options, fetchOptions = null, onChange, value, error, optionValue, maxWidth, minWidth }) {
    const [inputValue, setInputValue] = useState(""); // For search input
    const [dropdownOptions, setDropdownOptions] = useState(options); // Dropdown list
    const [loading, setLoading] = useState(false); // API call loading state

    // Function to handle input changes and fetch new options
    const handleSearch = async (value) => {
        setInputValue(value);
        if (value.trim() === "") {
            setLoading(false);
            setDropdownOptions(options); // Reset to default options if the search is empty
            return;
        } else if (fetchOptions) {
            setLoading(true);
            try {
                const newOptions = await fetchOptions(value); // Fetch new options based on input
                // setDropdownOptions(newOptions);
            } catch (error) {
                console.error("Error fetching options:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const StyledPopper = styled(Popper)(({ theme }) => ({
        "& .MuiAutocomplete-paper": {
            border: "1px solid #ECF0F3",
            boxShadow: "0px 4px 12px 0px #0000001A", // Add shadow for depth
            borderRadius: "8px", // Rounded corners
            overflow: "hidden", // Prevent content overflow
            backgroundColor: "#FAFBFC", // Dropdown background color
            marginTop: '0.3rem',
            padding: '0.3rem'
        },
        "& .MuiAutocomplete-listbox": {
            maxHeight: "200px", // Limit dropdown height
            overflowY: "auto", // Enable scrolling for overflow
            padding: "0", // Remove extra padding
            borderRadius: "6px",
        },
        "& .MuiAutocomplete-option": {
            borderRadius: '2px',
            padding: "8px 10px", // Padding for options
            "&:hover": {
                backgroundColor: "#1b2d30 !important", // Background on hover
                color: 'white',
            },
            fontSize: '14px',
            fontWeight: 400,
            color: '#1D1E25',
            fontFamily: "Plus Jakarta Sans",
            "&[aria-selected='true']": {
                backgroundColor: "#e8f4fc", // Background for selected option
            },
            "&:first-of-type": additionalOptionStyling,
        },
    }));

    useEffect(() => {
        setDropdownOptions(options);
    }, [options])

    return (
        <div style={{ display: "flex", flexDirection: "column", flex: 1, minWidth: minWidth ? minWidth : '203px', maxWidth: maxWidth && maxWidth }}>
            <Autocomplete
                freeSolo
                options={dropdownOptions}
                getOptionLabel={(option) => option[optionValue] ? option[optionValue] : option.label || ""} // Adjust based on your options structure
                onInputChange={(event, value) => handleSearch(value)}
                value={value?.value ? value : null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="standard"
                        value={inputValue}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress size={20} /> : !value?.value ? <DropdownImg style={{ marginRight: "0.1rem" }} /> : <></>}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        sx={UNDERLINE_INPUT_COLOR}
                    />
                )}
                PopperComponent={StyledPopper}
                sx={{ flex: 1 }}
                onChange={(event, value) => onChange(value)}
            />
            {(error && !value.value) &&
                <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                    Field required
                </span>
            }
        </div>
    );
}

export default SearchableDropdown;
