import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../index.css"; // Custom CSS for styling
import { ReactComponent as CalenderImg } from '../assets/datePickerCalender.svg';

const CustomDatePicker = ({ label, minDate, maxDate, value, onChange, placeHolder , isFilterStyle=false}) => {
    return (
        <div className="date-picker-container" style={{width:"100%" }}>
            <label htmlFor="start-date" className="date-picker-label">
                {label}
            </label>
            <div className={`custom-date-picker ${isFilterStyle && 'custom-color'}`}>
                <DatePicker
                    id="start-date"
                    selected={value}
                    onChange={(date) => onChange(date)}
                    dateFormat="MM/dd/yy"
                    placeholderText={placeHolder}
                    popperPlacement="bottom"
                    minDate={minDate || new Date()}
                    maxDate={maxDate || new Date("2030-12-31")}
                    autoComplete="off"
                />
                <CalenderImg />
            </div>
        </div>
    );
};

export default CustomDatePicker;
