import * as React from 'react';
import Box from '@mui/material/Box';
import modalCross from '../../assests/modalCross.svg'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DELETE_GPT_ASSISTANT_FILE } from '../../services/constructionGpt.services';
import STRINGS from '../../helpers/staticContentHelper';

function DeleteGPTConfiguredFile({ setConstructionGptValues, setOpenDeleteModal, openDeleteModal, fileData, uploadedFileDetails, setUploadedFileDetails }) {

    const [isLoading, setIsLoading] = React.useState(false)

    const handleClose = (e) => {
        setOpenDeleteModal(false);
    }

    const deleteFile = async () => {
        setIsLoading(true)
        const reqPayload = {
            "assistant_id": fileData.assistant_id,
            "file_id": fileData.file_id
        }
        const res = await DELETE_GPT_ASSISTANT_FILE(reqPayload);
        const filteredData = uploadedFileDetails.filter(file => file.file_id !== fileData.file_id)
        setUploadedFileDetails(prev => filteredData)
        setConstructionGptValues(prev => {
            return { ...prev, files: filteredData }
        })
        setIsLoading(false)
        setOpenDeleteModal(false)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 420,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        px: 4,
        borderRadius: '0.5rem'
    };


    const buttonStyle = {
        border: "1px solid #E6E8EA",
        borderRadius: "10px",
        color: "black",
        textTransform: 'none',
        "&:hover": {
            border: "1px solid #E6E8EA",
            background: '#FF0000',
            opacity: '0.9',
            color: "white",
        },

    };
    const cancelButtonStyle = {
        border: "1px solid #E6E8EA",
        borderRadius: "10px",
        color: "black",
        textTransform: 'none',
        "&:hover": {
            border: "1px solid #E6E8EA",
        },

    };

    return (
        <Modal
            open={openDeleteModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            onClick={(e) => e.stopPropagation()}
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={modalCross} alt='crossIcon'
                        style={{
                            backgroundColor: "#1B2E3114",
                            padding: '0.5rem',
                            borderRadius: '1rem',
                            cursor: "pointer"
                        }} onClick={() => setOpenDeleteModal(false)} />
                </Box>
                <Typography id="modal-modal-description" sx={{
                    textAlign: 'center',
                    fontSize: '24px',
                    fontWeight: 400,
                    p: 1
                }}>
                    {STRINGS.ARE_YOU_SURE}
                </Typography>
                <Typography id="modal-modal-description" sx={{
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#6D6D6D',
                    pb: 1
                }}>
                    <span >{STRINGS.YOU_WANT_TO_DELETE}</span>
                    <span style={{ fontWeight: 600, whiteSpace: 'wrap' }}>{fileData?.file_name}.</span>
                    <span >{STRINGS.THIS_ACTION_CANNOT_BE_UNDONE}</span>

                </Typography>

                {isLoading ?
                    <CircularProgress color="success" sx={{ color: '#124429', margin: 'auto', display: 'flex', marginTop: '0.2rem' }} />
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                        <Button
                            type='submit'
                            variant="outlined"
                            size="large"
                            sx={{ ...cancelButtonStyle, backgroundColor: 'white', color: "black", width: "180px" }}
                            onClick={handleClose}
                        >
                            {STRINGS.CANCEL}
                        </Button>
                        <Button
                            type='submit'
                            variant="outlined"
                            size="large"
                            sx={{ ...buttonStyle, backgroundColor: '#FF2400', color: "white", width: "180px" }}
                            onClick={deleteFile}
                        >
                            {STRINGS.DELETE}
                        </Button>
                    </Box>

                }
            </Box>
        </Modal>
    )
}

export default DeleteGPTConfiguredFile
