import React, { useState, useRef, useEffect } from "react";
import TextField from '@mui/material/TextField';
import { ReactComponent as CrossImg } from '../assets/cross.svg';
import { UNDERLINE_INPUT_COLOR } from "./searchableDropDown";
import { ReactComponent as DropdownImg } from '../assets/dropdown.svg';
import { Checkbox } from '@mui/material';
import { ReactComponent as InviteMemberImg } from '../assets/inviteMember.svg';
import { useStoreInviteUserContext } from "../../../services/inviteUserContext";

const iconStyling = { marginRight: "0rem", position: 'absolute', right: "0px", bottom: '10px', cursor: 'pointer' }

function CustomDropdown({ fetchOptions, customButtonText, isCustomButtonEnabled = false, handleCustomButton, addInviteButton = false, positionTop = false, multipleValues = [], isMulti = false, error, dropdownOptions = [], addCheckbox = false, label, value = '', onValueChange, optionLabel, optionValue, placeholder }) {
    const { setInviteUserModalOpen } = useStoreInviteUserContext();
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState(""); // For search input
    const [loading, setIsLoading] = useState(false)

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleCloseFocus = () => {
        setIsFocused(false);
    }

    // trigger if user clicks outside
    const divRef = useRef(null);

    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            handleCloseFocus()
            // Add your logic here
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const getSubString = (value) => {
        if (value?.length > 33) {
            return value?.substring(0, 33) + '...'
        } else {
            return value?.substring(0, 33)
        }
    }


    const handleSearch = async (value) => {
        setInputValue(value);
        if (value.trim() === "") {
            return;
        } else if (fetchOptions) {
            fetchOptions(value)
        }
    };

    return (
        <div className="searchable-outlined-input-new" style={{ height: 'fit-content' }}>
            {isMulti && multipleValues.length > 2 &&
                <div style={{
                    backgroundColor: "#ECF0F3",
                    position: "absolute",
                    right: "-5px",
                    top: '25px',
                    borderRadius: '12px',
                    padding: "3px 10px",
                    fontSize: '10px',
                    color: '#1B2E31',
                    fontWeight: 500,
                    zIndex: 1
                }}>
                    +{multipleValues.length - 2}
                </div>
            }
            <TextField
                id="standard-basic"
                label={label}
                variant="standard"
                onFocus={handleFocus}
                focused
                placeholder={placeholder}
                fullWidth
                value={inputValue || getSubString(value) || ''}
                sx={{ ...UNDERLINE_INPUT_COLOR }}
                style={{ minWidth: '200px', flex: 1 }}
                inputProps={{
                    autoComplete: 'off', // Disables auto-suggestions
                }}
                onChange={(e) => {
                    if (!value) {
                        handleSearch(e.target.value)
                    }
                }}
            />
            {
                value && !isMulti ?
                    <CrossImg style={iconStyling} onClick={() => {
                        onValueChange('')
                        setInputValue('');
                    }} /> : multipleValues.length === 0 ?
                        <DropdownImg style={iconStyling} /> : <></>
            }
            {(error && !value) &&
                <span key='err' style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem", position: 'absolute', left: "0px", bottom: "-20px" }}>
                    *required
                </span>
            }
            {!loading && (dropdownOptions?.length > 0 || addInviteButton || isCustomButtonEnabled) && isFocused &&
                <div className={`${positionTop ? 'dropdown-outlined-list-new-top' : 'dropdown-outlined-list-new-bottom'}`} ref={divRef}>
                    {dropdownOptions.map((optionData, index) => {
                        if (addCheckbox) {
                            return (<SingleDropdownListWithCheckbox
                                multipleValues={multipleValues}
                                index={index}
                                optionData={optionData}
                                onChange={(e) => {
                                    onValueChange(e)
                                    setInputValue('');
                                }}
                                optionValue={optionValue}
                                optionLabel={optionLabel}
                            />)
                        }
                        return (<SingleDropdownList
                            index={index}
                            optionData={optionData}
                            onChange={(e) => {
                                onValueChange(e)
                                handleCloseFocus()
                                setInputValue('');
                            }}
                            optionValue={optionValue}
                            optionLabel={optionLabel}
                        />)
                    })}
                    {addInviteButton &&
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                backgroundColor: 'transparent',
                                borderRadius: '2px',
                                fontSize: '14px',
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 400,
                                borderRadius: '2px',
                                borderTop: '1px solid #ECF0F3',
                                position: 'sticky',
                                bottom: "0px",
                                left: '0px',
                                background: "white",
                                padding: '0.5rem ',
                                cursor: 'pointer'
                            }}
                        >
                            <div style={{ display: "flex", cursor: "pointer", alignItems: 'center', gap: '0.2rem', color: '#006D6B', fontWeight: 500, fontSize: '14px' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setInviteUserModalOpen(true)
                                    handleCloseFocus()
                                }}
                            >
                                <InviteMemberImg />
                                Invite member
                            </div>
                            <button className='button-primary-web'
                                style={{ padding: '0px 14px', width: '94px', height: '34px' }} onClick={() => handleCloseFocus()}>
                                Done
                            </button>
                        </div>}
                    {isCustomButtonEnabled &&
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                backgroundColor: 'transparent',
                                borderRadius: '2px',
                                fontSize: '14px',
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 400,
                                borderRadius: '2px',
                                borderTop: '1px solid #ECF0F3',
                                position: 'sticky',
                                bottom: "0px",
                                left: '0px',
                                background: "white",
                                padding: '0.9rem 0.7rem',
                                cursor: 'pointer'
                            }}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleCustomButton()
                            }}
                        >
                            <div style={{ display: "flex", cursor: "pointer", alignItems: 'center', gap: '0.2rem', color: '#006D6B', fontWeight: 500, fontSize: '14px' }}>
                                {customButtonText}
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default CustomDropdown;


export function SingleDropdownList({ optionData, onChange, optionValue, optionLabel, index }) {
    return (
        <div className="dropdown-outlined-list-option-new"
            key={index}
            onClick={() => onChange(optionData)}>
            {optionData[optionLabel] ? optionData[optionLabel] : optionData.label}
        </div>
    )
}

export function SingleDropdownListWithCheckbox({ optionData, onChange, optionValue, optionLabel, multipleValues }) {
    return (
        <div className="dropdown-outlined-list-option-new checkbox-background-style"
            key={optionData?.label}
            onClick={() => {
                onChange(optionData)
            }} >
            <div style={{ display: 'flex', justifyContent: "flex-start", alignItems: "center" }}>
                <Checkbox
                    sx={{
                        "& .MuiSvgIcon-root": {
                            width: "20px",
                            height: "20px",
                        },
                        color: '#A3A7AA',
                        '&.Mui-checked': {
                            color: '#1F5D5E',
                        },
                        padding: '0px',
                        marginRight: "6px"
                    }}
                    id="myCheckbox"
                    onChange={() => onChange(optionData)}
                    checked={multipleValues.includes(optionData[optionValue])}
                />
                {optionData[optionLabel] ? optionData[optionLabel] : optionData.label}
            </div>
            <span style={{ backgroundColor: '#F3F3F3', borderRadius: '12px', padding: "4px 10px", fontSize: '10px', fontWeight: 400, color: '#1B2E31' }}>
                {optionData.role}
            </span>
        </div>
    )
}