import moment from "moment";
export const jobInfo = {
    "job_number": '',
    "job_name": '',
    "job_address": '',
    "project_manager": '',
    "project_status": '',
    "start_date": '',
    "end_date": '',
}

export const jobMaterialInfo = {
    "material_id": "",
    "material_name": "",
    "subtype_id": null,
    "subtype_name": "",
    "material_quantity_available": null,
    "material_unit_cost": null,
    "material_certificate_id": null,
    "movement_type": "",
    "material_availability": "",
    "material_quantity": null,
    "job_material_id": null,
    "movement_id": null,
    "materials_acceptance_period": {
        "start_date": '',
        "end_date": '',
    },
    'subtype_options':[]
}

export const projectStatusValues = [
    { label: 'Live', value: 'LIVE' },
    { label: 'Upcoming', value: 'UPCOMING' },
    { label: 'Completed', value: 'COMPLETED' },
    { label: 'Cancelled', value: 'CANCELLED' },
    { label: 'On hold', value: 'ON_HOLD' }
];

export const movementTypeValues = [
    { label: 'Inbound', value: 'INBOUND' },
    { label: 'Outbound', value: 'OUTBOUND' },
    { label: 'Transfer', value: 'TRANSFER' },
    { label: 'Stockpile', value: 'STOCKPILE' },
];

export const materialAvailabilityValues = [
    { label: 'Deficit', value: 'DEFICIT' },
    { label: 'Excess', value: 'EXCESS' },
    { label: 'Balanced', value: 'BALANCED' }
];

export const certificateStatusValues = [
    { label: 'Pending', value: 'PENDING' },
    { label: 'Uploaded', value: 'UPLOADED' },
    { label: 'Verified', value: 'VERIFIED' },
    { label: 'Approved', value: 'APPROVED' }
];


export const materialDropDownListStatic =
{
    "materials": [
        {
            "id": 1,
            "material_id": "7ce31a4c-38ab-48b3-94b6-80094c24a8af",
            "created_at": "2024-12-31T09:38:53.213321+00:00",
            "deleted_at": null,
            "user_id": 6,
            "material_name": "Asphalt",
            "updated_at": "2024-12-31T11:01:44.330830+00:00"
        },
        {
            "id": 2,
            "material_id": "febc6ef1-834d-47d3-a28c-a6e63d0c5286",
            "created_at": "2024-12-31T09:39:03.672646+00:00",
            "deleted_at": null,
            "user_id": 6,
            "material_name": "wood dry",
            "updated_at": "2024-12-31T11:02:27.727534+00:00"
        }
    ],
    "subtypes": [
        {
            "created_at": "2024-12-31T09:38:53.253115+00:00",
            "user_id": 6,
            "id": 1,
            "subtype_id": "64e55d98-18c2-4b18-921d-8cb4b78db84f",
            "subtype_name": "Structural",
            "updated_at": "2024-12-31T09:38:53.253115+00:00",
            "deleted_at": null
        },
        {
            "created_at": "2024-12-31T09:39:03.707082+00:00",
            "user_id": 6,
            "id": 2,
            "subtype_id": "baab3195-cd36-460d-8e6c-c43fc3cb1428",
            "subtype_name": "lumbar",
            "updated_at": "2024-12-31T09:39:03.707082+00:00",
            "deleted_at": null
        }
    ]
}