import axios from "axios";
import { getApiEndpoints } from "../../../services/api.endpoints";
import { getBooleanFromLocalStorage } from "../../../services";

export const FETCH_LOCATION_DATA = async (key, restrict = false) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const apiUrl = API_END_POINT.GOOGLE_MAPS_AUTO_COMPLETE
    const data = {
        "input": key,
        "includedRegionCodes": ['US']
    }
    if (restrict) {
        data["locationRestriction"] = {
            "rectangle": {
                "low": {
                    "latitude": 32.5343,
                    "longitude": -124.4096
                },
                "high": {
                    "latitude": 42.0095,
                    "longitude": -114.1312
                }
            }
        }
    }
    try {
        const loacationResponse = await axios.post(apiUrl, data, {
            headers: {
                'Content-Type': 'application/json',
                'X-Goog-Api-Key': process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            }
        });
        // Handle the successful response
        if (loacationResponse?.data?.suggestions?.length > 0) {
            let formatedDataForLocations = loacationResponse.data.suggestions.map(data => {
                return { 'text': data.placePrediction.text.text, 'id': data.placePrediction.placeId }
            })
            return formatedDataForLocations
        } else {
            return []
        }
    } catch (error) {
        // Handle the error response
        console.error('Error posting data:', error);
    }
}

export const GET_LAT_LNG_FROM_ADDRESS = async (address) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const geocodeApiUrl = API_END_POINT.GOOGLE_MAPS_GET_GEOCODE;
    try {
        const response = await axios.get(geocodeApiUrl, {
            params: {
                address: address,  // The address you want to geocode
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            },
        });

        // Extract the latitude and longitude from the response
        const location = response?.data?.results[0]?.geometry?.location;
        return { ...location, address }

    } catch (error) {
        console.error('Error fetching place details:', error);
    }
};


export const GET_REQUEST_PAYLOAD = (cardData, apiRequestPayload, destinationLocation) => {
    let reqPayload = {
        "supplier_details": {
            "material_place_id": cardData?.supplierId || cardData?.materialPlaceId,
            "company_name": cardData?.companyName,
            "formatted_address": cardData?.formattedAddress,
            "materials_available": cardData?.materialsAvailable || [],
            "location": cardData?.location,
            "national_phone_number": cardData?.nationalPhoneNumber || '',
            'contact_person': cardData.Contact || '',
            'contact_email': cardData.Email || '',
            'open_hours': cardData.openHours,
            'sonar_verified': cardData.sonarVerified,
            "sales_phone_number": cardData["Phone (M)"] || '',
            "website": cardData["websiteUri"] || '',
        },
        "destination_details": {
            "formatted_address": apiRequestPayload?.destination_address,
            "location": {
                "latitude": destinationLocation?.lat,
                "longitude": destinationLocation?.lng
            }
        },
        "distance": {
            "time": 0.0,
            "miles": 0.0
        },
        "user_query": apiRequestPayload
    }

    return reqPayload
}


export const GET_REQUEST_PAYLOAD_FOR_LANDFILL = (cardData, apiRequestPayload, destinationLocation) => {
    let reqPayload = {
        "landfill_details": {
            "landfill_id": cardData?.landfillId,
            "landfill_name": cardData?.companyName,
            "formatted_address": cardData?.formattedAddress,
            "materials_accepted": cardData?.materialsAccepted || [],
            "location": cardData?.location,
            "national_phone_number": cardData?.nationalPhoneNumber || '',
            'contact_person': cardData.Contact || '',
            'contact_email': cardData.Email || '',
            'open_hours': cardData.openHours,
            'sonar_verified': cardData.sonarVerified,
            "sales_phone_number": cardData["Phone (M)"] || '',
            "website": cardData["websiteUri"] || '',
        },
        "destination_details": {
            "formatted_address": apiRequestPayload?.destination_address,
            "location": {
                "latitude": destinationLocation?.lat,
                "longitude": destinationLocation?.lng
            }
        },
        "distance": {
            "time": 0.0,
            "miles": 0.0
        },
        "user_query": apiRequestPayload
    }

    return reqPayload
}

export const FETCH_LOCATION_ROUTES = async (data) => {
    try {
        const directionsService = new window.google.maps.DirectionsService();
        const finalRes = await directionsService.route(
            {
                ...data,
                travelMode: window.google.maps.TravelMode.DRIVING,
                provideRouteAlternatives: true,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    return result
                } else {
                    console.error('Error fetching directions', status);
                }
            }
        );
        return finalRes;
    } catch (error) {
        console.log(error)
    }
}