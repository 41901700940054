import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as DeleteImg } from '../../../assests/delete.svg';
import UserDeleteModal from '../components/userDeleteModal';

function JobUsers({ usersData = [], userData, fetchUserList, jobId }) {
    const [isDeleteModalEnabled, setIsDeleteModalEnabled] = useState(false);
    const [delteUserData, setDeleteUserData] = useState({})
    const columns = [
        {
            id: 'first_name', label: 'NAME', align: 'start', width: "100%", format: (value, row) => {
                return <span style={{ fontSize: '12px', fontWeight: "500" , fontFamily: 'Plus Jakarta Sans'}}>{row.first_name + " " + row.last_name}</span>
            }
        },
        {
            id: 'email', label: 'EMAIL', align: 'start', minWidth: '250px', format: (value) => {
                return <span style={{ fontSize: '12px', fontWeight: "500" , fontFamily: 'Plus Jakarta Sans'}}>{value}</span>
            }
        },
        {
            id: 'role', label: 'ROLE', align: 'start', minWidth: '50px'
        },
        {
            id: 'actions', label: '', align: 'center', minWidth: '50px',
            format: (value, row) => {
                if (row.email === userData.email || (userData.role === 'manager' && row.role === 'admin') || ( jobId && row.role === 'admin')) {
                    return <></>
                }
                return <DeleteImg onClick={() => {
                    setDeleteUserData({ ...row, org_id: userData.org_id })
                    setIsDeleteModalEnabled(true)
                }} />
            }
        },
    ];

    return (
        <TableContainer>
            <UserDeleteModal fetchUserList={fetchUserList} open={isDeleteModalEnabled} jobId={jobId} setOpen={setIsDeleteModalEnabled} userData={delteUserData} />
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                    width: column.width,
                                    minWidth: column.minWidth,
                                    padding: "0.4rem 0.1rem",
                                    color: '#6D6D6D',
                                    fontFamily: 'Plus Jakarta Sans'
                                }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usersData.length > 0 && usersData.map((row) => {
                        return (
                            <TableRow hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.code}
                                style={{ cursor: "pointer", height: "53px"}}
                            >
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}
                                            sx={{
                                                position: 'relative', padding: '0.5rem 0.1px',
                                                paddingRight: '1rem',
                                                fontFamily: 'Plus Jakarta Sans'
                                            }}>
                                            {column.format
                                                ? column.format(value, row)
                                                : value}

                                        </TableCell>

                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default JobUsers