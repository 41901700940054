import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import STRINGS from '../../../helpers/staticContentHelper';
import BackImg from '../../../assests/back.svg';
import { decryptResponse } from '../../../utils/encryptionDecryption';
import { styled } from '@mui/material/styles';
import { GET_JOB_MEMBERS } from '../../../services/trackMaterialServices';
import Chip from '@mui/material/Chip';

const fontAndSize = {
    fontFamily: "Plus Jakarta Sans",
    color: 'rgb(27, 46, 49)',
    fontSize: '16px',
    margin: '0.1rem'
}

function InviteNewExistingUser({ jobs, jobId, setUserFormData, projectId, userData, setActiveTab, userFormData, error, setError, usersData }) {
    const [dropDownUsers, setDropdownUsers] = useState([])

    function getProjectName(data) {
        const filteredData = jobs.find(projectData => projectData?.job_info?.job_id === data)
        return filteredData.job_info.job_name
    }

    useEffect(() => {
        setUserFormData({
            user_emails: [],
            job_ids: []
        })
    }, [])

    const handleDelete = (chipToDelete, key) => () => {
        setUserFormData(prev => {
            const filteredData = prev[key].filter((chip) => chip !== chipToDelete)
            return { ...prev, [key]: filteredData }
        })
    };

    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
    }));

    const getListOfJobUsers = (orgId, jobId) => {
        const queryObj = {
            org_id: orgId,
            job_id: jobId,
            existing_job_users: false
        }
        GET_JOB_MEMBERS(queryObj).then(res => {
            try {
                const decryptedUsersData = JSON.parse(decryptResponse(res))
                setDropdownUsers(decryptedUsersData)
            } catch (error) {

            }
        })
    }

    useEffect(() => {
        if (userData.org_id && jobId) {
            getListOfJobUsers(userData.org_id, jobId)
        } else {
            setDropdownUsers(usersData)
        }
    }, [userData.org_id, jobId])

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <img onClick={() => {
                    setActiveTab('')
                }} src={BackImg} alt='not-found' width='20px' height='20px' style={{ cursor: "pointer" }} />
                <p style={{ lineHeight: "0px", ...fontAndSize, textAlign: 'center', fontSize: '20px', fontWeight: '500', flex: 1 }}>
                    {STRINGS.INVITE_USER_TO_TEAM}
                </p>
            </div>
            <div style={{ marginTop: '1rem' }}>
                <div className='flex-input-box' style={{ marginTop: '1rem' }}>
                    <Typography className='input-label' sx={{ ...fontAndSize }}>
                        {STRINGS.SELECT_JOB}
                    </Typography>
                    <Select
                        displayEmpty
                        onChange={(e => {
                            setUserFormData(prev => {
                                return { ...prev, job_ids: [...prev.job_ids, e.target.value] }
                            })
                            setError(prev => {
                                return { ...prev, job_ids: '' }
                            })
                        })}
                        sx={{ marginTop: "0px" }}
                        input={<OutlinedInput
                            fullWidth
                            required
                            error={error.isError && error.job_ids}
                            size='small'
                            className='outline-input-customized'
                        />}
                        renderValue={() => <em>{STRINGS.SELECT_JOB}</em>}
                        MenuProps={[]}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {jobs.map((job) => {
                            if (!userFormData?.job_ids?.includes(job?.job_info?.job_id)) {
                                return (
                                    <MenuItem
                                        key={job?.job_info?.job_name}
                                        value={job?.job_info?.job_id}
                                        sx={{ ...fontAndSize }}
                                    >
                                        {job?.job_info?.job_name}
                                    </MenuItem>
                                )
                            }
                        })}
                    </Select>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0,
                            m: 0,
                        }}
                        component="ul"
                    >
                        {userFormData?.job_ids?.map((data) => {
                            let icon;
                            return (
                                <ListItem key={data}>
                                    <Chip
                                        icon={icon}
                                        label={getProjectName(data)}
                                        onDelete={handleDelete(data, 'job_ids')}
                                    />
                                </ListItem>
                            );
                        })}
                    </Box>
                    {(error.isError && error.job_ids) &&
                        <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                            {error.job_ids}
                        </span>
                    }
                </div>
            </div>
            <div style={{ marginTop: '1rem' }}>
                <div className='flex-input-box' style={{ marginTop: '1rem' }}>
                    <Typography className='input-label' sx={{ ...fontAndSize }}>
                        {STRINGS.SELECT_USER}
                    </Typography>
                    <Select
                        displayEmpty
                        disabled={projectId && dropDownUsers.length === 0}
                        onChange={(e => {
                            setUserFormData(prev => {
                                return { ...prev, user_emails: [...prev.user_emails, e.target.value] }
                            })
                            setError(prev => {
                                return { ...prev, user_emails: '' }
                            })
                        })}
                        sx={{ marginTop: "0px" }}
                        input={<OutlinedInput
                            fullWidth
                            required
                            error={error.isError && error.user_emails}
                            size='small'
                            className='outline-input-customized'
                        />}
                        renderValue={() => <em>{STRINGS.SELECT_USER}</em>}
                        MenuProps={[]}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {dropDownUsers.map((user) => {
                            if (!userFormData.user_emails.includes(user.email)) {
                                return (
                                    <MenuItem
                                        onKeyDown={(e) => e.stopPropagation()}
                                        key={user.email}
                                        value={user.email}
                                        sx={{ ...fontAndSize }}
                                    >
                                        {user.email}
                                    </MenuItem>
                                )
                            }
                        })}
                    </Select>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0,
                            m: 0,
                        }}
                        component="ul"
                    >
                        {userFormData.user_emails.map((data) => {
                            let icon;
                            return (
                                <ListItem key={data}>
                                    <Chip
                                        icon={icon}
                                        label={data}
                                        onDelete={handleDelete(data, 'user_emails')}
                                    />
                                </ListItem>
                            );
                        })}
                    </Box>
                    {(error.isError && error.user_emails) &&
                        <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                            {error.user_emails}
                        </span>
                    }
                </div>
            </div>
            <p style={{ fontSize: '0.9rem', fontFamily: "Plus Jakarta Sans", }}>
                {STRINGS.CNNOT_FIND_YOUR_TEAM_MATE} <span style={{ fontFamily: "Plus Jakarta Sans", color: "#34b37e", fontWeight: 600, cursor: "pointer" }} onClick={() => {
                    setActiveTab('add-user')
                }}>{STRINGS.ADD_NEW_USER}</span>
            </p>
        </div>
    )
}

export default InviteNewExistingUser