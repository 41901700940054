export function VALIDATE_JOB_DATA(jobData) {

  const excludeFields = [
    'subtype_id',
    'deleted_at',
    'material_certificate_file_name',
    'material_certificate_id',
    'material_certificate_s3_key',
    'material_certificate_s3_url',
    'subtype_name',
    "job_material_id",
    "movement_id",
    "job_members",
    "subtype_info",
    "material_certificate",
    "subtype_options",
    "materials_acceptance_period",
    "material_availability",
    "material_quantity",
    "movement_type",
  ]
  let error = false; // Initialize error flag as false

  // Helper function to check if a value is empty
  const isEmpty = (key, value) => {
    if (excludeFields.includes(key)) return false
    return value === null ||
      value === undefined ||
      (typeof value === 'string' && value.trim() === '') ||
      (Array.isArray(value) && value.length === 0)
  };

  // Check values in job_info object
  for (const [key, value] of Object.entries(jobData.job_info)) {
    if (typeof value === 'object' && value !== null) {
      // For nested objects like job_location
      for (const nestedValue of Object.values(value)) {
        if (isEmpty(key, nestedValue)) {
          error = true;
          break;
        }
      }
    } else if (isEmpty(key, value)) {
      error = true;
      break;
    }
  }

  // Check values in job_materials array
  for (const material of jobData.job_materials) {
    for (const [key, value] of Object.entries(material)) {
      if (typeof value === 'object' && value !== null) {
        // For nested objects like materials_acceptance_period
        for (const nestedValue of Object.values(value)) {
          if (isEmpty(key, nestedValue)) {
            error = true;
            break;
          }
        }
      } else if (isEmpty(key, value)) {
        error = true;
        break;
      }
    }
    if (error) break; // Break early if an error is found
  }

  return error;
}

export const VALIDATE_MOVEMENT_DATA = (material) => {
  const movementKeys = [
    "materials_acceptance_period",
    "material_availability",
    "material_quantity",
    "movement_type",
  ];

  const isEmptyValue = (value) => {
    if (value === null || value === undefined || value === "") return true;

    if (typeof value === "object") {
      return Object.values(value).every((nestedValue) => 
        nestedValue === null || nestedValue === undefined || nestedValue === ""
      );
    }

    return false; 
  };

  let hasAnyValue = false;

  // Check if any movement key has a non-empty value
  for (const key of movementKeys) {
    const value = material[key];
    if (!isEmptyValue(value)) {
      hasAnyValue = true;
      break;
    }
  }

  // If any key has a value, ensure all movement-related keys are non-empty
  if (hasAnyValue) {
    for (const key of movementKeys) {
      const value = material[key];
      if (isEmptyValue(value)) {
        return false; 
      }
    }
  }

  return true; // Passes movement validation
};

