import React, { useState } from 'react'
import ScheduleImg from '../../assests/schedule.svg';
import ShareImg from '../../assests/share.svg';
import { useEffect } from 'react';
import Moment from 'moment';
import STRINGS from '../../helpers/staticContentHelper';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useAvailableConfiguredGPTs } from '../../services/availableConfiguredGpts';
import { LOAD_GPT_ASSISTANTS_THREADS } from '../../services/constructionGpt.services';
import { decryptResponse } from '../../utils/encryptionDecryption';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStoreConstructionGptContext } from '../../services/constuctionGptContext';

function HistoryOfConstructionGpt() {
    const { constructionGptValues, setConstructionGptValues } = useStoreConstructionGptContext();
    const navigate = useNavigate();
    const { availableConfiguredGPTs } = useAvailableConfiguredGPTs()
    const [threads, setThreads] = useState({
        'threads': [],
        'afterSearchThreads': []
    })
    const [isLoading, setIsLoading] = useState(false)
    // debounce request for search 
    const [query, setQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState(query);

    const formatDate = (threadDate) => {
        const formattedDate = Moment(threadDate).format('DD MMMM YYYY');
        return formattedDate
    }
    const truncateResult = (result = '') => {
        if (result.length > 200) {
            return result.slice(0, 200) + '...'
        }
        return result
    }

    useEffect(() => {
        console.log(availableConfiguredGPTs, 'availableConfiguredGPTs__ assistant_id')
        const handler = setTimeout(() => {
            setDebouncedQuery(query);
        }, 500); // 1000ms delay
        return () => {
            clearTimeout(handler); // Cleanup timeout if query changes
        };
    }, [query]);

    useEffect(() => {
        if (debouncedQuery) {
            const filteredValues = []
            threads['threads'].forEach((thread) => {
                if (thread.thread_name.toLowerCase().includes(debouncedQuery.toLowerCase())) {
                    filteredValues.push(thread)
                }
            })
            setThreads(prev => {
                return { ...prev, 'afterSearchThreads': filteredValues }
            })
            setIsLoading(prev => false)
        }
        //eslint-disable-next-line
    }, [debouncedQuery]);

    const getGptThreadHistory = async (assistantId) => {
        setIsLoading(prev => true)
        const res = await LOAD_GPT_ASSISTANTS_THREADS(assistantId);
        if (res) {
            const decryptedResponse = JSON.parse(decryptResponse(res));
            setThreads(prev => {
                return { ...prev, 'threads': decryptedResponse }
            })
            setConstructionGptValues(prev => {
                return { ...prev, history: { 'threads': decryptedResponse } }
            })
            setIsLoading(prev => false)
        }
    }

    useEffect(() => {
        if (availableConfiguredGPTs.length > 0) {
            if (constructionGptValues.history) {
                setThreads(constructionGptValues.history)
            } else {
                getGptThreadHistory(availableConfiguredGPTs[0].assistant_id)
            }
        }
    }, [availableConfiguredGPTs])

    return (
        <div className='project-file-result'>
            <div className='result-after-fetch-data'>
                <div className='answer-options' style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                </div>
                {isLoading &&
                    <>
                        <div className='answer-skelton-loader' style={{ marginTop: "2rem", justifyContent: 'space-around' }} >
                            <div className='skelton-line-1' key={'skelton-line-1'}></div>
                            <div className='skelton-line-2' key={'skelton-line-2'}></div>
                            <div className='skelton-line-3' key={'skelton-line-3'}></div>
                            <div className='skelton-line-4' key={'skelton-line-4'}></div>
                        </div>
                        <div className='answer-skelton-loader' style={{ marginTop: "2rem", justifyContent: 'space-around' }} >
                            <div className='skelton-line-1' key={'skelton-line-1'}></div>
                            <div className='skelton-line-2' key={'skelton-line-2'}></div>
                            <div className='skelton-line-3' key={'skelton-line-3'}></div>
                            <div className='skelton-line-4' key={'skelton-line-4'}></div>
                        </div>
                        <div className='answer-skelton-loader' style={{ marginTop: "2rem", justifyContent: 'space-around' }} >
                            <div className='skelton-line-1' key={'skelton-line-1'}></div>
                            <div className='skelton-line-2' key={'skelton-line-2'}></div>
                            <div className='skelton-line-3' key={'skelton-line-3'}></div>
                            <div className='skelton-line-4' key={'skelton-line-4'}></div>
                        </div>
                    </>

                }

                {threads[debouncedQuery ? 'afterSearchThreads' : 'threads']?.length > 0 ?
                    threads[debouncedQuery ? 'afterSearchThreads' : 'threads']?.map((thread, i) => {
                        return (
                            <div className='project-level-history-card' key={i} style={{ margin: '0px', marginTop: "1.5rem", cursor: 'pointer' }}
                                onClick={() => {
                                    navigate(`/construction-gpt-home?threadId=${thread.thread_id}`)
                                }}>
                                <p className='project-level-history-question' style={{ fontSize: '18px', lineHeight: '1.5rem' }}>{thread?.thread_name} </p>
                                <p className='project-level-history-answer' style={{ fontSize: '14px', lineHeight: '17.64px' }}>
                                    <Markdown remarkPlugins={[[remarkGfm]]} className='mark-up'>
                                        {truncateResult(thread?.last_message?.response) || 'no answer'}
                                    </Markdown>
                                </p>
                                <div className='answer-options'>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                        <img src={ScheduleImg} alt='not-found' />
                                        <span style={{ fontSize: '12px' }}>Updated on: {formatDate(thread?.updated_at)}</span>
                                        {thread?.total_questions > 1 && <span style={{ fontSize: '12px' }}>{`(${thread?.total_questions}+ search results)`}</span>}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                        <img src={ShareImg} alt='not-found' />
                                        <span>{STRINGS.SHARE}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    !isLoading ?
                        <div className='project-no-history'>{STRINGS.NO_HISTORY}</div> :
                        <></>
                }
            </div>
        </div>
    )
}

export default HistoryOfConstructionGpt;