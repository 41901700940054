import React, { useState } from 'react'
import UseImg from '../../assests/user.svg'
import UserProfileImg from '../../assests/userProfile.svg'
import LogOutImg from '../../assests/logout.svg'
import ShowProfileDetails from '../showProfileDetails';
import { LStorage } from '../../utils/localStorage.Helper';
import { USER_LOG_OUT } from '../../services';
import { usePostHog } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';
import { decryptResponse } from '../../utils/encryptionDecryption';
import { checkUserPermission } from '../../utils/encryptionDecryption';
const USER_OPTIONS = [
    {
        title: 'Profile',
        icon: UserProfileImg
    },
    {
        title: 'Logout',
        icon: LogOutImg
    },
]

function ProfileHeader() {
    const [isUserDropDownActive, setIsUserDropDownActive] = useState(false);
    const [openProfileModal, setOpenProfileModal] = useState(false);
    const userData = LStorage.getUserData();
    const posthog = usePostHog()
    const navigate = useNavigate()

    const logOutUser = () => {
        const reqData = {
            refresh_token: userData.refresh_token
        }

        USER_LOG_OUT(reqData).then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                posthog.reset()
                LStorage.removeUserData()
                navigate('/')
                if (parsedDecryptedData?.url) {
                    window.open(parsedDecryptedData.url, "_blank");
                }
            }
        })
    }

    return (
        <div className='projects-header' style={{ position: "absolute", right: "12px", top: "12px", padding: "0px" , cursor:"pointer",zIndex:999}}>
            <ShowProfileDetails openFolderModal={openProfileModal} setOpenFolderModal={setOpenProfileModal} />
            <div className='logo-with-user-name'>
                <div className='nav-bar-icons' style={{border:'1px solid #ecf0f3'}}
                    onClick={() => setIsUserDropDownActive(prev => !prev)}>
                    <img src={UseImg} alt='userImg' />
                    {USER_OPTIONS.length > 0 && isUserDropDownActive &&
                        <div className='drop-down-options-list'>
                            {USER_OPTIONS.map((option, index) => {
                                if (option.requiredPermission && !checkUserPermission("INVITE", userData)) {
                                    return <></>
                                }
                                return (
                                    <div className='drop-down-option'
                                        key={index}
                                        onClick={() => {
                                            if (option.title === 'Profile') {
                                                setOpenProfileModal(true)
                                            }
                                            if (option.title === 'Logout') {
                                                logOutUser()
                                            }
                                        }}>
                                        {option.icon &&
                                            <img src={option.icon} alt='not-found' />
                                        }
                                        {option.title}
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProfileHeader