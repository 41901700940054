import React, { useState } from 'react';
import ConstructionGptSideBar from './constructionGptSidebar';
import ConstructionGptsearchBar from './constructionGptsearchBar';
import ProfileHeader from '../../components/profileHeader/profileHeader';
function ConstructionLayout({ children }) {
    const [isTogglerOn, setIsTogglerOn] = useState(true);
    const [isModalOpen , setIsModalOpen] = useState(false);
    return (
        <div className='projects-details-main'>
            <ConstructionGptsearchBar  isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
            {/* left nav bar  */}
            <ConstructionGptSideBar setIsModalOpen={setIsModalOpen} isTogglerOn={isTogglerOn} setIsTogglerOn={setIsTogglerOn} />
            {/* material search chils compoenet */}
            <div className='project-files-details' style={{ padding: "0px", maxWidth: 'calc(100% - 240px)', minWidth: isTogglerOn ? 'calc(100% - 250px)' : 'calc(100% - 80px)', position:"relative" }}>
            <ProfileHeader />
                {children}
            </div>
        </div>
    )
}

export default ConstructionLayout;