import React, { useState } from 'react';
import { ReactComponent as MaterialSearchActiveImg } from '../../materialSearch/assets/filter.svg';
import { ReactComponent as LandfillFilledImg } from '../assets/map.svg';

export function ViewOptions({selectedOption, setSelectedOption}) {
  return (
    <div className='personal-and-company-option-button'>
      <div className={`${selectedOption === 'List view' && 'new-option-for-landfil-material-active'} new-option-for-landfil-material`}
        onClick={() => setSelectedOption('List view')}>
        {selectedOption === 'List view' ?
          <MaterialSearchActiveImg className='green-filled' /> :
          <MaterialSearchActiveImg />
        }
        List view
      </div>
      <div className={`${selectedOption === 'Map view' && 'new-option-for-landfil-material-active'} new-option-for-landfil-material`}
        onClick={() => setSelectedOption('Map view')}>
        {selectedOption === 'Map view' ?
          <LandfillFilledImg className='green-filled' /> :
          <LandfillFilledImg />
        }
        Map view
      </div>
    </div>
  )
}
