import { createContext, useContext, useState } from 'react';

const StoreInviteUserContext = createContext();

export const StoreInviteUserContextProvider = ({ children }) => {
    const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);

    return (
        <StoreInviteUserContext.Provider value={{ inviteUserModalOpen, setInviteUserModalOpen }}>
            {children}
        </StoreInviteUserContext.Provider>
    );
};

export const useStoreInviteUserContext = () => useContext(StoreInviteUserContext);
