import React, { useState, useEffect } from 'react';
import { ReactComponent as BackImg } from './assets/back.svg';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactComponent as AddImg } from '../../assests/add.svg';
import { ReactComponent as UploadFilesImg } from '../../assests/uploadFiles.svg';
import { ReactComponent as DeleteImg } from './assets/delete.svg';
import { ReactComponent as DropdownArrowImg } from './assets/dropdownArrow.svg';
import { InputAdornment } from "@mui/material";
import StyledDatePicker from './components/datePicker';
import { UNDERLINE_INPUT_COLOR } from './components/searchableDropDown';
import { convertToCamelCase } from '../../utils/encryptionDecryption';
import moment from 'moment';
import {
  jobInfo,
  jobMaterialInfo,
  projectStatusValues,
  materialAvailabilityValues,
} from './addNewJobPayload';
import UploadConfigurationFile from './components/uploadCertification';
import { FETCH_LOCATION_DATA } from '../materialSearch/googleSerive';
import { LStorage } from '../../utils/localStorage.Helper';
import { ADD_JOB, UPDATE_JOB } from '../../services/trackMaterialServices';
import { GET_MATERIAL_LIST } from '../../services/trackMaterialServices';
import { VALIDATE_JOB_DATA, VALIDATE_MOVEMENT_DATA } from './validateJobForm';
import AddMaterial from './components/addNewMaterial';
import { decryptResponse } from '../../utils/encryptionDecryption';
import JobDeleteModal from './components/jobDeleteModal'
import { GET_ADMIN_AND_MANAGERS } from '../../services/trackMaterialServices';
import AddMaterialSubType from './components/addMaterialSubType';
import { GET_MATERIAL_SUB_TYPES_LIST } from '../../services/trackMaterialServices';
import { useNavigate } from "react-router-dom";
import SearchableLocationDropdown from './components/searchableLocationDrodown';
import { toast } from 'react-toastify';
import STRINGS from '../../helpers/staticContentHelper';
import SearchableEmailDropdown from './components/emailDropdown';
import { GET_JOB_MEMBERS } from '../../services/trackMaterialServices';
import { LIST_ORG_MEMBERS } from '../../services';
import { useSearchParams } from "react-router-dom";
import CustomDropdown from './components/customDropdown';

const FLOAT_NUMBERS = ['material_unit_cost', 'material_quantity_available', 'material_quantity'];

export const formatNumberWithCommas = (value) => {
  if (!value) return '';
  // Remove any existing commas first
  const cleanValue = value.toString().replace(/,/g, '');
  // Split number into whole and decimal parts
  const [wholePart, decimalPart] = cleanValue.split('.');
  // Format whole part with commas
  const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // Return formatted number
  return decimalPart !== undefined
    ? `${formattedWholePart}.${decimalPart}`
    : formattedWholePart;
};

function CreateAndEditJob({ setIsAddJobEnabled, editJobData, getJObsList }) {
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
  const navigate = useNavigate();
  const [loadComponent, setComponentLoad] = useState(false)
  const userData = LStorage.getUserData()
  const [hiddenCards, setHiddenCards] = useState([]);
  const [jobData, setJobData] = useState({
    job_info: jobInfo,
    job_materials: [],
    job_members: []
  });
  const [error, setError] = useState(false)
  const [uploadFile, setUploadFile] = useState(false)
  const [locationsData, setLocationsData] = useState([]);
  const [movementValidationData, setMovementValidationData] = useState([]);
  const [materialDropDownList, setMaterialDropDownList] = useState([]);
  const [isAddMaterialOpen, setIsAddMaterialOpen] = useState(false)
  const [isAddSubTypeModalOpen, setIsAddSubTypeModalOpen] = useState(false)
  const [orgUsersList, setOrgUsers] = useState([])
  const [usersList, setUsersData] = useState([])
  const [deleteJobData, setDeleteJobData] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUploadFile, setSelectedUploadFile] = useState(null)
  const [indexValueOfAddMaterial, setIndexValueOfAddMaterial] = useState(0);

  useEffect(() => {
    if (editJobData) {
      setJobData({ ...editJobData, job_members: [] })
    }
    setTimeout(() => {
      setComponentLoad(true)
    }, 100);
  }, [editJobData])

  const changeDate = (index, key, keyTwo) => {
    let jobMaterials = JSON.parse(JSON.stringify(jobData));
    jobMaterials['job_materials'][index].materials_acceptance_period[key] = '';
    if (keyTwo) {
      jobMaterials['job_materials'][index].materials_acceptance_period[keyTwo] = '';
    }
    setJobData(prev => jobMaterials)
  }

  const handleCreateEditJob = async () => {
    let movementValidationData = []
    jobData.job_materials.forEach((material, index) => {
      const isValidMovementData = VALIDATE_MOVEMENT_DATA(material)
      if (!isValidMovementData) movementValidationData.push(index)
      for (let key in material) {
        if (FLOAT_NUMBERS.includes(key) && material[key]) {
          material[key] = parseFloat(material[key])
        }
      }
      return material
    })
    const validate = VALIDATE_JOB_DATA(jobData)
    setMovementValidationData(movementValidationData)
    setError(validate || movementValidationData.length > 0)
    if (validate || movementValidationData.length > 0) return
    setIsLoading(true)

    if (jobData.job_info.job_id) {
      const res = await UPDATE_JOB(jobData);
      try {
        if (res?.response?.data?.detail) {
          const parsedValue = JSON.parse(res.response.data.detail.replace(/^500:\s*/, ""));
          if (parsedValue.err_code === 'SNR_2326') {
            setIsAddJobEnabled(false)
            return
          }
          if (parsedValue.invalid_end_date && parsedValue.invalid_start_date) {
            toast.error('Invalid start and end date')
            changeDate(parsedValue.index, 'start_date', 'end_date')
          } else if (parsedValue.invalid_start_date) {
            changeDate(parsedValue.index, 'start_date')
            toast.error('Invalid start date')
          } else if (parsedValue.invalid_end_date) {
            changeDate(parsedValue.index, 'end_date')
            toast.error('Invalid end date')
          }
        } else {
          const parsedRes = JSON.parse(decryptResponse(res));
          if (parsedRes.job_info) {
            getJObsList()
            navigate(`/track-material`)
            setIsAddJobEnabled(false)
          }
        }
      } catch (error) {
        toast.error("Something went wrong updating job")
      } finally {
        setIsLoading(false)
      }
    } else {
      const res = await ADD_JOB(jobData);
      try {
        if (res?.response?.data?.detail) {
          const parsedValue = JSON.parse(res.response.data.detail.replace(/^500:\s*/, ""));
          if (parsedValue.invalid_end_date && parsedValue.invalid_start_date) {
            toast.error('Invalid start and end date')
            changeDate(parsedValue.index, 'start_date', 'end_date')
            setError(true)
          } else if (parsedValue.invalid_start_date) {
            changeDate(parsedValue.index, 'start_date')
            toast.error('Invalid start date')
          } else if (parsedValue.invalid_end_date) {
            changeDate(parsedValue.index, 'end_date')
            toast.error('Invalid end date')
          }
        } else {
          const parsedRes = JSON.parse(decryptResponse(res));
          if (parsedRes.job_info) {
            getJObsList()
            navigate(`/track-material`)
            setIsAddJobEnabled(false)
          }
        }
      } catch (error) {
        toast.error("Something went wrong while creating job")
      } finally {
        setIsLoading(false)
      }
    }
  }

  const fetchLocationData = async (key) => {
    try {
      const res = await FETCH_LOCATION_DATA(key)
      setLocationsData(res)
    } catch (error) {
      console.log(error)
    }
  }

  const getMaterialList = async () => {
    try {
      const res = await GET_MATERIAL_LIST()
      const decryptedResponse = JSON.parse(decryptResponse(res))
      setMaterialDropDownList(decryptedResponse)
    } catch (error) {
      console.log(error)
    }
  }

  const getListOfOrgUsers = () => {
    GET_ADMIN_AND_MANAGERS(userData.org_id).then(res => {
      if (res) {
        const decryptedUsersData = JSON.parse(decryptResponse(res))
        setOrgUsers(decryptedUsersData)
      }
    })
  }

  useEffect(() => {
    getMaterialList()
    getListOfOrgUsers()
  }, [])

  const handleLocalDelete = (materialIndex) => {
    let copyData = JSON.parse(JSON.stringify(jobData?.job_materials))
    const newArray = copyData.filter((_, index) => index !== materialIndex);
    setJobData(prev => {
      return { ...prev, job_materials: newArray }
    })
  }

  const getMaterialSubTypeList = async (material_id, index, jobMaterials) => {
    setJobData(prev => jobMaterials)
    const res = await GET_MATERIAL_SUB_TYPES_LIST(material_id)
    const decryptedResponse = JSON.parse(decryptResponse(res))
    setJobData(prev => {
      let jobMaterials = JSON.parse(JSON.stringify(prev));
      jobMaterials['job_materials'][index].subtype_options = decryptedResponse;
      return jobMaterials
    })
  }

  // get list for dropdown selection to add user to org or job
  const getListOfOrgUsersForDropdown = (orgId) => {
    LIST_ORG_MEMBERS(orgId).then(res => {
      if (res) {
        const decryptedUsersData = JSON.parse(decryptResponse(res))
        if (decryptedUsersData.length > 0) {
          setUsersData(decryptedUsersData)
        }
      }
    })
  }

  const getListOfJobUsers = (orgId, jobId) => {
    const queryObj = {
      org_id: orgId,
      job_id: jobId,
      existing_job_users: true
    }
    GET_JOB_MEMBERS(queryObj).then(res => {
      try {
        const decryptedUsersData = JSON.parse(decryptResponse(res))
        if (decryptedUsersData.length > 0) {
          const userEmails = decryptedUsersData.map(userData => userData.email)
          setJobData(prev => {
            return { ...prev, job_members: userEmails }
          })
        }
      } catch (error) {

      }
    })
  }

  useEffect(() => {
    getListOfOrgUsersForDropdown(userData.org_id)
    if (userData.org_id && jobId) {
      getListOfJobUsers(userData.org_id, jobId)
    }
  }, [userData.org_id, jobId])


  const getValueToRender = (listOfUsers) => {
    return listOfUsers.join(", ")
  }
  return (
    <>
      <AddMaterialSubType
        setNewSubTypeList={(data, materialInfo) => {
          jobMaterials['job_materials'][indexValueOfAddMaterial].subtype_options = data;
          let jobMaterials = JSON.parse(JSON.stringify(jobData));
          jobMaterials['job_materials'][indexValueOfAddMaterial].material_name = materialInfo.material_name;
          jobMaterials['job_materials'][indexValueOfAddMaterial].material_id = materialInfo?.material_id;
          jobMaterials['job_materials'][indexValueOfAddMaterial].subtype_name = data[data.length - 1]?.subtype_name;
          jobMaterials['job_materials'][indexValueOfAddMaterial].subtype_id = data[data.length - 1]?.subtype_id;
          setJobData(prev => jobMaterials)
        }}
        open={isAddSubTypeModalOpen}
        setOpen={setIsAddSubTypeModalOpen}
      />
      <JobDeleteModal handleLocalDelete={handleLocalDelete} open={deleteJobData} setOpen={() => setDeleteJobData(null)} jobData={deleteJobData} />
      <AddMaterial
        setOpen={setIsAddMaterialOpen}
        open={isAddMaterialOpen}
        setNewMaterialList={(data) => {
          setMaterialDropDownList(data);
          let jobMaterials = JSON.parse(JSON.stringify(jobData));
          jobMaterials['job_materials'][indexValueOfAddMaterial].material_name = data[data.length - 1]?.material_name;
          jobMaterials['job_materials'][indexValueOfAddMaterial].material_id = data[data.length - 1]?.material_id;
          setJobData(prev => jobMaterials)
        }} />
      <UploadConfigurationFile jobData={jobData} setJobData={setJobData} selectedUploadFile={selectedUploadFile} open={uploadFile} setOpen={setUploadFile} />
      {/* header component */}
      <div className='material-tracker-header' style={{ padding: "1rem 0px", position: 'sticky', top: '0px', backgroundColor: "white", zIndex: 1 }}>
        <div className='material-tracker-back-img'>
          <BackImg onClick={() => {
            navigate(`/track-material`)
            setIsAddJobEnabled(false)
          }} style={{ cursor: 'pointer' }} />
          {editJobData ? 'Edit job details' : 'New job details'}
        </div>

        <div style={{ display: "flex", gap: '24px' }}>
          <button className='button-secondary-web' style={{ width: '120px' }} onClick={() => {
            navigate(`/track-material`)
            setIsAddJobEnabled(false)
          }}>
            Cancel
          </button>
          <button className='button-primary-web' style={{ width: '120px' }} onClick={() => {
            if (!isLoading) {
              handleCreateEditJob()
            }
          }}>
            {isLoading ? "Saving..." : 'Save'}
          </button>
        </div>
      </div>
      {/* header component end */}

      <div className='material-tracker-create-new'>
        {/* {!hiddenCards.includes(600) && */}
        <>
          {/* general details form component */}
          <div className='material-tracker-create-new-general-details'>
            <h6 className='material-tracker-header-card-header' style={{ marginBottom: "1rem" }}>General details</h6>
            {loadComponent &&
              <>
                <div className='material-tracker-general-details-form'>
                  <div style={{ display: "flex", flexDirection: "column", flex: 1, position: "relative", height: "fit-content" }}>
                    <TextField
                      sx={UNDERLINE_INPUT_COLOR}
                      id="standard-basic"
                      label="Job number"
                      variant="standard"
                      focused
                      placeholder='Enter job number'
                      style={{ minWidth: '243px', flex: 1 }}
                      value={jobData?.job_info?.job_number}
                      onChange={(e) => {
                        let jobMaterials = JSON.parse(JSON.stringify(jobData));
                        jobMaterials['job_info'].job_number = e.target.value || '';
                        setJobData(prev => jobMaterials)
                      }}
                      inputProps={{
                        autoComplete: 'off', // Disables auto-suggestions
                      }}
                    />
                    {(error && !jobData?.job_info?.job_number) &&
                      <ErrorMsg />
                    }
                  </div>

                  <div style={{ display: "flex", flexDirection: "column", flex: 1, position: 'relative', height: "fit-content" }}>
                    <TextField
                      sx={UNDERLINE_INPUT_COLOR}
                      id="standard-basic"
                      label="Job name"
                      variant="standard"
                      focused
                      placeholder='Enter job name'
                      style={{ minWidth: '243px', flex: 1 }}
                      value={jobData?.job_info?.job_name}
                      onChange={(e) => {
                        let jobMaterials = JSON.parse(JSON.stringify(jobData));
                        jobMaterials['job_info'].job_name = e.target.value || '';
                        setJobData(prev => jobMaterials)
                      }}
                      inputProps={{
                        autoComplete: 'off', // Disables auto-suggestions
                      }}
                    />
                    {(error && !jobData?.job_info?.job_name) &&
                      <ErrorMsg />
                    }
                  </div>
                  <CustomDropdown
                    label='Location'
                    dropdownOptions={[...locationsData]}
                    optionValue='text'
                    optionLabel='text'
                    onValueChange={(value) => {
                      setJobData(prev => {
                        return { ...prev, job_info: { ...prev.job_info, ['job_address']: value?.text || '' } }
                      })
                    }}
                    fetchOptions={(value => {
                      fetchLocationData(value)
                    })}
                    placeholder='Enter location'
                    value={jobData?.job_info?.job_address}
                  />
                  <CustomDropdown
                    error={error}
                    optionValue='email'
                    dropdownOptions={[...orgUsersList]}
                    label='Project manager'
                    placeholder='Select project manager'
                    onValueChange={(value) => {
                      setJobData(prev => {
                        return { ...prev, job_info: { ...prev.job_info, ['project_manager']: value?.email || '' } }
                      })
                    }}
                    value={jobData?.job_info?.project_manager}
                    optionLabel='email'
                  />
                </div>
                <div className='material-tracker-general-details-form' style={{ marginTop: '2.5rem' }}>
                  <CustomDropdown
                    error={error}
                    dropdownOptions={projectStatusValues}
                    label='Project status'
                    placeholder='Enter project status'
                    onValueChange={(value) => {
                      setJobData(prev => {
                        return { ...prev, job_info: { ...prev.job_info, ['project_status']: value?.value || '' } }
                      })
                    }}
                    value={convertToCamelCase(jobData?.job_info?.project_status)?.replace(/_/g, " ")}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ display: "flex", flexDirection: "column", flex: 1, position: "relative" }}>
                      <StyledDatePicker
                        placeHolder='Enter start date'
                        label='Start date'
                        value={jobData?.job_info?.start_date ?
                          moment(jobData?.job_info?.start_date, "DD-MM-YYYY").toDate()
                          : ''
                        }
                        onChange={(date) => {
                          let jobMaterials = JSON.parse(JSON.stringify(jobData));
                          jobMaterials.job_info.start_date = moment(date).format("DD-MM-YYYY");
                          setJobData(prev => jobMaterials)
                        }}
                      />
                      {(error && !jobData?.job_info.start_date) &&
                        <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem", position: 'absolute', left: "0px", bottom: "-20px" }}>
                          {STRINGS.REQUIRED_FIELD_ERROR_MSG}
                        </span>
                      }
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", flex: 1, position: "relative" }}>
                      <StyledDatePicker
                        placeHolder='Enter end date'
                        label='End date'
                        value={
                          jobData?.job_info?.end_date ?
                            moment(jobData?.job_info?.end_date, "DD-MM-YYYY").toDate()
                            : ''
                        }
                        onChange={(date) => {
                          let jobMaterials = JSON.parse(JSON.stringify(jobData));
                          jobMaterials.job_info.end_date = moment(date).format("DD-MM-YYYY");
                          setJobData(prev => jobMaterials)
                        }}
                        minDate={moment(jobData?.job_info?.start_date, "DD-MM-YYYY").toDate()}
                      />
                      {(error && !jobData?.job_info?.end_date) &&
                        <ErrorMsg />
                      }
                    </div>
                  </LocalizationProvider>
                  <CustomDropdown
                    addInviteButton={true}
                    addCheckbox={true}
                    isMulti={true}
                    dropdownOptions={usersList}
                    label='Team members'
                    placeholder='Select team members'
                    onValueChange={(value) => {
                      if (value.email) {
                        if (jobData.job_members.includes(value.email)) {
                          const copyData = JSON.parse(JSON.stringify(jobData));
                          const filteredJobUsers = copyData.job_members.filter(userData => userData !== value.email);
                          copyData.job_members = filteredJobUsers
                          setJobData(copyData)
                        } else {
                          setJobData(prev => {
                            return { ...prev, job_members: [...prev.job_members, value['email']] }
                          })
                        }
                      }
                    }}
                    value={getValueToRender(jobData.job_members || [])}
                    multipleValues={jobData.job_members || []}
                    optionValue='email'
                    optionLabel='email'
                  />
                </div>
              </>
            }
            <div className='material-tracker-form-divider' />
          </div>
          {/* general details form component close */}

          {/* header component */}
          <div className='material-tracker-header'>
            <h6 className='material-tracker-header-card-header'>Material details</h6>

            <button className='button-secondary-web' style={{ width: '160px' }} onClick={() => {
              let jobMaterials = JSON.parse(JSON.stringify(jobData));
              jobMaterials.job_materials.push({ ...jobMaterialInfo })
              setJobData(prev => jobMaterials)
            }}>
              <AddImg style={{ marginRight: '0.5rem' }} />
              Add materials
            </button>
          </div>

          {jobData?.job_materials?.map((materialData, index) => {
            return (
              <div className='material-tracker-create-new-general-details' style={{ backgroundColor: '#FAFBFC', border: '1px solid #ECF0F3', padding: '20px', borderRadius: '12px', marginTop: '2rem' }}>
                <div className='material-tracker-header' style={{ marginBottom: "1rem" }}>
                  <h6 className='material-tracker-header-card-header'>{index + 1}. Material </h6>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <div className='material-tracker-delete-button' onClick={() => {
                      setDeleteJobData({ ...materialData, index });
                    }}>
                      Delete
                      <DeleteImg />
                    </div>
                    <DropdownArrowImg
                      style={{ cursor: 'pointer', transform: hiddenCards.includes(index) && 'rotate(180deg)' }} onClick={() => {
                        if (hiddenCards.includes(index)) {
                          const filteredActiveCards = hiddenCards.filter(item => item !== index)
                          setHiddenCards(prev => filteredActiveCards)
                        } else {
                          setHiddenCards(prev => [...prev, index])
                        }
                      }} />
                  </div>
                </div>

                <div className={`${hiddenCards.includes(index) && 'material-tracker-general-details-form-hide'}`}>
                  <div className='material-tracker-general-details-form'>
                    <CustomDropdown
                      customButtonText='Add new material'
                      isCustomButtonEnabled={true}
                      handleCustomButton={() => {
                        setIsAddMaterialOpen(true);
                        setIndexValueOfAddMaterial(index)
                      }}
                      error={error}
                      optionValue='material_name'
                      dropdownOptions={materialDropDownList}
                      label='Material'
                      placeholder='Select material'
                      onValueChange={(value) => {
                        let jobMaterials = JSON.parse(JSON.stringify(jobData));
                        jobMaterials['job_materials'][index].material_name = value?.material_name || '';
                        jobMaterials['job_materials'][index].material_id = value?.material_id || '';
                        jobMaterials['job_materials'][index].subtype_options = [];
                        if (value?.material_id) {
                          getMaterialSubTypeList(value?.material_id, index, jobMaterials)
                        } else {
                          setJobData(prev => jobMaterials)
                        }
                      }}
                      value={materialData?.material_name}
                      optionLabel='material_name'
                    />
                    <CustomDropdown
                      customButtonText='Add new subtype'
                      isCustomButtonEnabled={true}
                      handleCustomButton={() => {
                        setIndexValueOfAddMaterial(index)
                        setIsAddSubTypeModalOpen(true);
                      }}
                      // error={error}
                      optionValue='subtype_name'
                      dropdownOptions={[...materialData.subtype_options]}
                      label='Select material subtype'
                      placeholder='Select material subtype'
                      onValueChange={(value) => {
                        let jobMaterials = JSON.parse(JSON.stringify(jobData));
                        jobMaterials['job_materials'][index].subtype_name = value?.subtype_name || '';
                        jobMaterials['job_materials'][index].subtype_id = value?.subtype_id || '';
                        setJobData(prev => jobMaterials)
                      }}
                      value={materialData?.subtype_name}
                      optionLabel='subtype_name'
                    />
                    <div style={{ display: "flex", flexDirection: "column", position: "relative", flex: 1 }}>
                      <TextField
                        focused
                        sx={UNDERLINE_INPUT_COLOR}
                        id="standard-basic"
                        placeholder='Enter material quantity available on site'
                        label={`Material quantity available on site 
                            ${materialData?.material_name?.toLowerCase() === 'asphalt' ?
                            "( tons )" :
                            '( C.Y )'}`} variant="standard"
                        // style={{ width: '350px', flex: 1 }}
                        value={formatNumberWithCommas(materialData?.material_quantity_available)}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          const cleanValue = inputValue.replace(/,/g, '');
                          const regex = /^\d*\.?\d*$/;
                          if (inputValue === "" || regex.test(cleanValue)) {
                            let jobMaterials = JSON.parse(JSON.stringify(jobData));
                            jobMaterials['job_materials'][index].material_quantity_available = cleanValue;
                            setJobData(prev => jobMaterials);
                          }
                        }}
                        type='text'
                        inputProps={{
                          step: 0.5,
                          autoComplete: 'off',
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {materialData?.material_name?.toLowerCase() === 'asphalt' ?
                                "tons" :
                                'C.Y.'
                              }
                            </InputAdornment>
                          ),
                        }} />
                      {(error && !materialData?.material_quantity_available) &&
                        <ErrorMsg />
                      }
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", position: "relative", flex: 1 }}>
                      <TextField
                        focused
                        placeholder='Enter material unit cost '
                        sx={UNDERLINE_INPUT_COLOR}
                        id="standard-basic"
                        label={`Material unit cost 
                             ${materialData?.material_name?.toLowerCase() === 'asphalt' ?
                            "( tons )" :
                            '( C.Y )'}`}
                        variant="standard"
                        type='text'
                        inputProps={{
                          step: 0.5,
                          autoComplete: 'off',
                        }}
                        // style={{ width: '300px', flex: 1 }}
                        value={formatNumberWithCommas(materialData?.material_unit_cost)}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          // Remove commas for validation
                          const cleanValue = inputValue.replace(/,/g, '');
                          // Basic number format validation (allows numbers and one decimal point)
                          const regex = /^\d*\.?\d*$/;
                          if (inputValue === "" || regex.test(cleanValue)) {
                            let jobMaterials = JSON.parse(JSON.stringify(jobData));
                            jobMaterials['job_materials'][index].material_unit_cost = cleanValue;
                            setJobData(prev => jobMaterials)
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              $
                            </InputAdornment>
                          ),
                        }} />
                      {(error && !materialData?.material_unit_cost) &&
                        <ErrorMsg />
                      }
                    </div>
                  </div>

                  <div className='material-tracker-header' style={{ margin: '2.5rem 0px' }}>
                    <div className='material-tracker-title-with-sub-title'>
                      <h6> Upload test certificate for the material</h6>
                      <p>Once uploaded the certification will be verified by sonar </p>
                    </div>
                    <button className='button-secondary-web' style={{ height: '44px' }}
                      onClick={() => {
                        setUploadFile(true);
                        setSelectedUploadFile(index)
                      }}>
                      {!materialData.material_certificate_id ? <>
                        <UploadFilesImg style={{ marginRight: '0.5rem' }} />
                        {STRINGS.UPLOAD_FILES}
                      </> :
                        <>Update certificate</>}
                    </button>
                  </div>

                  <h6 className='material-tracker-header-card-header' style={{ marginBottom: "1rem" }}>Material Movement </h6>

                  <div className='material-tracker-general-details-form'>
                    {/* <CustomDropdown
                      // positionTop={true}
                      error={error && !materialData?.movement_type && movementValidationData.includes(index)}
                      dropdownOptions={movementTypeValues}
                      label='Type of movement'
                      placeholder='Enter type of movement'
                      onValueChange={(value) => {
                        let jobMaterials = JSON.parse(JSON.stringify(jobData));
                        jobMaterials['job_materials'][index].movement_type = value?.value || '';
                        setJobData(prev => jobMaterials)
                      }}
                      value={convertToCamelCase(materialData?.movement_type)?.replace(/_/g, " ")}
                    /> */}
                    <CustomDropdown
                      // positionTop={true}
                      error={error && !materialData?.material_availability && movementValidationData.includes(index)}
                      dropdownOptions={materialAvailabilityValues}
                      label='Material availability'
                      placeholder='Select material availability'
                      onValueChange={(value) => {
                        let jobMaterials = JSON.parse(JSON.stringify(jobData));
                        jobMaterials['job_materials'][index].material_availability = value?.value || '';
                        // Below line is temporary default value for movement type, could be removed in future
                        jobMaterials['job_materials'][index].movement_type = materialData.movement_type ? materialData.movement_type : jobMaterials['job_materials'][index]?.material_availability ? 'INBOUND' : null;
                        setJobData(prev => jobMaterials)
                      }}
                      value={convertToCamelCase(materialData?.material_availability)?.replace(/_/g, " ")}
                    />
                    {/* certificateStatusValues */}
                    <div style={{ display: "flex", flexDirection: "column", flex: 1, position: "relative" }}>
                      <TextField
                        focused
                        sx={UNDERLINE_INPUT_COLOR}
                        id="standard-basic"
                        label="Material quantity"
                        variant="standard"
                        placeholder='Enter material quantity'
                        type='text'
                        inputProps={{
                          step: 0.5,
                          autoComplete: 'off', // Disables auto-suggestions
                        }}
                        style={{ minWidth: '243px', flex: 1 }} InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {materialData?.material_name?.toLowerCase() === 'asphalt' ?
                                "tons" :
                                'C.Y'}
                            </InputAdornment>
                          ),
                        }}
                        value={formatNumberWithCommas(materialData?.material_quantity)}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          // Remove commas for validation
                          const cleanValue = inputValue.replace(/,/g, '');
                          // Basic number format validation (allows numbers and one decimal point)
                          const regex = /^\d*\.?\d*$/;
                          if (inputValue === "" || regex.test(cleanValue)) {
                            let jobMaterials = JSON.parse(JSON.stringify(jobData));
                            jobMaterials['job_materials'][index].material_quantity = cleanValue;
                            setJobData(prev => jobMaterials)
                          }
                        }}
                      />
                      {(error && !materialData?.material_quantity) && movementValidationData.includes(index) &&
                        <ErrorMsg />
                      }
                    </div>
                    {/* materials_acceptance_period */}
                    <div style={{ display: "flex", flexDirection: "column", position: "relative", flex: 1 }}>
                      <StyledDatePicker
                        placeHolder='Enter start date'
                        label='Start date'
                        value={materialData?.materials_acceptance_period?.start_date ?
                          moment(materialData?.materials_acceptance_period?.start_date, "DD-MM-YYYY").toDate() : ''}

                        onChange={(date) => {
                          let jobMaterials = JSON.parse(JSON.stringify(jobData));
                          jobMaterials['job_materials'][index].materials_acceptance_period.start_date = moment(date).format("DD-MM-YYYY");
                          setJobData(prev => jobMaterials)
                        }}
                        minDate={moment(jobData?.job_info?.start_date, "DD-MM-YYYY").toDate()}
                        maxDate={moment(jobData?.job_info?.end_date, "DD-MM-YYYY").toDate()}
                      />
                      {(error && !materialData?.materials_acceptance_period?.start_date) && movementValidationData.includes(index) &&
                        <ErrorMsg />
                      }
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", position: "relative", flex: 1 }}>
                      <StyledDatePicker
                        placeHolder='Enter end date'
                        label='End date'
                        value={
                          materialData?.materials_acceptance_period?.end_date ?
                            moment(materialData?.materials_acceptance_period?.end_date, "DD-MM-YYYY").toDate()
                            : ''
                        }
                        onChange={(date) => {
                          let jobMaterials = JSON.parse(JSON.stringify(jobData));
                          jobMaterials['job_materials'][index].materials_acceptance_period.end_date = moment(date).format("DD-MM-YYYY");
                          setJobData(prev => jobMaterials)
                        }}
                        minDate={moment(jobData?.job_info?.start_date, "DD-MM-YYYY").toDate()}
                        maxDate={moment(jobData?.job_info?.end_date, "DD-MM-YYYY").toDate()}
                      />
                      {(error && !materialData?.materials_acceptance_period?.end_date) && movementValidationData.includes(index) &&
                        <ErrorMsg />
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </>
        {/* } */}
      </div>
    </>
  )
}

export default CreateAndEditJob;


export function ErrorMsg() {
  return (
    <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem", position: 'absolute', left: "0px", bottom: "-20px" }}>
      {STRINGS.REQUIRED_FIELD_ERROR_MSG}
    </span>
  )
}
