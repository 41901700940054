import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import STRINGS from '../../../helpers/staticContentHelper';
import CircularProgress from '@mui/material/CircularProgress';
import { ADD_NEW_MATERIAL } from '../../../services/trackMaterialServices';
import { decryptResponse } from '../../../utils/encryptionDecryption';

const buttonStyle = {
    border: "1px solid #E6E8EA",
    borderRadius: "12px",
    color: "black",
    textTransform: 'none',
    "&:hover": {
        border: "1px solid #E6E8EA",
    },
    fontFamily: "Plus Jakarta Sans"
};

export const buttonSecondaryStyle = {
    border: "1px solid #ECF0F3",
    background: '#1B2E31',
    borderRadius: "12px",
    color: "white",
    whiteSpace: 'nowrap',
    textTransform: 'none',
    // backgroundColor: '#ECF0F3',
    "&:hover": {
        border: "1px solid #ECF0F3",
        color: "white",
        background: '#1B2E31',
    },
    "&:disabled": {
        border: "1px solid #ECF0F3",
        color: "#6D6D6D",
        background: '#DBDEE0',
    },
    fontFamily: "Plus Jakarta Sans"
};

export const popUpStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '0.5rem',
    fontFamily: "Plus Jakarta Sans"
};

const fontAndSize = {
    fontFamily: "Plus Jakarta Sans",
    color: 'rgb(27, 46, 49)',
    fontSize: '16px',
    margin: '0.1rem'
}

export const handleValidation = (data) => {
    delete data.subtype_name
    const errorValue = {
        isError: false,
        material_name: "",
    }
    Object.entries(data).forEach(([key, value]) => {
        if (!value) {
            errorValue[key] = STRINGS.REQUIRED_FIELD_ERROR_MSG
            errorValue.isError = true
        }
    });
    return errorValue
}

export const handleInviteUserValidation = (data) => {
    const errorValue = {
        isError: false,
        material_name: "",
    }
    Object.entries(data).forEach(([key, value]) => {
        if (value.length === 0) {
            errorValue[key] = STRINGS.REQUIRED_FIELD_ERROR_MSG
            errorValue.isError = true
        }
    });
    return errorValue
}

function AddMaterial({ open, setOpen, setNewMaterialList }) {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState({
        isError: false,
        material_name: "",
    })
    const [userFormData, setUserFormData] = useState({
        material_name: "",
    })

    const handleClose = () => {
        setOpen(prev => !prev)
    }

    const onChangeFormData = (name, value) => {
        setUserFormData(prev => {
            return { ...prev, [name]: value }
        })
        if (value) {
            setError(prev => {
                return { ...prev, [name]: '' }
            })
        } else {
            setError(prev => {
                return { ...prev, [name]: STRINGS.REQUIRED_FIELD_ERROR_MSG }
            })
        }
    }

    useEffect(() => {
        setUserFormData({
            material_name: "",
        })
    }, [open])

    const handleAddMaterial = async () => {
        const errorValues = handleValidation(userFormData)
        setError(errorValues)
        if (!errorValues.isError) {
            const requestData = {
                "material_info": userFormData
            }
            setIsLoading(true)
            const res = await ADD_NEW_MATERIAL(requestData);
            try {
                const decryptedResponse = JSON.parse(decryptResponse(res))
                setNewMaterialList(decryptedResponse)
                handleClose()
            } catch (error) {
                if (res?.response?.data?.detail.includes('SNR_2324')) {
                    alert('Duplicate material found')
                }
            }
            setIsLoading(prev => false)
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ ...popUpStyle, width: '500px', minHeight: 'auto', maxHeight: "90vh", display: 'flex', flexDirection: "column", justifyContent: "space-between", padding: '1.5rem', overflowY: "scroll" }}>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                        <p style={{ lineHeight: "0px", ...fontAndSize, textAlign: 'center', fontSize: '20px', fontWeight: '500', flex: 1 }}>
                           {STRINGS.ADD_MATERIAL}
                        </p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className='flex-input-box' style={{ marginTop: '1rem' }}>
                            <Typography className='input-label' sx={{ ...fontAndSize }}>
                                {STRINGS.ADD_MATERIAL_NAME}
                            </Typography>
                            <OutlinedInput size='small'
                                sx={{ ...fontAndSize }}
                                placeholder="Enter material name"
                                fullWidth
                                error={error.isError && error.material_name}
                                required
                                value={userFormData?.material_name}
                                className='outline-input-customized'
                                onChange={(e) => onChangeFormData('material_name', e.target.value)}
                            />
                            {(error.isError && error.material_name) &&
                                <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                    {error.material_name}
                                </span>
                            }
                        </div>
                    </div>
                </div>

                <div style={{ textAlign: 'end', marginTop: '2rem', display: 'flex', justifyContent: 'end' }}>
                    <Button variant="outlined" size="large"
                        sx={{ ...buttonStyle, marginRight: '1rem', width: '150px' }}
                        onClick={handleClose}
                    >
                        {STRINGS.CANCEL}
                    </Button>

                    {isLoading ?
                        <div style={{
                            width: '150px',
                            color: '#1B2E31',
                            display: "flex",
                            justifyContent: 'center',
                            border: "1px solid #ECF0F3",
                            borderRadius: "12px",
                        }}>
                            <CircularProgress
                                color="success"
                                sx={{ color: '#1B2E31' }}
                            />
                        </div> :
                        <Button variant="outlined"
                            size="large"
                            sx={{ ...buttonSecondaryStyle, width: '150px' }}
                            onClick={handleAddMaterial}
                        >
                            {STRINGS.ADD_MATERIAL}
                        </Button>}
                </div>
            </Box>
        </Modal>
    )
}

export default AddMaterial;