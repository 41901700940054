import React from 'react'
import { ReactComponent as SonarNewLogo } from '../../../assests/SonarNewLogo.svg';
import { ReactComponent as SearchLogo } from './search.svg';
import { ReactComponent as MaterialLogo } from './findMaterial.svg';
import { ReactComponent as TrackMaterialLogo } from './trackMaterial.svg';
import { ReactComponent as ConstructionGPTLogo } from './constructionGpt.svg';
import ProjectsHeader from '../../../components/projectsHeader';
import { useNavigate } from 'react-router-dom';
import { LStorage } from '../../../utils/localStorage.Helper';
import STRINGS from '../../../helpers/staticContentHelper';
import { NO_DOC_SEARCH_ACCESS_ORGS } from '../../../helpers/staticContentHelper';
function ProductDashboard() {
    const userData = LStorage.getUserData()
    const navigate = useNavigate();
    return (
        <div className='projects-main'>
            <ProjectsHeader setOpen={false} tableData={[]} hideButtons={true} />

            <div className='welcome-screen-main-container'>
                <SonarNewLogo style={{ width: "50px", height: "50px", marginBottom: '15px' }} />
                <p className='welcome-msg-user-text'>
                    Welcome, {userData.first_name}
                </p>
                <div className='user-option-for-welcome-screen'>

                    <div className='option-card-for-welcome-screen'>
                        <TrackMaterialLogo />
                        <p>Track materials</p>
                        <span>
                        Select a location and type of material to find suppliers nearby.
                        </span>
                        <button className='button-primary-web'
                            onClick={() => navigate('/track-material')}>
                                Track materials
                        </button>
                    </div>

                    {(userData?.org_id && !NO_DOC_SEARCH_ACCESS_ORGS.includes(userData?.org_id)) &&
                        <div className='option-card-for-welcome-screen' >
                            <SearchLogo />
                            <p>{STRINGS.DOCUMENT_SEARCH}</p>
                            <span>
                                {STRINGS.CREATE_PROJECTS_UPLOAD_DOCUMENTS_AND_QUICKLY_LOOK_UP}
                            </span>
                            <button className='button-primary-web'
                                onClick={() => navigate('/projects')}>
                                {STRINGS.DOCUMENT_SEARCH}
                            </button>
                        </div>
                    }

                    <div className='option-card-for-welcome-screen'>
                        <MaterialLogo />
                        <p>{STRINGS.FIND_MATERIALS}</p>
                        <span>
                            {STRINGS.FIND_MATERIALS_SUB_TEXT}
                        </span>
                        <button className='button-primary-web'
                            onClick={() => navigate('/material-search')}>
                            {STRINGS.FIND_MATERIALS}
                        </button>
                    </div>

                    {!userData.role &&
                        <div className='option-card-for-welcome-screen' >
                            <ConstructionGPTLogo />
                            <p>{STRINGS.CONSTRUCTION_GPT}</p>
                            <span>
                                {STRINGS.CONSTRUCTION_GPT_SUB_TEXT}
                            </span>
                            <button className='button-primary-web'
                                onClick={() => navigate('/construction-gpt-home')}>
                                {STRINGS.CONSTRUCTION_GPT}
                            </button>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default ProductDashboard;