import CryptoJS from 'crypto-js';
import moment from 'moment/moment';

export const encryptPayload = (payload, isFormData = false) => {
    try {
        const secretKey = CryptoJS.lib.WordArray.random(16).toString();

        const random16Value = random16String()

        const iv = CryptoJS.enc.Utf8.parse(random16Value);

        const derived_key = CryptoJS.enc.Base64.parse(secretKey);

        const encryptionOptions = {
            iv: iv,
            mode: CryptoJS.mode.CBC
        };

        // Serialize payload object to JSON string
        const jsonString = JSON.stringify(payload);

        // Encrypt the payload using AES encryption
        const encrypted = CryptoJS.AES.encrypt(jsonString, derived_key, encryptionOptions).toString();

        const encrypted_data = secretKey + encrypted + random16Value

        const encrypted_payload = {
            payload: encrypted_data
        }

        return isFormData ? encrypted_data : encrypted_payload

    } catch (error) {
        console.error(error)
    }

};

// Function to generate a random 16-digit buffer
export function random16String() {
    let result = '';
    for (let i = 0; i < 16; i++) {
        result += Math.floor(Math.random() * 10);
    }
    return String(result);
}

export function decryptResponse(encryptedText) {
    try {    // Extract IV from the encrypted text
        var ivBase64 = encryptedText.substring(0, 24);
        var encryptedData = encryptedText.substring(24, encryptedText.length - 24);
        var keyBase64 = encryptedText.substring(encryptedText.length - 24);

        // Convert the base64 encoded IV to a word array
        var iv = CryptoJS.enc.Base64.parse(ivBase64);

        // Convert the base64 encoded key to a word array
        var key = CryptoJS.enc.Base64.parse(keyBase64);

        // Convert the ciphertext from base64 to a word array
        var ciphertext = CryptoJS.enc.Base64.parse(encryptedData);

        // Decrypt the ciphertext using the key and IV
        var decrypted = CryptoJS.AES.decrypt({
            ciphertext: ciphertext
        }, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        // Convert the decrypted data to a string and return
        return decrypted.toString(CryptoJS.enc.Utf8);

    } catch (error) {
        console.error(error)
    }

}

export function IS_USER_ADMIN(data) {
    return ((data?.org_id && (data?.role === 'admin')) || !data?.role)
}

// encryption and decryption for local storage use
export function encryptObject(object) {
    const secretKey = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY || 'simple-secret-key'
    const stringifiedObject = JSON.stringify(object);
    const encrypted = CryptoJS.AES.encrypt(stringifiedObject, secretKey).toString();
    return encrypted;
}

export function decryptObject(encryptedObject) {
    const secretKey = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY || 'simple-secret-key'
    const bytes = CryptoJS.AES.decrypt(encryptedObject, secretKey);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    const decryptedObject = JSON.parse(decryptedString);
    return decryptedObject;
}

export const generateUUID = () => {
    let randomBytes = CryptoJS.lib.WordArray.random(16).toString();
    return 'xxxxxxxxxxxx55xxxy'.replace(/[xy]/g, function (c) {
        let r = parseInt(randomBytes.substr(0, 2), 16) % 16;
        let v = c === 'x' ? r : (r & 0x3) | 0x8;
        randomBytes = randomBytes.slice(2); // Shift the bytes
        return v.toString(16);
    });
};

export const checkUserPermission = (permission_type, userData = {}) => {
    const { permissions, org_id } = userData;

    if (permission_type === 'INVITE' && !org_id) {
        return false
    }

    if (org_id && (permissions?.includes(permission_type) || permissions?.includes('FULL'))) {
        return true
    }

    return false
}


export const checkHasFavAggrigates = (markedMaterials = []) => {
    let hasAggregates = [];
    markedMaterials.forEach(material =>
        material.favorites.forEach(materialData => {
            if (materialData.material && materialData.available) {
                hasAggregates.push(materialData.material)
            }
        })
    );

    return hasAggregates
}

export const checkHasBloackListAggrigates = (markedMaterials = []) => {
    let hasAggregates = [];
    markedMaterials.forEach(material =>
        material.blacklisted.forEach(materialData => {
            if (materialData.material && materialData.available) {
                hasAggregates.push(materialData.material)
            }
        })
    );

    return hasAggregates
}


export function areObjectsEqual(obj1, obj2) {

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
        if (key === "materials") {
            // Check if materials arrays are equal
            if (!Array.isArray(obj1.materials) || !Array.isArray(obj2.materials)) return false;
            if (obj1.materials.length !== obj2.materials.length) return false;

            // Check each item in the materials arrays
            const materials1 = [...obj1.materials].sort();
            const materials2 = [...obj2.materials].sort();
            if (!materials1.every((val, index) => val === materials2[index])) return false;
        } else if (obj1[key] !== obj2[key]) {
            return false;
        }
    }
    return true;
}

export const convertToCamelCase = (string)=>{
    if(!string) return ''
   return  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase().replace(/_/g, " ");
}

export function formatDateWithMoment(dateString) {
    // Parse the date using the input format and format it to desired output
    if(!dateString) return ''
    return moment(dateString, "DD-MM-YYYY").format("MM/DD/YY");
  }