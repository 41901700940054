import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchGptInput from './chatWithGpt';
import STRINGS from '../../helpers/staticContentHelper';
import '../newProjectDetails/projectDetails.css'
import { ReactComponent as SearchEnterImg } from '../../assests/searchEnter.svg';
import SonarLogo from '../../assests/SonarNewLogo.svg';
import GPTLogo from './assests/gpt.svg';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import useWebSocket, { ReadyState } from "react-use-websocket";
import { LStorage } from '../../utils/localStorage.Helper';
import { LOAD_THREAD_MESSAGES, MONTHLY_GPT_CHAT_COUNT } from '../../services/constructionGpt.services';
import { useRememberQuestionAsked } from '../../services/newThreadQuestionContext';
import { decryptResponse, encryptPayload } from '../../utils/encryptionDecryption';
import { useFunctionContext } from '../../services/triggerLocationHistoryContext'
import { useAvailableConfiguredGPTs } from '../../services/availableConfiguredGpts';
import AutoResizeTextarea from './AutoResizeTextarea';
import PopupForm from '../formPage/popupForm';
import { useStoreConstructionGptContext } from '../../services/constuctionGptContext';
function ConstructionChat() {
    const { constructionGptValues, setConstructionGptValues } = useStoreConstructionGptContext();
    const textareaRef = useRef(null);
    const { sharedFunction } = useFunctionContext();
    const { availableConfiguredGPTs } = useAvailableConfiguredGPTs()
    const { lastAskedQuestion, setLastAskedQuestion } = useRememberQuestionAsked();
    const userData = LStorage.getUserData()
    const [searchParams, setSearchParams] = useSearchParams();
    const [newThreadQuestion, setNewThreadQuestion] = useState('');
    let threadId = searchParams.get('threadId')
    const [imageData, setImageData] = useState([]);
    const [socketResponse, setSocketResponse] = useState(null);
    const [fetchingResult, setFetchingResult] = useState(false);
    const [isAnswerFetching, setIsAnswerFetching] = useState(false);
    const [isThreadDataFetching, setIsThreadDataFetching] = useState(false);
    const [searchValue, setSearchValue] = useState('')
    const [monthlyLimit, setMonthlyLimit] = useState(null);
    const [isUserFormOpen, setIsUserFormOpen] = useState(false)
    let scrollDownRef = useRef(null);


    const getMonthlyLimit = async () => {
        const res = await MONTHLY_GPT_CHAT_COUNT()
        if (res) {
            const decryptedResponse = JSON.parse(decryptResponse(res))
            setMonthlyLimit(decryptedResponse)
            setConstructionGptValues(prev => {
                return { ...prev, monthlyLimit: decryptedResponse }
            })
        }
    }

    // websocket connection start
    const WS_URL = process.env.REACT_APP_BACKEND_WEB_SOCKET_API + "/add-thread-message/"
    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(WS_URL,
        {
            shouldReconnect: () => true, // Automatically reconnect on disconnect
            onOpen: () => console.log('WebSocket connected'),
            onClose: () => console.log('WebSocket closed'),
            onError: (error) => console.error('WebSocket error:', error),
        });

    const triggerAskQuestion = useCallback(async (payload) => {
        if (readyState !== ReadyState.OPEN) {
            if (readyState !== ReadyState.OPEN) {
                await new Promise((resolve) => setTimeout(resolve, 500));
            }
        }
        const encryptedPayload = encryptPayload({ ...payload, access_token: userData.access_token })
        sendMessage(JSON.stringify(encryptedPayload))
        setSearchValue('')
        scrollToView();
    }, [readyState]) //eslint-disable-line

    useEffect(() => {
        const socket = getWebSocket();
        if (lastMessage !== null && lastMessage.data) {
            try {
                if (!socketResponse) {
                    scrollToView();
                }
                const jsonStr = lastMessage.data
                const data = JSON.parse(jsonStr);
                if (data.status === 'error' && data.error_code === 'SNR_2113') {
                    setIsUserFormOpen(true)
                    setSocketResponse(prev => null)
                    setImageData(prev => {
                        return [...prev].slice(0, -1)
                    })
                    socket.close();
                    return
                }
                if (data.status === 'success' && data.display_text) {
                    setSocketResponse(prev => {
                        return { response: prev?.response ? prev?.response + data.display_text : data.display_text }
                    })
                }
                if (data.status === 'done') {
                    const deepCopyOfImageData = JSON.parse(JSON.stringify(imageData));
                    if (deepCopyOfImageData.length > 0) {
                        deepCopyOfImageData[deepCopyOfImageData.length - 1].response = socketResponse?.response;
                        deepCopyOfImageData[deepCopyOfImageData.length - 1].threadId = data.thread_id;
                    }
                    setImageData(prev => deepCopyOfImageData);
                    setSocketResponse(null)
                    sharedFunction(Math.floor(Math.random() * 10001))
                    getMonthlyLimit()
                }
            } catch (error) {
                console.log(error)
            }
        }
        if (readyState === ReadyState.CLOSING || readyState === ReadyState.CLOSED) {
            socket.close();
        }
    }, [lastMessage])

    const logoutUser = () => {
        LStorage.removeUserData()
        // navigate('/dashboard')
    }

    const WS_URL_NEW_THREAD = process.env.REACT_APP_BACKEND_WEB_SOCKET_API + "/create-thread/"

    const { sendMessage: sendMessageNewThread,
        lastMessage: lastMessageNewThread,
        readyState: readyStateNewThread,
        getWebSocket: getWebSocketNewThread
    } = useWebSocket(WS_URL_NEW_THREAD,
        {
            shouldReconnect: () => true, // Automatically reconnect on disconnect
            onOpen: () => console.log('WebSocket connected'),
            onClose: () => console.log('WebSocket closed'),
            onError: (error) => console.error('WebSocket error:', error),
        });

    const triggerCreateNewThread = useCallback(async (payload) => {
        if (readyStateNewThread !== ReadyState.OPEN) {
            if (readyStateNewThread !== ReadyState.OPEN) {
                await new Promise((resolve) => setTimeout(resolve, 500));
            }
        }
        const encryptedPayload = encryptPayload({ ...payload, access_token: userData.access_token });
        sendMessageNewThread(JSON.stringify(encryptedPayload))
    }, [readyStateNewThread]) //eslint-disable-line

    useEffect(() => {
        const socket = getWebSocketNewThread();
        if (lastMessageNewThread !== null && lastMessageNewThread.data) {
            try {
                const jsonStr = lastMessageNewThread.data
                const data = JSON.parse(jsonStr);
                if (data.status === 'error' && data.error_code === 'SNR_2113') {
                    setIsUserFormOpen(true)
                    setSocketResponse(prev => null)
                    setImageData(prev => [])
                    setFetchingResult(false)
                    return
                }
                if (data.status === 'success' && data.display_text) {
                    setSocketResponse(prev => {
                        return { response: prev?.response ? prev?.response + data.display_text : data.display_text }
                    })
                }
                if (data.status === 'done') {
                    setImageData(prev => {
                        let finalData = {
                            response: socketResponse?.response,
                            question: prev[0].question,
                            threadId: data.thread_id
                        }
                        return [finalData]
                    })
                    socket.close();
                    setSocketResponse(null)
                    sharedFunction(Math.floor(Math.random() * 10001))
                    getMonthlyLimit()
                }
            } catch (error) {
                console.log(error)
            }
        }
        if (readyState === ReadyState.CLOSING || readyState === ReadyState.CLOSED) {
            socket.close();
        }
    }, [lastMessageNewThread])

    const scrollToView = () => {
        if (scrollDownRef.current) {
            scrollDownRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            })
        }
    }

    const getThreadData = async (threadId) => {
        const res = await LOAD_THREAD_MESSAGES(threadId);
        const decryptedResponse = JSON.parse(decryptResponse(res))
        if (decryptedResponse) {
            setImageData(prev => decryptedResponse)
        }
        setIsThreadDataFetching(prev => false)
    }

    useEffect(() => {
        if (threadId) {
            setImageData(prev => [])
            setFetchingResult(true)
            setIsThreadDataFetching(true)
            getThreadData(threadId)
        } else {
            setFetchingResult(false)
            setImageData([])
        }
    }, [threadId])

    useEffect(() => {
        if (newThreadQuestion) {
            setImageData([{ question: newThreadQuestion }])
            const payload = {
                "role": "user",
                "assistant_id": availableConfiguredGPTs[0].assistant_id,
                "content": newThreadQuestion
            }
            setNewThreadQuestion(prev => '')
            setFetchingResult(true)
            triggerCreateNewThread(payload)
            setLastAskedQuestion('')
        }
    }, [newThreadQuestion])

    useEffect(() => {
        if (lastAskedQuestion) {
            setNewThreadQuestion(lastAskedQuestion)
        }
    }, [lastAskedQuestion])

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter' && availableConfiguredGPTs.length > 0) {
            askQuestionInThread(searchValue)
        }
    }

    const askQuestionInThread = (searchValue) => {
        const reqPayload = {
            "role": "user",
            "assistant_id": availableConfiguredGPTs[0].assistant_id,
            "content": searchValue,
            "thread_id": threadId ? threadId : imageData[0]['threadId'],
        }
        setImageData(prev => {
            return [...prev, { question: searchValue }]
        })
        triggerAskQuestion(reqPayload)
    }

    useEffect(() => {
        // Auto-focus the textarea when the component mounts
        if (searchValue.length <= 60 && document.activeElement !== textareaRef.current) {
            if (textareaRef.current) {
                if (textareaRef.current) {
                    const textarea = textareaRef.current;
                    // Focus the textarea
                    textarea.focus();
                    // Move cursor to the end of the text
                    textarea.selectionStart = textarea.value.length;
                    textarea.selectionEnd = textarea.value.length;
                }
            }
        }
    }, [searchValue]);

    useEffect(() => {
        if(constructionGptValues.monthlyLimit){
            setMonthlyLimit(constructionGptValues.monthlyLimit)
        }else{
            getMonthlyLimit()
        }
    }, [])

    return (
        <div style={{ flex: 1, display: "flex", width: '100%', position: 'relative', maxHeight: "100%", paddingTop: '0.5rem' }}>
            {monthlyLimit &&
                <span style={{ position: 'absolute',top: '24px', left: '15px', fontSize: "0.7rem", backgroundColor: '#e2e2e2', borderRadius: "0.5rem", padding: "0.2rem 0.5rem" }} className="filter-option-tooltip">
                    {STRINGS.MONTHLY_LIMIT} {monthlyLimit.used}/30
                </span>}
            <PopupForm open={isUserFormOpen} setOpen={setIsUserFormOpen} />
            {fetchingResult ?
                <div className='project-file-result' style={{ maxHeight: "calc(100% - 2rem)", paddingTop: "1rem", position: "relative" }}>
                    <div className='result-after-fetch-data'>
                        {isThreadDataFetching &&
                            [0, 1, 2, 3, 4, 5].map(i => {
                                return (
                                    <div className='answer-skelton-loader' style={{ marginBottom: "1.5rem" }}>
                                        <div className='skelton-line-1' key={'skelton-line-1'}></div>
                                        <div className='skelton-line-2' key={'skelton-line-2'}></div>
                                        <div className='skelton-line-3' key={'skelton-line-3'}></div>
                                        <div className='skelton-line-4' key={'skelton-line-4'}></div>
                                    </div>
                                )
                            })
                        }
                        {imageData.map((singleImageData, questionIndex) => {
                            return (
                                <React.Fragment key={questionIndex + 'answer'}>
                                    <div className='question-asked'>
                                        <span className='result-question' style={{ backgroundColor: '#f3f3f3', padding: '8px 18px', borderRadius: '18rem', fontSize: "16px", margin: "0px", maxWidth: "80%", margin: "0px", lineHeight: '1.2rem' }}>{singleImageData.question}</span>
                                    </div>
                                    {/* answer component */}
                                    <div className='after-file-search-matches'>
                                        {singleImageData.response ?
                                            <div className='chat-response' style={{ position: "relative", paddingLeft: "28px" }}>
                                                <Markdown remarkPlugins={[[remarkGfm]]} className='mark-up'>
                                                    {singleImageData.response}
                                                </Markdown>
                                                <img src={SonarLogo} alt='not-found' width='20px' style={{ position: 'absolute', top: "13px", left: '10px' }} className={`${isAnswerFetching && imageData.length - 1 === questionIndex && 'rotate-animation-around-class'}`} />
                                            </div>
                                            :
                                            (socketResponse && !singleImageData.response) ?
                                                <div className='chat-response' style={{ position: "relative", paddingLeft: "28px" }}>
                                                    <Markdown remarkPlugins={[[remarkGfm]]} className='mark-up'>
                                                        {socketResponse?.response}
                                                    </Markdown>
                                                    <img src={SonarLogo} alt='not-found' width='20px' style={{ position: 'absolute', top: "13px", left: '10px' }} className={`${isAnswerFetching && imageData.length - 1 === questionIndex && 'rotate-animation-around-class'}`} />
                                                </div>
                                                :
                                                <div className='answer-skelton-loader'>
                                                    <div className='skelton-line-1' key={'skelton-line-1'}></div>
                                                    <div className='skelton-line-2' key={'skelton-line-2'}></div>
                                                    <div className='skelton-line-3' key={'skelton-line-3'}></div>
                                                    <div className='skelton-line-4' key={'skelton-line-4'}></div>
                                                </div>
                                        }
                                    </div>
                                    <div ref={scrollDownRef} />
                                </React.Fragment>
                            )
                        })}
                    </div>
                    <div className='search-box-input-inside' style={{ alignItems: searchValue.length > 60 && "flex-end" }}>
                        {searchValue.length > 60 ?
                            <AutoResizeTextarea
                                _handleKeyDown={_handleKeyDown}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                            /> :
                            <input
                                ref={textareaRef}
                                type='text'
                                placeholder='Search'
                                onChange={(e) => setSearchValue(e.target.value)}
                                onKeyDown={(e) => {
                                    if (searchValue) {
                                        _handleKeyDown(e)
                                    }
                                }}
                                value={searchValue}
                                autocomplete="off"
                            />
                        }
                        <SearchEnterImg className={`${!searchValue && 'image'}`} style={{ cursor: searchValue && 'pointer' }} onClick={() => {
                            if (searchValue.length > 0 && availableConfiguredGPTs.length > 0) {
                                askQuestionInThread(searchValue)
                            }
                        }} />
                    </div>
                </div>
                : <SearchGptInput availableConfiguredGPTs={availableConfiguredGPTs} setNewThreadQuestion={setNewThreadQuestion} fetchingResult={fetchingResult} />
            }
        </div>
    )
}

export default ConstructionChat;