const STRINGS = {
  ASK_ME_ANYTHING: 'Ask me anything',
  PRODUCTS: 'Products',
  CONSTRUCTION_GPT_SUB_TEXT: 'Create custom GPTs with your prompts and files.',
  WHAT_CAN_I_HELP_YOU_WITH_TODAY: 'What can I help you with today?',
  START_NEW_CHAT: 'Start new chat',
  NAME_YOUR_GPT: 'Name your GPT ',
  YOU_WANT_TO_DELETE: 'You want to delete',
  DELETE: 'Delete',
  ARE_YOU_SURE: 'Are you sure? ',
  FILE_LIMIT_512MB: 'total files size must not exceed 512MB.',
  MAX_FILE_FILE_FOR_GPT: 'The maximum file size for a single PDF we currently support is 512MB',
  FIND_MATERIALS: 'Find Materials ',
  CREATE_PROJECTS_UPLOAD_DOCUMENTS_AND_QUICKLY_LOOK_UP: 'Create projects, upload documents and quickly look up answers.',
  FIND_MATERIALS_SUB_TEXT: 'Find material vendors and landfills near your job site.',
  NO_HISTORY_FOUND: 'No search history found',
  NO_HISTORY_FOUND_SUB_TITLE: 'Your recent searches will appear here. Start exploring materials to build your search history',
  NO_BLACKLIST: 'No blacklisted suppliers',
  NO_BLACKLIST_SUB_TITLE: "Haven't added anyone to the blacklist? Keep an eye on unreliable suppliers and blacklist them if needed.",
  NO_FAVORITES_YET: 'No favorites yet',
  NO_FAVORITES_YET_SUB_TITLE: 'Save suppliers you trust for quick access later. Start by marking your top suppliers as favorites.',
  COMPARE: 'Compare',
  COMPARE_SHEETS: 'Compare documents',
  UPLOAD_FILES: ' Upload files',
  ADD_DOCUMENTS_FOR_UPLOAD: ' Add your document here. We currently support only PDFs.',
  FILE_NAME: 'File name',
  PAGE_NUMBER: 'Page number',
  FOLLOW_UP_PLACEHOLDER: "Ask follow up",
  CREATE_PROJECT: 'Create project',
  NO_PROJECTS: 'No projects',
  NO_PROJECTS_DESC: 'Start this journey by creating your first project',
  INITIAL_MATERIAL_SEARCH_MSG: 'Start your journey by searching for materials',
  TOTAL_PROJECTS: 'Total projects',
  USER_CONTEXT_ON_PROJECTS: 'Manage and view all your projects and documents in one place',
  ADD_FILES_TO_PROJECT: 'Your files will be added to the project',
  WE_ARE_INDEXING_FILE: 'Your file is currently being analyzed',
  CHECK_AFTER_FEW_MINUTES: 'please check again in a few minutes',
  LOADING_SUGGESTIONS: 'Loading suggestions for you',
  ASK_QUESTIONS_TO_PDF: 'Ask questions to your PDF',
  NO_FILES: 'No files',
  YOU_CAN_UPLOAD_MAXIMUM_4_FILES: 'you can upload a maximum of 10 files each time',
  UPLOAD_LIMIT_REACHED: 'Upload limit reached',
  WOULD_YOU_LIKE_TO_ASK_ANOTHER_QUESTION: ' Would you like to ask another question?',
  COULD_NOT_FOUND_ANY_INFORMATION: 'Sorry, we couldn’t find any information in this PDF.',
  FILE_SIZE_LIMIT_REACHED: 'total files size must not exceed 1GB.',
  SINGLE_FILE_SIZE_LIMIT_REACHED: 'The maximum file size for a single PDF we currently support is 250MB',
  COPYRIGHT_TEXT: `Copyright © ${new Date().getFullYear()} Sonarlabs.ai | All Rights Reserved`,
  PRIVACY_POLICY: 'Privacy policy',
  START_PDF_UPLOAD: 'Boost your construction efficiency',
  TERMS_OF_USE: 'Terms of use',
  BOOK_DEMO: 'Book a demo',
  SIGN_IN_TEXT: 'Sign in',
  UPLOADED_ON: 'Uploaded on',
  CREATED_ON: 'Created on',
  CREATE: 'Create',
  ADD_DOCUMENT_MSG: 'Add your document here. We currently support only PDFs.',
  DRAWINGS_SUPPORT_SOON: 'Support for drawings is coming soon!',
  DRAG_AND_DROP: 'Drag and drop your files or',
  BROWSE_FILES: 'Browse Files',
  SAVE: 'Save',
  ADD_TO_PROJECT: 'Add to project',
  HISTORY: 'History',
  NEXT: 'Next',
  UPLOAD_FILES_LATER: 'Upload files later',
  BACK: 'Back',
  FILE: 'File',
  PROJECT: 'Project',
  YOUR_PROJECTS: 'Your projects',
  VIEW: 'View',
  VIEW_PROJECT: 'View  project',
  READY_FOR_SEARCH: 'Ready for search',
  INDEXING: 'Analyzing',
  MATCHES: 'Matches',
  VIEW_ALL_MATCHES: 'View all the matches',
  SHOW_LESS_MATCHES: 'Show less',
  ANSWER: 'Answer',
  PDF_VIEW: 'PDF view',
  MATCHES_FOUND: 'matches found',
  FILES_AND_FOLDERS: 'Files and folders',
  BACK_TO_PROJECTS: 'Back to projects',
  HELP_AND_SUPPORT: 'Help and support',
  ASK_QUESTIONS_FOR_CLARITY: 'Search within this PDF',
  TYPE_FILE_FOLDER: 'FileAndFolder',
  DUPLICATE_FILE_TITLE: 'Duplicate file name',
  DUPLICATE_FILE_DESCRIPTION: 'A file with this name exists already. Please upload any other file',
  HOME: "Home",
  CREATE_FOLDER: 'Create folder',
  SEARCH_HISTORY: 'Search history',
  NO_HISTORY: 'No history found',
  ANALYZING_THE_DOCUMENT: 'Analyzing PDF',
  SOURCES: 'Sources',
  DELETE_IN_PROGRESS: 'Delete in progress',
  SEARCH_WITHIN_PROJECT: 'Search within project:',
  INVITE_USER_TO_TEAM: 'Invite your team',
  INVITE_USER: 'Invite user',
  USERS: 'Users',
  CANCEL: 'Cancel',
  ROLE: 'Role',
  EMAIL: 'Email',
  LAST_NAME: 'Last name',
  FIRST_NAME: 'First name',
  PDF_WARN: '*we only support .PDF',
  REQUIRED_FIELD_ERROR_MSG: '*required',
  ENTER_VALID_EMAIL_ERROR_MSG: 'please enter valid email',
  PRICING: "Pricing",
  ABOUT_US: 'About us',
  SECURITY: 'Security',
  SECURITY_LINK: '/security',
  CONTACT: 'Contact',
  FEATURES: 'Features',
  GET_IN_TOUCH: 'Get in touch',
  AI_POWERED_DOCUMENT: 'AI powered document search for AEC',
  SIMPLIFY_YOUR_WORKFLOW: 'Simplify your workflow with three steps',
  OUR_SOLUTION: 'OUR SOLUTION',
  START_BY_UPLOADING_PDF: ' Start by uploading a PDF',
  PROBLEM: "PROBLEM",
  FINDING_RIGHT_INFO: 'Finding the right information within multiple documents is hard',
  SOLVING_CONSTRUCTION_SEARCH_DILEMMA: "Solving construction's search dilemma",
  WITH_SONAR_RECALL_LOST_HOURS: 'With Sonar Labs, reclaim lost hours spent searching for crucial information. Our platform delivers precise results instantly, saving you valuable time.',
  LEAR_MORE_ABOUT_SECURITY: 'Learn more about our security practices',
  INTEGRATION: 'INTEGRATIONS (COMING SOON)',
  GATHER_QUICKER_ANSWERS: 'Gather quicker answers and deeper analysis by connecting Sonar Labs to the tools used in your workflow.',
  YOUR_DOCUMENTS_YOUR_DATA_SECURED: 'Your documents and data, fully secured.',
  SECURITY_UPPERCASE: 'SECURITY',
  SOLUTION: 'SOLUTION',
  FIND_ANSWERS_TO_YOUR_QUESTIONS: 'Find answers to your questions instantly with Sonar Labs',
  GET_STARTED: 'Get started',
  ADDRESS: " #4 Bellary Road, 3rd Floor, Vaibhava Center, Sadashiv Nagar, Bangalore, 560080.",
  CIN: 'CIN : U72900KA2021PTC150806',
  FOR_CONSTRUCTION_PROFESSIONALS: 'For construction professionals',
  FOR_CONSTRUCTION_PROFESSIONALS_BUTTON_TEXT: 'for_construction_professionals_clicked_hero_section',
  FOR_CONSTRUCTION_PROFESSIONALS_DESCRIPTION: 'For the professionals on the go, Sonar Labs allows you to swiftly locate key details from thousands of documents within seconds, giving access to vital information',
  SECURITY_AND_ENCRYPTION: 'Secure and encrypted',
  SECURITY_AND_ENCRYPTION_BUTTON_TEXT: 'secure_and_encrypted_clicked_hero_section',
  SECURITY_AND_ENCRYPTION_DESCRIPTION: 'At Sonar Labs, your documents and data are fully encrypted, ensuring their security during every upload and search.',
  WATCH_SONAR_IN_ACTION: 'Watch Sonar Labs in action',
  WATCH_SONAR_IN_ACTION_BUTTON_TEXT: 'watch_sonar_in_action_clicked_hero_section',
  MANUAL_SEARCHING: '30 minutes of manual searching',
  MATCHES_IN_A_PDF: '100+ matches found in a single PDF',
  CONFUSING_EMAIL_THREADS: 'Confusing email threads',
  MULTIPLE_EMAIL_CHAINS: 'Multiple email chains found',
  CTRL_F: 'Ctrl + F',
  NO_MATCHES_FOUND: 'No matches found! OCR not supported.',
  HOURS_WEEK: '10+ hours/week',
  SPENT_SEARCHING_FOR_INFORMATION: 'Spent searching for information',
  UPLOAD_OR_SYNC_YOUR_FILES: 'Upload or syncing your files',
  UPLOAD_OR_SYNC_YOUR_FILES_BUTTON_TEXT: 'upload_or_synching_your_files',
  ASK_QUESTIONS_TO_SONAR: 'Ask questions to Sonar Labs',
  ASK_QUESTIONS_TO_SONAR_BUTTON_TEXT: 'ask_questions_to_sonar',
  FAST_AND_SECURE_SCANNING: 'Fast and secure scanning',
  FAST_AND_SECURE_SCANNING_BUTTON_TEXT: 'fast_and_secure_scanning',
  PRECISE_RESULTS_AND_CREATED_SOURCES: 'Precise results and curated sources',
  PRECISE_RESULTS_AND_CREATED_SOURCES_BUTTON_TEXT: 'precise_results_and_curated_sources',
  SCANNED_IMAGES_AND_DOCUMENTS: 'Scanned images and documents',
  CONSTRUCTION_DRAWINGS_AND_DETAILS: 'Construction drawings and details',
  INFORMATION_HIDDEN_WITHIN_COMPLEX_FILES: 'Information hidden within complex files',
  NO_MORE: 'No more',
  SONAR_CAN_SEARCH_THROUGH: 'Sonar Labs can search through...',
  DATA_PRIVACY: 'Data privacy',
  YOUR_DOCUMENTS_REMAIN_PRIVATE: 'It’s simple, your documents remain private and encrypted.',
  ENCRYPTION: 'Encryption',
  DATA_TRANSFERS_ARE_ENCRYPTED_FOR_SECURE_UPLOADS: 'Our systems are designed for encryption during uploads and processing of documents.',
  CONTROLLED_ACCESS: 'Controlled access',
  YOU_CONTROL_WHO_ACCESSES_YOUR_DOCUMENTS: 'You control who accesses your documents within the organization.',
  COMPLIANCE: 'Compliance',
  TERMS_AND_CONDITIONS: 'Terms and condition',
  TERMS_AND_CONDITIONS_LINK: '/terms-of-use',
  TERMS_AND_CONDITIONS_BUTTON_TEXT: 'terms_of_use_clicked_footer',
  PRIVACY_LINK: '/privacy',
  PRIVACY_LINK_BUTTON_TEXT: 'privacy_policy_clicked_footer',
  CONTACT_US: 'Contact us',
  SONAR_LABS_EMAIL: 'hello@sonarlabs.ai',
  OUR_MEASURES: 'OUR MEASURES',
  SECURITY_YOU_TRUST: 'Security you can trust',
  ROBUST_AWS_BACKED_SECURITY: "Robust AWS-backed security",
  ROBUST_AWS_BACKED_SECURITY_description: 'Hosted on AWS, our infrastructure ensures top-tier reliability, encryption, and privacy for your data.',
  PRIVATE_BY_DESIGN: "Private by design",
  PRIVATE_BY_DESIGN_DESCRIPTION: 'We ensure your data is always safe, guaranteeing you privacy and security.',
  MANAGE_USERS: "Manage users",
  MANAGE_USERS_DESCRIPTION: 'Easily grant, modify, and revoke permissions for team members. Full control over who can access your data.',
  SSO_SUPPORT: "SSO support (coming soon)",
  SSO_SUPPORT_DESCRIPTION: 'Support for various SSO providers will allow secure, convenient access with trusted services.',
  UNCOMPROMISING_SECURITY: 'Uncompromising security',
  FOR_YOUR_DATA: 'for your data',
  OUR_SECURITY_MEASURES_ENSURE_YOUR_INFORMATION: 'Our security measures ensure your information stays protected.',
  AT_OUR_CORE: 'At our core, we prioritize the security and privacy of your data.',
  BLOG: 'Blog',
  RENAME_FOLDER: 'Rename folder',
  FOCUS_MODE_DESCRIPTION: 'Designed for extracting answers in',
  FOCUS: 'Focus',
  SEARCH: 'Search',
  DRAWINGS: 'drawings',
  CNNOT_FIND_YOUR_TEAM_MATE: "Can’t find your teammate?",
  ADD_NEW_USER: 'Add new user',
  THIS_ACTION_CANNOT_BE_UNDONE: 'This action cannot be undone.',
  DELETE_FROM_ORG: 'Are you sure you want to delete?',
  DELETE_FROM_PROJECT: 'Remove this user from the project?',
  DELETE_FROM_JOB: 'Remove this user from the job?',
  PROJECT_ACCESS_REVOKED: 'Project access revoked',
  JOB_ACCESS_REVOKED: 'Job access revoked',
  USER_HAS_BEEN_REMOVED_FROM_PROJECT: 'User has been removed from this project',
  USER_HAS_BEEN_REMOVED_FROM_JOB: 'User has been removed from this job',
  USER_HAS_BEEN_REMOVED_FROM_ORG: 'User has been removed from your organisation',
  DELETE_SUCCESSFUL: 'Delete successful',
  INVITE_SENT: 'Invite sent',
  USER_HAS_BEEN_NOTIFIED_TO_JOIN_ORG: 'User has been notified to join your organisation',
  USER_HAS_BEEN_NOTIFIED_TO_JOIN_PROJECT: 'Users have been notified to join your project',
  USER_HAS_BEEN_NOTIFIED_TO_JOIN_JOB: 'Users have been notified to join your job',
  USERS_HAS_BEEN_NOTIFIED_TO_JOIN_PROJECT: 'User has been notified to join your project',
  USERS_HAS_BEEN_NOTIFIED_TO_JOIN_JOB: 'User has been notified to join your job',
  PROJECT_ADDRESS: 'Project address',
  SEARCH_MATERIAL: 'Search materials',
  PROXIMITY: 'Proximity',
  PRODUCT_MATERIAL: 'Product material',
  SELECT_PRODUCT_TYPE: 'Select product type',
  MATERIAL_SEARCH: 'Material search',
  VERIFIED_BY_SONAR_LABS: 'Verified by Sonar Labs',
  ADDRESS_LABEL: 'Address:',
  OFFICE_LABEL: 'Office:',
  WEBSITE_TEXT: 'visit website',
  SALES_LABEL: 'Sales:',
  CLEAR: 'Clear',
  APPLY: 'Apply',
  FINDING_THE_BEST_MATCHES_FOR_YOU: 'Finding the best matches for you... ',
  FECILITIES_FOUND: 'facilities found',
  FECILITIY_FOUND: 'facilitiy found',
  BLACKLIST: 'Add to blacklist',
  FAVORITE: 'Add to favorite',
  WE_COULD_NOT_FIND_ANY_SUPPLIER: 'Sorry, looks like we couldn’t find any suppliers',
  WE_COULD_NOT_FIND_ANY_LANDFILLS: 'Sorry, looks like we couldn’t find any landfills',
  BLACK_LIST_MATERIAL: 'Blacklist materials from this supplier',
  BLACK_LIST_MATERIAL_SUB_TITLE: 'Select materials that you would like to blacklist and provide a brief note',
  BOOKMARK_MATERIALS: 'Select favorite materials from this supplier',
  FAVORITE_MATERIALS_SUB_TITLE: ' Select materials that you would like to favorite',
  DETAILS: 'Supplier details',
  SELECT_MATERIAL_TO_UNBLOCK: 'Select materials that you would like to unblock',
  SELECT_MATERIAL_TO_UNDO_FAVORITE: 'Select materials that you would like to undo from favorite',
  UNBLOCK_MATERIALS_FROM: 'Unblock materials from this supplier',
  UNDO_FAVORITE_MATERIALS_FROM: 'Undo favorite materials from this supplier',
  EDIT: 'Edit',
  BACK_TO_DASHBOARD: 'Back to dashboard',
  WHAT_WOULD_YOU_LIKE_TO_FAVORITE: 'What would you like to favorite?',
  BLACKLISTED: "Blacklisted",
  REASON_FOR_BLACKLIST: 'Reason for blacklisting',
  MATERIALS: 'Materials',
  AT_THIS_FACILITY: 'at this facility',
  MATERIALS_MARKED_AT_THIS_FECILITY: 'Materials marked at this facility',
  FILTER_TOOLTIP: 'Filter materials',
  REFUND_AND_CANCELLATION_POLICY: 'Refund and cancellation policy',
  SHIPPPING_POLICY: 'Shipping Policy',
  YOU_HAVE_REACHED_YOUR_MONTHLY_LIMIT: 'You have reached your monthly limit.',
  FOR_UNMIMITED_SEARCH_PLESE_CONTACT_US: 'For unlimited searches please contact us.',
  READY_TO_SEE_SONAR_LABS: 'Ready to see Sonar Labs?',
  JOIN_CONTRACTORS_FROM_ALL_OVER_AMERICA: 'Join contractors from all over America.',
  THANK_YOU: 'Thank You',
  YOUR_MESSAGE_HAS_BEEN_SUBMITTED: 'Your message has been submitted.',
  WE_WILL_GET_BACK_TO_YOU_SOON: 'We will get back to you soon.',
  FREE_MONTHLY_LIMIT_REACHED_UPGRADE_FOR_UNLIMITED: '5 free monthly limit, upgrade for unlimited searches',
  MONTHLY_LIMIT: 'Monthly limit: ',
  PERSONAL: 'Personal',
  IT_WILL_ONLY_BE_SEEN_BY_YOU: 'It will only be seen by you',
  COMPANY: 'Company',
  SHARED_WITH_ORG: 'Shared with your organization',
  WHERE_WOULD_YOU_LIKE_TO_SAVE: 'Where would you like to save this?',
  MATERIALS: 'Materials',
  ADD_TO_FAVOURITE: 'Add to favorite',
  FACILITY: 'Facility',
  ADD_SPECIFIC_MATERIALS_AT_THE_FACILITY: 'Add specific materials at the facility to the list',
  ADD_THE_SUPPLIER_TO_YOUR_BLACKLIST: 'Add the supplier to your blacklist',
  ADD_THE_SUPPLIER_TO_YOUR_FAVOURITE_LIST: 'Add the supplier to your favorite list ',
  WHAT_WOULD_YOU_LIKE_BLACKLIST: 'What would you like to blacklist?',
  ADD_TO_BLACKLIST: 'Add to blacklist',
  SELECT_MATERIALS_YOU_WOULD_LIKE_TO_BLACKLIST: 'Select the materials you would like to blacklist',
  TOP_UPDATE_OR_MAKE_CHANGES_PLEASE_GO_TO_FAVORITE: 'To update or make changes, please go to the Favorite tab or',
  FAVOURITED: 'Favorited',
  THE_FECILITY: 'The facility',
  LANDFILLS: 'Landfills',
  SEARCH_LANDFILLS: 'Search landfills',
  SELECT_MATERIALS: 'Select your materials',
  FAVORITES: 'Favorites',
  MARKED_BY: 'Marked by:',
  PERSONAL_FAVORITE: 'Personal favorite',
  COMPANY_FAVORITE: 'Company favorite',
  THIS_CHANGE_WILL_APPLY_TO_YOUR_ENTIRE_ORG: 'This change will apply to your entire organization.',
  THIS_FACILITY_HAS_BEEN_MARKED: 'This facility has been marked',
  MATERIAL_SEARCH_SUB_TEXT: 'Browse material suppliers for your needs',
  DOCUMENT_SEARCH: 'Document search',
  SEARCH_DOCUMENT_WITH_VENDORS: 'Search and connect with vendors in seconds',
  DOCUMENT_SEARCH_SUB_TEXT: 'Find critical information across all your documents',
  FIND_CONSTRUCTION_MATERIALS_AND_SUPPLIERS: ' Find construction materials and suppliers across the U.S.',
  STREAMLINE_YOUR_MATERIAL_PROCUREMENT: 'Streamline Your Material Procurement',
  DISCOVER_VENDORS_PLAN_LOGISTICS_AND_COLLABORATE: 'Discover vendors, plan logistics, and collaborate in one place.',
  VERIFIED_LISTING: 'Verified listing',
  VERIFIED_LISTING_SUB_TITLE: 'Browse verified suppliers for your needs',
  START_ROUTE_PLANNING: 'Smart route planning',
  PLAN_DELIVERY_PATH_TO_SAVE_TIME_AND_COST: 'Plan delivery paths to save time and costs',
  SHARED_FAVORITES: 'Shared favorites',
  YOUR_GPT_IS_EXCLUSIVELY_FOR_YOUR_USE: 'Your GPT is exclusively for your use and team',
  DATA_UPLOADED_REMAINS_PRIVATE_AND_SECURE: 'Data uploaded remains private and secure.',
  NO_AUTHORIZED_ACCESS_TO_YOUR_CUSTOM_GPT: 'No unauthorized access to your custom GPT',
  KEEP_YOUR_TEAM_ALIGNED_WITH_SHARED_LIST: 'Keep your team aligned with shared lists',
  BUILD_YOUR_CUSTOM: 'Build your custom',
  CONSTRUCTION_GPT: 'Construction GPT',
  IN_SECONDS_FOR_FREE: 'in seconds for free',
  CONFIGURE: 'Configure',
  NAME_YOUR_GPT: 'Name your GPT',
  ESTIMATOR_GPT: 'Estimator GPT',
  INSTRUCTIONS: 'Instructions',
  GPT_IN_ACTION: 'GPT in action',
  EQUIPMENT_PRODUCTIVITY: 'Equipment Productivity:',
  REWRITE: 'Rewrite',
  SHARE: 'Share',
  SET_UP_YOUR_GPT: 'Set up your GPT ',
  COLLABORATE: 'Collaborate',
  YOUR_DATA_IS_SAFE: 'Your Data Stays Safe ',
  BRING_YOUR_TEAM_INTO_THE_LOOP: 'Bring your team into the loop.',
  UPLOAD_FILES_OR_PDFS_TO_TRAIN_YOUR_GPT: 'Upload files or PDFs to train your GPT',
  EDIT_OUR_PROMPT_OR_ADD_YOUR_OWN: 'Edit our prompt or add your own',
  ADD_YOUR_DATA_TO_TAILOR_IT_FOR_YOUR_NEEDS: 'Add your data to tailor it for your needs',
  INVITE_YOUR_TEAM_ACCESS_THE_GPT_YOU_HAVE_BUILT: "Invite your team to access the GPT you’ve built. Sharing ensures everyone works smarter and faster with your custom tool.",
  PRODUCTIVITY_RATES_OF_DOZERS_SCRAPERS_EXCAVATORS: 'Productivity rates of dozers, scrapers, excavators, haul trucks, and compactors.',
  SITE_CONDITIONS_SOIL_TYPE_MOISTURE_CONTENT_ROCK_CONTENT: 'Site Conditions: Soil type, moisture content, rock content, and required compaction.',
  VOLUME_OF_MATERIAL_TO_MOVE: 'Volume of Material to Move: Total cubic yards (CY) of material.',
  TO_CALCULATE_CREW_COMPOSITION_FOR_A_MASS_GRADING_OPERATION: 'To calculate crew composition for a mass grading operation, we need to consider the following parameters:',
  HELP_ME_CALCULATE_CREW_COMPOSITION_FOR_MASS_GRADE: 'Help me calculate crew composition for mass grading.',
  CORE_KNOWLEDGE: 'Core Knowledge & Behavior Guidelines...',
  UPLOAD_FILES_TO_PROVIDE_INSTRUCTIONS_TO_GPT: 'Upload files to provide instructions to GPT',
  YOU_HAVE_EXPERIENCE_IN_PRE_CONSTRUCTION: 'You have experience in pre-construction services, specializing in vertical construction, heavy civil works and private development.',
  ADD_NEW_JOB: 'Add new job',
  FILTERS: 'Filters',
  ADD_SUB_TYPE: 'Add subtype',
  SELECT_MATERIAL: ' Select material',
  ENTER_MATERIAL_SUB_TYPE_NAME: 'Enter material sub type name',
  ADD_MATERIAL: ' Add material',
  ADD_MATERIAL_NAME: 'Enter material name',
  SELECT_ALL: 'Select all',
  SELECT_JOB: 'Select Job',
  SELECT_USER: 'Select User',
  REMOVE_THIS_MATERIAL_FROM_THIS_JOB: 'Remove this material from this job',
  ADD_USER_TO_TEAM: 'Add user to team',
  VIEW_UPDATES: 'View updates',
  DURATION: 'DURATION',
  GENERAL_DETAILS: 'General details',
  EDIT_VALUE: 'Edit value',
  JOB_NUMBER: 'Job number',
  JOB_NAME: 'Job name',
  LOCATION: 'Location',
  PROJECT_MANAGER: 'Project manager',
  PROJECT_STATUS: 'Project status',
  TYPE_OF_MOVEMENT: 'Type of movement', 
  MATERIAL_QUANTITY: 'Material quantity',
  MATERIAL_AVAILABILITY: 'Material availability ',
  TRANSFER_DATE_WINDOW: 'Transfer date window ',
  SHOW_DETAILS: 'Show details ',
  HIDE_DETAILS: 'Hide details ',
  OLD: "Old: ",
  CHANGED_TO: "Changed to: ",
  MATERIAL_DETAILS: "Material details ",
  CERTIFICATION: "certification ",
}

export default STRINGS


export const PROXIMITY_OPTIONS = [
  {
    key: '10 miles',
    value: 10
  },
  {
    key: '20 miles',
    value: 20
  },
  {
    key: '35 miles',
    value: 35
  },
  {
    key: '50 miles',
    value: 50
  },
  {
    key: '75 miles',
    value: 75
  },
  {
    key: '100 miles',
    value: 100
  },
]

export const MATERIALS_LIST = ["Asphalt Concrete", "Ready Mix Concrete", "Aggregates", "Quarry", "Concrete Products", "Material Plants", "Sand And Gravel", "Recycled Products"];

export const NO_MATERIAL_SEARCH_ACCESS_ORGS = ["d57373abc06548ceb2440b6863fdba40"]

export const NO_DOC_SEARCH_ACCESS_ORGS = ["a8c1849a91ab402782d2dff7eed75ed8"]