

import React, { useState, useRef, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { ReactComponent as DropdownImg } from '../assets/dropdown.svg';
import { Checkbox } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import STRINGS from "../../../helpers/staticContentHelper";

const iconStyling = { marginRight: "0rem", position: 'absolute', right: "10px", bottom: '15px', cursor: 'pointer' }
const checkBoxStyle = {
    "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
    },
    color: '#A3A7AA',
    '&.Mui-checked': {
        color: '#1F5D5E',
    },
    padding: '0px',
    marginRight: "6px"
}
const fontAndSize = {
    fontFamily: "Plus Jakarta Sans",
    color: 'rgb(27, 46, 49)',
    fontSize: '12px',
    fontWeight: 400,
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "black",
        backgroundColor: '#f3f3f3',
    },
    margin: '0.1rem',
    borderRadius: "6px", // Add border radius to each option
    padding: '10px 12px'
}

function FilterDropdown({ isAllChecked, handleAllChecked, subTypeValues = [], handleClearAll, multipleValues = [], error, dropdownOptions = [], label, onValueChange, optionLabel, optionValue, placeholder }) {
    const [isFocused, setIsFocused] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [activeDropdownList, setActiveDropdownList] = useState([])

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleCloseFocus = () => {
        setIsFocused(false);
    }

    // trigger if user clicks outside
    const divRef = useRef(null);

    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            handleCloseFocus()
            // Add your logic here
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <Typography className='input-label' sx={{ ...fontAndSize, padding: "0", fontSize: '14px' }}>
                {label}
            </Typography>
            <div className="searchable-outlined-input-new" style={{ height: "42px" }}>
                <OutlinedInput
                    onFocus={handleFocus}
                    fullWidth
                    required
                    size='small'
                    className='outline-input-customized'
                    placeholder={placeholder}
                />
                <DropdownImg style={iconStyling} />
                {error &&
                    <span key='err' style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem", position: 'absolute', left: "0px", bottom: "-20px" }}>
                        *required
                    </span>
                }
                {!loading && dropdownOptions?.length > 0 && isFocused &&
                    <div className="dropdown-outlined-list-new-bottom" ref={divRef} style={{ border: "1px solid #ECF0F3", top: "45px" }}>
                        <div className="dropdown-outlined-list-option-new checkbox-background-style" style={{ borderBottom: "1px solid #ECF0F3", position:"sticky" , top:'0px', backgroundColor:'#FAFBFC' , zIndex:1}}>
                            <span onClick={(e) => { handleAllChecked() }} style={{ fontSize: '13px', fontWeight: 400, display: 'flex', justifyContent: "center", alignItems: "center", gap: '3px' }}>
                                <Checkbox
                                    sx={checkBoxStyle}
                                    id="myCheckbox"
                                    checked={isAllChecked}
                                />
                                {STRINGS.SELECT_ALL}
                            </span>
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <span style={{ color: "#006D6B", fontWeight: 600 }} onClick={(e) => { handleClearAll() }}>{STRINGS.CLEAR}</span>
                                <span style={{ color: "#006D6B", fontWeight: 600 }} onClick={() => handleCloseFocus()}>Done</span>
                            </div>
                        </div>
                        {dropdownOptions.map((optionData, index) => {
                            return (<SingleDropdownListWithCheckbox
                                multipleValues={multipleValues}
                                index={index}
                                optionData={optionData}
                                onChange={(...rest) => { onValueChange(...rest) }}
                                optionValue={optionValue}
                                optionLabel={optionLabel}
                                activeDropdownList={activeDropdownList}
                                setActiveDropdownList={setActiveDropdownList}
                                paddingLeft='35px'
                                subTypeValues={subTypeValues}
                            />)
                        })}
                    </div>
                }
            </div>
        </>
    )
}

export default FilterDropdown;


export function SingleDropdownListWithCheckbox({ optionData, onChange, subTypeValues, optionValue, optionLabel, multipleValues, paddingLeft, index, activeDropdownList, setActiveDropdownList }) {
    return (
        <>
            <div className="dropdown-outlined-list-option-new" style={{ paddingLeft: paddingLeft && paddingLeft }}
                key={optionData?.label}
                onClick={() => {
                    onChange(optionData)
                }}>
                <Checkbox
                    sx={checkBoxStyle}
                    id="myCheckbox"
                    onChange={() => onChange(optionData)}
                    checked={multipleValues.includes(optionData[optionValue])}
                />
                {optionData[optionLabel] ? optionData[optionLabel] : optionData.label}

                {optionData?.subtypes?.length > 0 &&
                    <div style={{ transform: activeDropdownList.includes(index) && 'rotate(180deg)', width: '2rem', display: "flex", justifyContent: "center", alignItems: 'center' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            if (activeDropdownList.includes(index)) {
                                const deepCopy = JSON.parse(JSON.stringify(activeDropdownList))
                                const filteredArray = deepCopy.filter(i => i !== index)
                                setActiveDropdownList(prev => filteredArray)
                            } else {
                                setActiveDropdownList(prev => {
                                    return [...prev, index]
                                })
                            }
                        }}>
                        <DropdownImg />
                    </div>
                }
            </div>

            {optionData?.subtypes?.length > 0 && activeDropdownList.includes(index) &&
                optionData?.subtypes?.map(subtype => {
                    return (
                        <div className="dropdown-outlined-list-option-new" style={{ paddingLeft: '52px' }}
                            key={subtype?.label}
                            onClick={() => {
                                onChange({ ...subtype, material: optionData}, true)
                            }} >
                            <Checkbox
                                sx={checkBoxStyle}
                                id="myCheckbox"
                                onChange={() => {}}
                                checked={subTypeValues.includes(subtype['subtype_id'])}
                            />
                            {subtype['subtype_name']}
                        </div>
                    )
                })}
        </>
    )
}