import { getApiEndpoints } from "./api.endpoints";
import { API, handleApiResponse, API_FOR_FORM } from "./axios.config";
import { encryptPayload } from "../utils/encryptionDecryption";
import { getBooleanFromLocalStorage } from "./user.services";
import { toast } from 'react-toastify';

const abortController = new AbortController();

export const ADD_JOB = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.post(API_END_POINT.ADD_JOB, encryptedPayload).then(res => {
        toast.success("Job added successfully")
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}

export const UPDATE_JOB = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.post(API_END_POINT.UPDATE_JOB, encryptedPayload).then(res => {
        toast.success("Job updated successfully")
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}

export const UPLOAD_CERTIFICATE = async (data) => {
    // const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API_FOR_FORM.post(API_END_POINT.UPLOAD_CERTIFICATE, data).then(res => {
        toast.success("Certificate uploaded successfully")
        return res.data
    }).catch(err => {
        toast.error("Certificate upload failed")
        handleApiResponse(err)
        return err
    });
    return res
}

export const ADD_NEW_MATERIAL = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.post(API_END_POINT.ADD_NEW_MATERIAL, encryptedPayload).then(res => {
        toast.success("Material added successfully")
        return res.data
    }).catch(err => {
        toast.error("Adding material failed")
        handleApiResponse(err)
        return err
    });
    return res
}

export const ADD_MATERIAL_SUB_TYPES_LIST = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.post(API_END_POINT.ADD_MATERIAL_SUB_TYPES_LIST, encryptedPayload).then(res => {
        toast.success("Material subtype added successfully")
        return res.data
    }).catch(err => {
        toast.error("Adding subtype failed")
        handleApiResponse(err)
        return err
    });
    return res
}

export const INVITE_USER_TO_JOB = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API_FOR_FORM.post(API_END_POINT.INVITE_USER_TO_JOB, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}

export const REMOVE_USER_FROM_JOB = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API_FOR_FORM.post(API_END_POINT.REMOVE_USER_FROM_JOB, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}

export const DELETE_JOB = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.post(API_END_POINT.DELETE_JOB, encryptedPayload).then(res => {
        toast.success("Job material deleted successfully")
        return res.data
    }).catch(err => {
        toast.error("Deleting job material failed")
        handleApiResponse(err)
        return err
    });
    return res
}

export const GET_MATERIAL_LIST = async () => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.GET_MATERIAL_LIST).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });
    return res
}

export const GET_MATERIAL_SUB_TYPES_LIST = async (material_id) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.GET_MATERIAL_SUB_TYPES_LIST + material_id).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });
    return res
}

export const GET_JOBS = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.post(API_END_POINT.GET_JOBS, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });
    return res
}

export const GET_ADMIN_AND_MANAGERS = async (data) => {
    const query = `org_id=${data}`
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.GET_ADMIN_AND_MANAGERS + query).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });
    return res
}

export const GET_JOB_MEMBERS = async (data) => {
    const query = `org_id=${data.org_id}&job_id=${data.job_id}&existing_job_users=${data.existing_job_users}`
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.GET_JOB_MEMBERS + query).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });
    return res
}

export const GET_VERSION_HISTORY = async (id) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.GET_VERSION_HISTORY + id).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });
    return res
}

export const GET_FILTER_DROPDOWN = async () => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.GET_FILTER_DROPDOWN ).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });
    return res
}