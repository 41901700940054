import React from 'react'
import { ReactComponent as FavMaterial } from '../assets/noDataFound.svg';

function NoDataFound({ title, subTitle, Icon, buttonText = '', handleButtonClick }) {
  return (
    <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: "100%" }}>
      <div className='no-data-found-container'>
        <div className='no-data-found-image-container'>
          {Icon ? <Icon /> :
            <FavMaterial />
          }
        </div>
        <p>{title}</p>
        <span>{subTitle}</span>
        {buttonText &&
          <button className='button-primary-web' style={{ width: '220px', height: "44px" }}
            onClick={handleButtonClick}>
            {buttonText}
          </button>
        }
      </div>
    </div>
  )
}

export default NoDataFound